<template>
  <v-dialog v-model="dialog" max-width="700px" :key="dialog">
    <template v-slot:activator="{ on, attrs }">
      <strong dark v-bind="attrs" v-on="on" v-show="user.referral_token">
        <span class="friend_referral_modal">
          {{ $t('referralPrize.referToAFriend') }}
        </span>
        <i class="mdi mdi-link-variant"></i>
      </strong>
    </template>
    <v-card>
      <v-card-title class="info title white--text font-weight-bold">
        {{ $t('referralPrize.referToAFriend') }}
        <v-spacer />
        <v-icon class="mdi mdi-close" style="color: white" @click="close"></v-icon>
      </v-card-title>

      <v-card-text class="py-5">
        <p>
          {{ $t('referralPrize.referToAFriendDescription') }}
        </p>

        <div class="mt-12">
          <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
            <v-form lazy-validation @submit.prevent="handleSubmit(sendEmail)">
              <v-row class="d-flex align-center">
                <v-col cols="12" lg="8" md="8" sm="8" class="d-flex align-center">
                  <validation-provider style="width: inherit;" rules="required|email|max:200" v-slot="{ errors }" name="email" ref="emailRef">
                    <v-text-field
                      type="email"
                      name="email"
                      v-model="email"
                      clearable
                      counter="200"
                      :label="$t('organisationsPage.email')"
                      class="mr-5"
                      :error-messages="errors"></v-text-field>
                  </validation-provider>

                  <button-submit :failed="failed" :loading="loading" buttonText="buttons.send"></button-submit>
                </v-col>

                <v-col cols="12" lg="4" md="4" sm="4" class="d-flex justify-end">
                  <v-btn class="primary edit ml-5" @click="copyToClipboard">
                    {{ $t('buttons.copyLink') }}
                    <v-icon class="ml-3" small @click="copyToClipboard">mdi-content-copy</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { mapState } from 'vuex';
import i18n from '@/i18n/i18n';

export default {
  name: 'ClaimReferralPrize',
  data: () => ({
    dialog: false,
    email: '',
    loading: false,
  }),
  components: { ButtonSubmit },
  computed: {
    ...mapState('auth', ['user']),
  },
  mounted() {
    if (!this.user.referral_token) {
      this.$store.dispatch('auth/getUserData').then((res) => {
        const updatedUser = {
          ...this.user,
          referral_token: res.data.referral_token || '',
        };
        this.$store.dispatch('auth/setUser', updatedUser);
        localStorage.setItem('LimoExpressUser', JSON.stringify(updatedUser));
      });
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.loading = false;
      this.email = '';
    },
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText('https://limoexpress.app/?referral_token=' + this.user.referral_token);
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.linkCopied'), color: 'green' });
      } catch (err) {
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
      }
    },
    async sendEmail() {
      this.loading = true;
      let path = 'sendReferralEmail';

      await this.$store
        .dispatch('affiliate/' + path, { email: this.email })
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.sentSuccessfully'), color: 'green' });
          this.close();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
@media only screen and (max-width: 599px) {
  .friend_referral_modal {
    display: none;
  }
}

hr {
  height: 1px;
  color: #ddd;
}
</style>
