export default {
  sidebar: {
    fleet: 'Flotte',
    vehicles: 'Véhicules',
    vehiclesClasses: 'Classes de véhicules',
    administration: 'Administration',
    currencies: 'Devises',
    currenciesAdministration: 'Administration des devises',
    paymentTypes: 'Méthodes de paiement',
    paymentTypesAdministration: 'Administration des méthodes de paiement',
    users: 'Utilisateurs',
    usersAdministration: 'Administration des utilisateurs',
    clients: 'Clients',
    clientsAdministration: 'Administration des clients',
    organizations: 'Organisations',
    organizationsAdministration: 'Administration des organisations',
    saasAdmin: 'Admin SaaS',
    administrators: 'Administrateurs',
    saasAdminAdministration: "Administration de l'admin SaaS",
    statistics: 'Statistiques',
    generalOverview: 'Rapport général',
    payment: 'Paiement',
    rebates: 'Remises',
    pricingRegions: 'Tarification des régions',
    pricingRegionsAdministration: 'Administration de la tarification des régions',
    help: 'Aide',
    drivings: 'Commandes de conduite',
    calendar: 'Calendrier',
    tabularView: 'Vue tabulaire',
    expenses: 'Coûts',
    vehicleExpensesAdministration: 'Administration des coûts de véhicule',
    vehicleMaintenance: 'Pannes de véhicules',
    vehicleMaintenanceAdministration: 'Administration des pannes de véhicules',
    organization: 'Organisation',
    organizationAdminstration: "Administration de l'organisation",
    packages: 'Forfaits',
    packagesChange: 'Changer de forfaits',
    logout: 'Déconnexion',
    logoutText: 'Êtes-vous sûr de vouloir vous déconnecter ?',
    unaccessibleRoutes: 'Vous ne pouvez pas accéder à cette page. Mettez à niveau le forfait pour un accès complet.',
    transactions: 'Transactions',
    gpsMap: 'Carte GPS',
    team: 'Équipe',
    teamAdministration: 'Administration de l’équipe',
    invoices: 'Factures',
    invoicesAdministration: 'Gestion des factures',
    drivingOffers: 'Offres',
    drivingOffersAdministration: 'Gestion des offres',
    dashboard: 'Tableau de bord',
    dashboardAdministration: 'Administration du tableau de bord',
    resourceOverview: 'Aperçu des ressources',
    resourceOverviewAdministration: "Administration de l'aperçu des ressources",
    rides: 'Trajets',
    passengerReviews: 'Avis des passagers',
    passengerReviewsAdministration: 'Administration des avis des passagers',
    pricingAndRates: 'Tarifs et taux',
    pricingZones: 'Zones et régions',
    pricingZonesAdministration: 'Administration des zones et régions',
    pricingPerZones: 'Tarifs par zone',
    pricingPerZonesAdministration: 'Administration des tarifs par zone',
    pricingPerHour: 'Tarifs horaires',
    pricingPerHourAdministration: 'Administration des tarifs horaires',
    availability: 'Disponibilité',
    availabilityAdministration: 'Disponibilité des conducteurs et des véhicules',
  },
  header: {
    organization: 'Organisation',
    newDriving: 'Commande de conduite',
    dailyRent: 'Location journalière',
    transfer: 'Transfert',
    notifications: 'Notifications',
    changeLanguage: 'Changer la langue',
    help: 'Aide',
  },
  footer: {
    poweredBy: 'Propulsé par',
    subscriptionTo: 'Votre abonnement à',
    trialEndsAt: "Votre période d'essai pour le ",
    packageExpiresIn: 'forfait expire dans',
    clickToChangePackage: 'Cliquez pour changer de forfait',
    clickToActivatePackage: 'Cliquez pour activer le forfait',
    clickToPay: 'Cliquez pour payer',
    packageHasExpired: 'paquet a expiré',
    affiliateUser: 'Si vous souhaitez débloquer toutes les fonctionnalités de la plateforme, vous devez vous abonner ',
    here: 'ICI',
  },
  buttons: {
    ok: 'Ok',
    yes: 'Oui',
    no: 'Non',
    save: 'Enregistrer',
    send: 'Envoyer',
    sendMessage: 'Envoyer un message',
    edit: 'Modifier',
    add: 'Ajouter',
    confirm: 'Confirmer',
    cancel: 'Annuler',
    cancelDriving: 'Annuler la conduite',
    markAsDone: 'Marquer comme fait',
    markAsNoShow: 'Marquer comme non-présentation',
    deleteDriving: 'Supprimer la conduite',
    newOrganisation: 'Nouvelle organisation',
    changePackage: 'Changement de forfait',
    downloadStats: 'Télécharger les statistiques',
    downloadPDF: 'Télécharger le PDF',
    today: "Aujourd'hui",
    choose: 'Choisir',
    login: 'Se connecter',
    generateFile: 'Générer le contrat',
    generateDrivingOrder: 'Imprimer la commande de conduite',
    downloadFile: 'Télécharger le fichier',
    activate: 'Activer',
    deactivate: 'Désactiver',
    showImage: "Afficher l'image",
    showDetails: 'Afficher les détails',
    actions: 'Actions',
    delete: 'Supprimer',
    open: 'Ouvrir',
    show: 'Afficher',
    options: 'Options',
    prev: 'Précédent',
    next: 'Suivant',
    skip: 'Passer',
    finish: 'Terminer',
    settings: 'Paramètres',
    cancelSubscription: "Annuler l'abonnement",
    backToEditOrganization: "Retour à la modification de l'organisation",
    payNow: 'Payer maintenant',
    back: 'Retour',
    integrationRoutes: "Itinéraires d'intégration",
    transactions: 'Transactions',
    articlesOfLaw: 'Articles de loi',
    export: 'Exporter',
    duplicate: 'Faire une copie',
    makeReturnTrip: 'Faire un voyage retour',
    selectOrganisation: 'Sélectionner une organisation',
    advancedSettings: 'Paramètres avancés',
    accept: 'Accepter',
    reject: 'Rejeter',
    sendViaGnet: 'Envoyer via GNET',
    printInvoice: 'Imprimer la facture',
    enable: 'Activer',
    disable: 'Désactiver',
    generatePaymentLink: 'Générer un lien de paiement',
    addItem: '+ Ajouter un élément',
    visitWebsite: 'Visitez notre site Web',
    buyMoreSms: 'Achetez plus de SMS',
    forward: 'Transférer',
    sendViaAffiliate: 'Envoyer via Affiliate',
    markInvoiceAsPaid: 'Marquer la facture comme payée',
    markDrivingOfferAsPaid: 'Marquer l’offre de conduite comme payée',
    selectAll: 'Tout sélectionner',
    viaAffiliateId: 'Ajouter via l’ID affilié',
    registerPartner: 'Enregistrer un partenaire',
    createNew: 'Créer nouveau',
    clone: 'Cloner',
    cloneExisting: 'Cloner existant',
    copyLink: 'Copier le lien',
    markAsStarted: 'Marquer comme commencé',
  },
  tooltips: {
    save: 'Enregistrer',
    edit: 'Modifier',
    delete: 'Supprimer',
    showImage: "Afficher l'image",
    open: 'Ouvrir',
    show: 'Afficher',
    showDetails: 'Afficher les détails ',
    downloadFile: 'Télécharger le fichier',
    generateFile: 'Générer le contrat',
    generateDrivingOrder: 'Imprimer la commande de conduite',
    activate: 'Activer',
    deactivate: 'Désactiver',
    cancel: 'Annuler',
    cancelDriving: 'Annuler la conduite',
    cannotMarkAsCanceled: 'La commande ne peut pas être marquée comme annulée car elle a déjà été acceptée',
    markAsDone: 'Marquer comme fait',
    cannotMarkAsDone: "La commande ne peut pas être marquée comme terminée, car elle n'a pas encore eu lieu",
    markAsNoShow: 'Marquer comme non-présentation',
    cannotMarkAsNoShow: "La commande ne peut pas être marquée comme non-présentation, car elle n'a pas encore eu lieu",
    deleteDriving: 'Supprimer la conduite',
    cannotDelete:
      "Nous sommes désolés, mais pour des raisons de sécurité, les utilisateurs ne peuvent pas être supprimés tant qu'ils sont connectés.",
    duplicate: 'Faire une copie',
    makeReturnTrip: 'Faire un voyage retour',
    refresh: 'Actualiser',
    selectOrganisation: 'Sélectionner une organisation',
    sendViaGnet: 'Envoyer via GNET',
    receivedViaGnet: 'Reçu via GNET',
    sentViaGnet: 'Envoyé via GNET',
    printInvoice: 'Imprimer la facture',
    optionsDisabled: 'Vous devez rejeter ou accepter la commande pour accéder au menu des options',
    cannotMakeReturnTrip: 'Vous ne pouvez pas faire de voyage retour pour une location journalière',
    cannotSendGnetOrderViaGnet: 'Vous ne pouvez pas envoyer la commande via gnet',
    cannotSendViaGnetWithoutGnetId: 'Vous ne pouvez pas envoyer la commande via gnet sans ID gnet',
    cannotPrintInvoiceWithoutClient: 'Vous ne pouvez pas imprimer une facture sans choisir un client',
    cannotMarkAsDoneStatusRejected: 'La commande ne peut pas être marquée comme terminée car elle a déjà été rejetée',
    cannotMarkAsDoneStatusCanceled: 'La commande ne peut pas être marquée comme terminée car elle a déjà été annulée',
    cannotMarkAsDoneStatusDone: 'La commande ne peut pas être marquée comme terminée car elle a déjà été terminée',
    cannotMarkAsDoneStatusNoShow:
      'La commande ne peut pas être marquée comme terminée car elle a déjà été marquée comme non présentée',
    cannotMarkAsNoShowStatusRejected:
      'La commande ne peut pas être marquée comme non présentée car elle a déjà été rejetée',
    cannotMarkAsNoShowStatusCanceled:
      'La commande ne peut pas être marquée comme non présentée car elle a déjà été annulée',
    cannotMarkAsNoShowStatusDone:
      'La commande ne peut pas être marquée comme non présentée car elle a déjà été terminée',
    cannotMarkAsNoShowStatusNoShow:
      'La commande ne peut pas être marquée comme non présentée car elle a déjà été marquée comme non présentée',
    cannotMarkAsCanceledGnet: 'La commande ne peut pas être annulée car elle a été reçue via gnet',
    cannotMarkAsCanceledStatusRejected: 'La commande ne peut pas être annulée car elle a déjà été rejetée',
    cannotMarkAsCanceledStatusExpired: 'La commande ne peut pas être annulée car elle a déjà été marquée comme expirée',
    cannotMarkAsCanceledStatusCanceled: 'La commande ne peut pas être annulée car elle a déjà été annulée',
    cannotMarkAsCanceledStatusDraft: 'La commande ne peut pas être annulée car elle a déjà été marquée comme brouillon',
    cannotMarkAsCanceledStatusCompleted:
      'La commande ne peut pas être annulée car elle a déjà été marquée comme terminée',
    cannotMarkAsCanceledStatusNoShow:
      'La commande ne peut pas être annulée car elle a déjà été marquée comme non présentée',
    cannotDeleteDrivingSentViaGnet: 'La commande ne peut pas être supprimée car elle a été envoyée via gnet',
    cannotDeleteDrivingReceivedViaGnet:
      'La commande ne peut pas être supprimée car elle a été reçue via gnet et acceptée',
    generatePaymentLink: 'Générer un lien de paiement',
    cannotGeneratePaymentLInk: "Le lien de paiement ne peut pas être généré car vous n'avez pas activé Stripe",
    disabledExport: 'Vous pouvez exporter les données une fois toutes les 24 heures',
    sendSms: 'Envoyer un SMS',
    accept: 'Accepter',
    reject: 'Refuser',
    sendViaAffiliate: 'Envoyer via Affiliate',
    receivedViaAffiliate: 'Reçu via Affiliate',
    sentViaAffiliate: 'Envoyé via Affiliate',
    cannotSendViaAffiliate: 'Vous ne pouvez pas envoyer la commande de conduite via l’affiliate.',
    cannotForwardDrivingOrder: 'Vous ne pouvez pas transférer une commande de conduite passée.',
    cannotMarkAsCanceledAffiliate:
      'L’ordre de conduite ne peut pas être marqué comme annulé car il a été reçu via l’affiliate.',
    cannotMarkAsCanceled12Hours:
      "La commande ne peut pas être marquée comme annulée car 12 heures se sont écoulées depuis l'heure de prise en charge.",
    cannotDeleteActiveDriving: 'L’ordre de conduite actif ne peut pas être supprimé.',
    noImageToShow: 'Aucune image à afficher',
    disabledForAffiliateUser:
      "Votre compte est de type 'Affilié'. Cette action est disponible pour les comptes [Réguliers] abonnés. Rendez-vous sur le lien en pied de page pour vous abonner.",
    markInvoiceAsPaid: 'Marquer la facture comme payée',
    markDrivingOfferAsPaid: 'Marquer l’offre de conduite comme payée',
    invoiceIsPaid: 'La facture est déjà marquée comme payée',
    drivingOfferIsPaid: 'L’offre de conduite est déjà marquée comme payée',
    selectAreaToDelete: 'Sélectionnez la zone que vous souhaitez supprimer',
    deleteSelectedArea: 'Supprimer la zone sélectionnée',
    markAsStarted: 'Marquer comme commencé',
    cannotMarkAsStarted: "La commande ne peut pas être marquée comme commencée car elle n'a pas encore eu lieu",
    cannotMarkAsStartedStatusStarted:
      'La commande ne peut pas être marquée comme commencée car elle a déjà été marquée comme commencée',
    cannotMarkAsStartedStatusRejected:
      'La commande ne peut pas être marquée comme commencée car elle a déjà été marquée comme rejetée',
    cannotMarkAsStartedStatusCanceled:
      'La commande ne peut pas être marquée comme commencée car elle a déjà été marquée comme annulée',
    cannotMarkAsStartedStatusDone:
      'La commande ne peut pas être marquée comme commencée car elle a déjà été marquée comme terminée',
    cannotMarkAsStartedStatusNoShow:
      'La commande ne peut pas être marquée comme commencée car elle a déjà été marquée comme absence',
  },
  snackbar: {
    close: 'Fermer',
    savedSuccessfully: 'Enregistré avec succès',
    excelDownloadedSuccessfully: 'Téléchargé avec succès',
    deletedSuccessfully: 'Supprimé avec succès',
    sentSuccessfully: 'Envoyé avec succès',
    statusChanged: 'Changement de statut réussi',
    activeStatusChanged: 'Changement de statut actif réussi',
    defaultStatusChanged: 'Changement de statut par défaut réussi',
    primaryStatusChanged: 'Changement de statut principal réussi',
    anErrorOccured: "Une erreur s'est produite, signalez-la au support",
    loadingCommentsFailed: 'Échec du chargement des commentaires',
    drivingCanceled: 'La commande de conduite a été annulée avec succès',
    drivingFinished: 'La commande de conduite a été terminée avec succès',
    drivingStarted: 'La commande de conduite a été marquée comme commencée avec succès',
    drivingNoShow: 'La commande de conduite a été marquée avec succès comme non-présentation.',
    drivingDeleted: "l'ordre de conduite a été supprimé avec succès",
    drivingAccepted: 'La commande de conduite est acceptée avec succès',
    drivingRejected: 'La commande de conduite est rejetée avec succès',
    planChanged: 'Plan modifié avec succès',
    planChangeFailed: 'Impossible de modifier le plan',
    saveChangesFirst: "Sauvegardez d'abord les modifications que vous avez apportées précédemment",
    makeRoutesPublic: 'Les itinéraires sont accessibles au public',
    makeRoutesNotPublic: 'Les itinéraires ne sont plus accessibles au public',
    makePublicLinkActive: 'Le lien public est maintenant disponible',
    makePublicLinkInactive: 'Le lien public n’est plus disponible',
    integrationKeyCopied: "Clé d'intégration copiée",
    copyToken: 'Copiez manuellement le jeton',
    publicLinkCopied: 'Lien public copié',
    copyPublicLink: 'Copiez manuellement le lien public',
    organisationChanged: "Changement d'organisation réussi",
    changeFailed: 'La modification a échoué, veuillez réessayer',
    loginFailed: "Nom d'utilisateur ou mot de passe incorrect",
    organisationSuspended: 'Votre organisation est suspendue',
    userEmailDoesNotExists: "Aucun utilisateur avec cette adresse e-mail n'existe",
    canceledSubscrSuccessfully: "Annulation de l'abonnement réussie",
    toManyRequests: 'Trop de demandes. Réessayez dans une minute.',
    visibleStatusChanged: 'Changement de statut visible réussi',
    noInternetConnection: "Pas d'internet. Veuillez vérifier votre connexion internet.",
    backOnline: 'Vous êtes de nouveau en ligne.',
    driverNotifiedToTurnGpsOn: 'Chauffeur notifié pour allumer le GPS',
    savedSuccessfullyWithGnetError:
      'La synchronisation avec GNET a échoué, la commande de conduite a été sauvegardée avec succès.',
    logoutFromAnotherDevice: "Vous avez été déconnecté d'un autre appareil.",
    stripeEnabled: 'Stripe activé avec succès',
    stripeDisabled: 'Stripe désactivé avec succès',
    finishStripeConnect: 'Veuillez terminer la configuration de Stripe',
    requestSentSuccessfully: 'Demande envoyée avec succès',
    pricingZoneNoMatch: 'Nous ne pouvons pas trouver de zones tarifaires correspondantes pour calculer le prix.',
    areaAlreadyExists: 'Cette zone existe déjà',
    noDataForThisPostalCode: 'Aucune donnée disponible pour ce code postal',
    affiliateRequestSentSuccessfully: "L'organisation existe déjà, la demande d'affiliation a été envoyée",
    organizationRegisteredSuccessfully: "L'organisation a été enregistrée avec succès",
    organizationIsAlreadyAPartner: "L'organisation est déjà un partenaire",
    recordAlreadyExists: 'Cet enregistrement existe déjà.',
  },
  filters: {
    filter: 'Filtrer',
    currentYear: 'Année en cours',
    lastYear: "L'année dernière",
    lastQuarter: 'Le dernier trimestre',
    lastThreeYears: 'Les trois dernières années',
    filterByDriver: 'Filtrer par conducteur',
    filterByVehicle: 'Filtrer par véhicule',
    filterByClient: 'Filtrer par clients',
    day: 'Jour',
    week: 'Semaine',
    month: 'Mois',
    vehicle: 'Véhicule',
    driving: 'Conduite',
    user: 'Utilisateur',
    fromDate: 'À partir de la date',
    toDate: "Jusqu'à la date",
    threeMonths: 'Trois mois',
    halfYear: 'Demi année',
    year: 'Année',
  },
  months: {
    jan: 'Janvier',
    feb: 'Février',
    mar: 'Mars',
    apr: 'Avril',
    may: 'Mai',
    jun: 'Juin',
    jul: 'Juillet',
    aug: 'Août',
    sep: 'Septembre',
    oct: 'Octobre',
    nov: 'Novembre',
    dec: 'Décembre',
    January: 'Janvier',
    February: 'Février',
    March: 'Mars',
    April: 'Avril',
    May: 'Mai',
    June: 'Juin',
    July: 'Juillet',
    August: 'Août',
    September: 'Septembre',
    October: 'Octobre',
    November: 'Novembre',
    December: 'Décembre',
  },
  usersPageHeaders: {
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    username: "Nom d'utilisateur",
    email: 'Email',
    phoneNumber: 'Numéro de téléphone',
    role: 'Rôle',
    active: 'Actif',
    actions: 'Actions',
  },
  usersRoles: {
    masterAdmin: 'Administrateur principal',
    manager: 'Gestionnaire',
    driver: 'Chauffeur',
    operator: 'Opérateur',
    externalAssociate: 'Associé externe',
    sales: 'Ventes',
    salesLead: 'Responsable des Ventes',
    gnet: 'GNET',
    customer: 'Utilisateur du portail',
    publicUser: 'Utilisateur public',
  },
  masterUsersPageHeaders: {
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    username: "Nom d'utilisateur",
    email: 'Email',
    phoneNumber: 'Numéro de téléphone',
    role: 'Rôle',
    organisation: 'Organisation',
    active: 'Actif',
    actions: 'Actions',
  },
  usersPage: {
    newItem: "Nouvelle entrée d'utilisateur",
    editItem: "Modifier l'utilisateur",
    id: 'Id',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    username: "Nom d'utilisateur",
    password: 'Mot de passe',
    phoneNumber: 'Numéro de téléphone',
    role: 'Rôle',
    client: 'Client',
    email: 'Email',
    organisation: 'Organisation',
    percentage: 'Pourcentage',
    externalAssociate: 'Associé externe',
    image: 'Image',
    deleteUser: "Supprimer l'utilisateur",
    deleteMessage: "Êtes-vous sûr de vouloir supprimer l'utilisateur ",
    emailChanged: "Changement d'email",
    verificationEmailSent:
      "La demande de changement d'adresse e-mail a été envoyée. Pour finaliser le processus et assurer la sécurité du compte utilisateur, la nouvelle adresse e-mail doit être vérifiée en cliquant sur le lien de vérification envoyé à la nouvelle adresse e-mail. L'adresse e-mail ne sera pas modifiée tant qu'elle n'aura pas été vérifiée avec succès.",
    passengersInfoAvailableToDriver: 'Informations des passagers disponibles pour le chauffeur',
    colorPicker:
      'Vous pouvez sélectionner une couleur unique pour chaque utilisateur. Elle sera utilisée sur la page de disponibilité pour une identification plus facile.',
  },
  clientsPageHeaders: {
    name: 'Nom',
    phoneNumber: 'Numéro de téléphone',
    address: 'Adresse',
    email: 'Email',
    companyId: "ID de l'entreprise/ID personnel",
    clientType: 'Type de client',
    percentage: 'Pourcentage',
    active: 'Actif',
    actions: 'Actions',
  },
  clientsPage: {
    newItem: 'Nouvelle entrée client',
    editItem: "Modifier l'utilisateur",
    id: 'Id',
    name: 'Nom',
    address: 'Adresse',
    phoneNumber: 'Numéro de téléphone',
    email: 'Email',
    companyId: "ID de l'entreprise/ID personnel",
    clientType: 'Type de client',
    individual: 'Individu',
    business: 'Entité commerciale',
    percentage: 'Pourcentage',
    deleteClient: 'Supprimer le client',
    deleteMessage: 'Êtes-vous sûr de vouloir supprimer le client ',
    tabs: {
      general: 'Général',
      transfers: 'Transferts',
      users: 'Utilisateurs',
      invoices: 'Factures',
      cards: 'Cartes',
    },
  },
  drivingsHeaders: {
    drivingNumber: 'Numéro de commande de conduite',
    drivingType: 'Type de réservation',
    drivingStatus: 'Statut',
    locationFrom: 'Point de départ',
    locationTo: 'Destination',
    vehicleClass: 'Classe de véhicule',
    vehicle: 'Véhicule',
    driversName: 'Chauffeur',
    startTime: 'Heure de prise en charge',
    actions: 'Actions',
    exportExcel: 'Exporter au format Excel',
    price: 'Prix',
    totalPrice: 'Prix total',
    confirmed: 'Confirmé',
    paid: 'Payé',
    numberOfDrivings: "Nombre d'ordres de conduite",
  },
  vehiclesHeaders: {
    plateNumber: "Numéro d'immatriculation",
    brand: 'Marque du véhicule',
    vehicleClass: 'Classe de véhicule',
    passengersNumber: 'Nombre de passagers',
    fuelConsumption: 'Consommation de carburant',
    yearOfManufacture: 'Année de fabrication',
    pricePerKm: 'Prix par kilomètre',
    pricePerMi: 'Prix par mile',
    pricePerHour: 'Prix par heure',
    pricePerWaitingHour: "Prix par heure d'attente",
    active: 'Actif',
    actions: 'Actions',
  },
  vehiclesPage: {
    id: 'Id',
    newItem: 'Nouvelle entrée de véhicule',
    editItem: 'Modification du véhicule',
    vehiclePlates: "Plaques d'immatriculation",
    vehicleBrand: 'Marque du véhicule',
    vehicleClass: 'Classe de véhicule',
    passengersNumber: 'Nombre de passagers',
    fuelConsumption: 'Consommation de carburant (100 km)',
    fuelConsumptionMi: 'Consommation de carburant de la voiture (100 miles)',
    manufactureYear: 'Année de fabrication',
    price: 'Prix par kilomètre',
    priceMi: 'Prix par mile',
    hourPrice: 'Prix par heure',
    image: 'Image',
    hourWaitingPrice: "Prix par heure d'attente",
    deleteMessage: 'Êtes-vous sûr de vouloir supprimer ce véhicule ?',
    tabs: {
      general: 'Général',
      transfers: 'Transferts',
      costs: 'Coûts',
      damages: 'Dommages',
    },
    licence: 'Numéro de licence',
    color: 'Couleur',
    colorPicker:
      'Vous pouvez sélectionner une couleur unique pour chaque véhicule. Elle sera utilisée sur la page de disponibilité pour une identification plus facile.',
  },
  vehicleClassesHeaders: {
    vehicleClass: 'Classe de véhicule',
    numberOfPassengers: 'Nombre de passagers',
    numberOfSuitcases: 'Nombre de valises',
    pricePerKm: 'Prix par kilomètre',
    pricePerMi: 'Prix par mile',
    pricePerHour: 'Prix par heure',
    pricePerWaitingHour: "Prix par heure d'attente",
    availableForPublicBookingForm: 'Disponible pour le formulaire de réservation public',
    active: 'Actif',
    actions: 'Actions',
  },
  vehicleClassesPage: {
    newItem: 'Nouvelle entrée de classe de véhicule',
    editItem: 'Modification de la classe de véhicule',
    id: 'Id',
    vehicleClass: 'Classe de véhicule',
    numberOfPassengers: 'Nombre de passagers',
    numberOfSuitcases: 'Nombre de valises',
    availableForPublicBookingForm: 'Disponible pour le formulaire de réservation public',
    price: 'Prix par kilomètre',
    priceMi: 'Prix par mile',
    hourPrice: 'Prix par heure',
    image: 'Image',
    hourWaitingPrice: "Prix par heure d'attente",
    deleteMessage: 'Êtes-vous sûr de vouloir supprimer cette classe de véhicule ?',
  },
  expensesHeaders: {
    name: 'Nom',
    vehicle: 'Véhicule',
    type: 'Type',
    description: 'Description',
    price: 'Prix',
    expenseDate: 'Date',
    actions: 'Action',
    driving: 'Conduite',
    exportExcel: 'Exporter au format Excel',
  },
  vehicleExpensesTypes: {
    minorService: 'Service mineur',
    majorService: 'Service majeur',
    fuel: 'Carburant',
    tires: 'Pneus',
    parking: 'Stationnement',
    other: 'Autre',
  },
  expensesPage: {
    newItem: 'Nouvelle entrée de coûts',
    editItem: 'Changement de coût',
    id: 'Id',
    name: 'Nom',
    vehicle: 'Véhicule',
    costType: 'Type de coût',
    price: 'Prix',
    currency: 'Devise',
    costTime: 'Période de coût',
    description: 'Description',
    deleteMessage: 'Êtes-vous sûr de vouloir supprimer ce coût?',
    costTypes: {
      'Mali servis': 'Service mineur',
      'Veliki servis': 'Service majeur',
      Gorivo: 'Carburant',
      Gume: 'Pneus',
      Parking: 'Stationnement',
      Ostalo: 'Autre',
    },
  },
  vehicleMaintenanceHeaders: {
    name: 'Véhicule',
    details: 'Détails',
    dateFrom: 'Date de début',
    dateTo: 'Date de fin',
    actions: 'Actions',
  },
  vehicleMaintenancePage: {
    newItem: 'Nouvelle entrée de panne de véhicule',
    editItem: 'Modification de la panne de véhicule',
    id: 'Id',
    vehicle: 'Véhicule',
    description: 'Description',
    dateFrom: 'Heure de début',
    dateTo: 'Heure de fin',
    deleteMessage: 'Êtes-vous sûr de vouloir supprimer cette panne de véhicule?',
  },
  rebatesHeaders: {
    min: 'Nombre minimum de commandes de conduite',
    max: 'Nombre maximum de commandes de conduite',
    percentage: 'Remise (%)',
    actions: 'Actions',
  },
  rebatesPage: {
    newItem: 'Nouvelle entrée de remise',
    editItem: 'Modifier la remise',
    rebateMin: 'Nombre minimum de commandes de conduite',
    rebateMax: 'Nombre maximum de commandes de conduite',
    rebate: 'Remise (%)',
    deleteRebate: 'Supprimer la remise',
    deleteMessage: 'Êtes-vous sûr de vouloir supprimer cette remise?',
  },
  customerCardsHeaders: {
    cardholderName: 'Nom du titulaire',
    cardNumber: 'Numéro de carte',
    expiryDate: "Date d'expiration",
    cvv: 'CVV',
    actions: 'Actions',
  },
  customerCardsPage: {
    newItem: 'Nouvelle entrée de carte',
    editItem: 'Modification de la carte',
    cardholderName: 'Nom du titulaire',
    cardNumber: 'Numéro de carte',
    expiryDate: "Date d'expiration",
    cvv: 'CVV',
    deleteCard: 'Supprimer la carte',
    deleteMessage: 'Êtes-vous sûr de vouloir supprimer cette carte?',
  },
  pricingRegionsHeaders: {
    name: 'Nom',
    actions: 'Actions',
  },
  pricingRegionsPage: {
    newItem: 'Nouvelle entrée de région de tarification',
    editItem: 'Modification de la région de tarification',
    name: 'Nom',
    chooseCurrency: 'Choisir la devise',
    soloPrice: 'Prix Solo - Année',
    soloPaddlePriceId: 'ID de prix Solo paddle - Année',
    team5Price: "Prix de l'équipe 5 - Année",
    team5PaddlePriceId: "ID de prix paddle de l'équipe 5 - Année",
    team10Price: "Prix de l'équipe 10 - Année",
    team10PaddlePriceId: "ID de prix paddle de l'équipe 10 - Année",
    team25Price: "Prix de l'équipe 25 - Année",
    team25PaddlePriceId: "ID de prix paddle de l'équipe 25 - Année",
    team50Price: "Prix de l'équipe 50 - Année",
    team50PaddlePriceId: "ID de prix paddle de l'équipe 50 - Année",
    soloPriceMonthly: 'Prix Solo - Mois',
    soloPaddlePriceIdMonthly: 'ID de prix Solo paddle - Mois',
    team5PriceMonthly: "Prix de l'équipe 5 - Mois",
    team5PaddlePriceIdMonthly: "ID de prix paddle de l'équipe 5 - Mois",
    team10PriceMonthly: "Prix de l'équipe 10 - Mois",
    team10PaddlePriceIdMonthly: "ID de prix paddle de l'équipe 10 - Mois",
    team25PriceMonthly: "Prix de l'équipe 25 - Mois",
    team25PaddlePriceIdMonthly: "ID de prix paddle de l'équipe 25 - Mois",
    team50PriceMonthly: "Prix de l'équipe 50 - Mois",
    team50PaddlePriceIdMonthly: "ID de prix paddle de l'équipe 50 - Mois",
    chooseCountries: 'Choisir les pays',
    description: 'Description',
    deletePricingRegion: 'Supprimer la région de tarification',
    deleteMessage: 'Êtes-vous sûr de vouloir supprimer cette région de tarification ?',
  },
  notificationsHeaders: {
    time: 'Heure',
    notification: 'Notification',
  },
  organisationsHeaders: {
    name: 'Organisation',
    phoneNumber: 'Numéro de téléphone',
    email: 'Email',
    address: 'Adresse',
    companyId: "Identifiant de l'entreprise",
    vat: 'TVA',
    active: 'Actif',
    createdAt: 'Enregistré',
    actions: 'Actions',
    billingDate: 'Date de facturation',
  },
  organisationsPage: {
    newItem: "Nouvelle entrée d'utilisateur",
    editItem: "Modifier l'utilisateur",
    id: 'Id',
    name: 'Organisation',
    address: 'Adresse',
    phoneNumber: 'Numéro de téléphone',
    email: 'Email',
    vat: 'TVA',
    companyId: "Identifiant de l'entreprise",
    warrantNumber: 'Numéro de décision',
    image: 'Image',
    signature: 'Signature',
    seal: 'Tampon',
    deactivateMessage: 'Êtes-vous sûr de vouloir ',
    deactivate: 'DÉSACTIVER',
    activate: 'ACTIVER',
    organisation: 'organisation',
    deleteOrganisation: "Supprimer l'organisation",
    deleteMessage: 'Êtes-vous sûr de vouloir supprimer cette organisation?',
    cancelSubscrMessage:
      "Êtes-vous sûr de vouloir annuler votre abonnement ? Veuillez noter qu'en confirmant cette action : 1. Le compte de votre organisation et toutes les données associées seront définitivement supprimés. Cette action est irréversible. 2. Vous serez automatiquement déconnecté du système immédiatement après l'annulation. 3. Vous ne pourrez plus accéder au système pour vous connecter ou récupérer des données liées à votre organisation à l'avenir. Nous vous recommandons vivement de revoir toute information nécessaire ou d'exporter des données cruciales avant de procéder à cette action irréversible. Si vous avez des questions ou besoin d'aide, n'hésitez pas à contacter notre équipe de support. Souhaitez-vous procéder à l'annulation ?",
    allDrivings: 'Nombre total de commandes de conduite',
    allDrivingsAccepted: 'Nombre total de commandes de conduite acceptées',
    allDrivingsCanceled: 'Nombre total de commandes de conduite rejetées',
    allVehicles: 'Nombre total de véhicules',
    allUsers: "Nombre total d'utilisateurs",
    areYouSure: 'Êtes-vous sûr?',
    makeRoutesAvailable: "Rendre les itinéraires de l'intégration disponibles",
    percentage: 'Pourcentage',
    deleteImageMessage: 'Êtes-vous sûr de vouloir supprimer cette image ?',
    deleteImageTitle: "Supprimer l'image",
    cancelSubscriptionTitle: "Annuler l'abonnement",
    subscriptionType: "Type d'abonnement",
    billingDate: 'Date de facturation',
    articlesOfLaw: 'Articles de loi',
    country: 'Pays',
    referent: 'Référent',
    language: 'Langue',
    gnetId: 'ID GNET',
    affiliateId: 'Affiliate ID',
    trialEndsAt: 'La période d’essai se termine le',
    languageTooltip:
      'Langue par défaut pour les nouveaux utilisateurs et langue dans laquelle tous les e-mails de l’entreprise (pas les e-mails des utilisateurs individuels) seront envoyés.',
    organisationType: 'Type d’organisation',
    regular: 'Régulier',
    affiliateSender: 'Expéditeur affilié',
    affiliateReceiver: 'Récepteur affilié',
    dispatcher: 'Répartiteur',
  },
  transactionsHeaders: {
    invoiceNumber: 'Numéro de facture',
    status: 'Statut',
    total: 'Total',
    currency: 'Devise',
    billedAt: 'Facturé le',
    actions: 'Actions',
  },
  transactionsPage: {
    newItem: 'Nouvelle entrée de transaction',
    editItem: 'Modifier la transaction',
    id: 'Id',
    invoiceNumber: 'Numéro de facture',
    status: 'Statut',
    sent: 'Envoyé',
    completed: 'Terminé',
    total: 'Total',
    currency: 'Devise',
    billedAt: 'Facturé le',
    actions: 'Actions',
    deleteTransaction: 'Supprimer la transaction',
    deleteMessage: 'Êtes-vous sûr de vouloir supprimer cette transaction?',
    currentSubscription: 'Forfait actuel',
    nextBillingDate: 'Prochaine date de facturation',
    numberOfSeatsTaken: 'Nombre de membres de l’équipe occupés',
    numberOfSeatsAvailable: 'Nombre de membres de l’équipe disponibles',
  },
  currenciesHeaders: {
    name: 'Nom',
    code: 'Code',
    symbol: 'Symbole',
    active: 'Actif',
    default: 'Primaire',
    actions: 'Actions',
  },
  currenciesPage: {
    newItem: 'Nouvelle entrée de devise',
    editItem: 'Modifier la devise',
    id: 'Id',
    currency: 'Devise',
    code: 'Code',
    symbol: 'Symbole',
    deleteMessage: 'Êtes-vous sûr de vouloir supprimer cette devise?',
  },
  paymentMethodsHeaders: {
    name: 'Nom',
    active: 'Actif',
    default: 'Primaire',
    priceVisible: 'Prix visible',
    actions: 'Actions',
  },
  paymentMethodsPage: {
    newItem: 'Nouvelle entrée de méthode de paiement',
    editItem: 'Modification de la méthode de paiement',
    id: 'Id',
    paymentMethod: 'Méthode de paiement',
    deleteMessage: 'Êtes-vous sûr de vouloir supprimer cette méthode de paiement?',
  },
  feedbacksHeaders: {
    subject: 'Nom',
    organisation: 'Organisation',
    user: 'Utilisateur',
    actions: 'Actions',
  },
  passengersHeaders: {
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    phoneNumber: 'Numéro de téléphone',
    email: 'Email',
    passport: 'Passeport',
    nationality: 'Nationalité',
    actions: 'Actions',
  },
  checkpointsHeaders: {
    checkpointLocation: 'Emplacement du point de passage',
    arrivalTime: "Heure d'arrivée",
    actions: 'Actions',
  },
  tables: {
    noDataText: 'Aucune donnée',
    search: 'Rechercher',
    drivings: 'Commandes de conduite',
    drivingsByPage: 'Commandes de conduite par page',
    vehicles: 'Véhicules',
    vehiclesByPage: 'Véhicules par page',
    vehiclesClasses: 'Classes de véhicules',
    vehiclesClassesByPage: 'Classes de véhicules par page',
    expenses: 'Coûts',
    expensesByPage: 'Coûts par page',
    vehiclesMaintenance: 'Pannes des véhicules',
    vehiclesMaintenanceByPage: 'Pannes des véhicules par page',
    reportsByPage: 'Rapports par page',
    currencies: 'Devises',
    currencieseByPage: 'Devises par page',
    payments: 'Méthodes de paiement',
    paymentsByPage: 'Méthodes de paiement par page',
    saasAdministrators: 'Tous les administrateurs',
    users: 'Tous les utilisateurs',
    usersByPage: 'Utilisateurs par page',
    clients: 'Tous les clients',
    clientsByPage: 'Clients par page',
    organisations: 'Toutes les organisations',
    organisationsByPage: 'Organisations par page',
    rebates: 'Remises',
    rebatesByPage: 'Remises par page',
    cards: 'Cartes',
    cardsByPage: 'Cartes par page',
    transactions: 'Transactions',
    transactionsByPage: 'Transactions par page',
    pricingRegions: 'Tarifs des régions',
    pricingRegionsByPage: 'Tarifs des régions par page',
    feedback: 'Commentaires',
    feedbacksByPage: 'Commentaires par page',
    notifications: 'Notifications',
    notificationsByPage: 'Notifications par page',
    all: 'Tous',
    passengers: 'Passagers',
    passengersByPage: 'Passagers par page',
    of: 'de',
    checkpoints: 'Points de passage',
    checkpointsByPage: 'Points de passage par page',
    invoices: 'Factures',
    invoicesByPage: 'Factures par page',
    drivingOffers: 'Offres de conduite',
    drivingOffersByPage: 'Offres de conduite par page',
    smsMessages: 'Messages SMS',
    smsMessagesByPage: 'Messages SMS par page',
    resourcesUsed: 'Ressources utilisées',
    resourcesUsedByPage: 'Ressources par page',
    inNext3Days: 'Dans les 3 prochains jours',
    notConfirmedOrPaid: 'Non confirmé ou payé',
    inNext5Days: 'Dans les 5 prochains jours',
    latestReviews: 'Derniers avis',
    passengerReviews: 'Avis des passagers',
    driversByPage: 'Conducteurs par page',
    reviewsByPage: 'Avis par page',
    partners: 'Partenaires',
    partnersByPage: 'Partenaires par page',
    sentRequests: 'Demandes envoyées',
    receivedRequests: 'Demandes reçues',
    requestsByPage: 'Demandes par page',
    pricingZones: 'Zones et régions',
    pricingZonesByPage: 'Zones et régions par page',
    pricingPerZones: 'Tarifs par zone',
    pricingPerZonesByPage: 'Tarifs par page',
    pricingPerHour: 'Tarifs horaires',
    pricingPerHourByPage: 'Tarifs par page',
  },
  statistics: {
    numberOfAcceptedDrivings: 'Nombre de commandes de conduite acceptées',
    numberOfRejectedDrivings: 'Nombre de commandes de conduite rejetées par conducteur',
    vehicleDrivingsNumber: 'Nombre de commandes de conduite par véhicule',
    vehicleProfit: 'Bénéfice par véhicule',
    monthDrivingsNumber: 'Nombre de commandes de conduite par mois',
    driverProfit: 'Bénéfice par conducteur',
  },
  reportsHeaders: {
    vehicle: 'Véhicule',
    expenses: 'Dépenses',
    profit: 'Bénéfice',
    earnings: 'Revenus',
    daysWorking: 'Jours de travail',
    usageIndex: "Indice d'utilisation",
    client: 'Client',
    numberOfDrivings: 'Nombre de Conduites',
    percentForClient: 'Prix ​​pour le client',
    sumOfPrice: 'Montant',
  },
  packages: {
    active: 'Actif',
    starterTitle: 'Démarrage',
    monthlyPackagesMessage: 'Abonnement mensuel par organisation.',
    starterText: "Idéal pour les particuliers ou les petites équipes qui n'ont besoin que des fonctionnalités de base.",
    starterListItemOne: 'Applications mobiles',
    starterListItemTwo: 'Notifications push',
    starterListItemThree: "Accès à l'administration",
    starterListItemFour: 'Intégration sur le site Web',
    standardTitle: 'Standard',
    standardText: 'Parfait pour les organisations de plus de 5 conducteurs.',
    standardListItemOne: 'Forfait DEBUTANT inclus',
    standardListItemTwo: 'Aucune limite sur le nombre de conducteurs',
    standardListItemThree: 'Disponibilité de 99,9%',
    standardListItemFour: 'Rapports et gestion des véhicules',
    premiumTitle: 'Premium',
    premiumText: 'Parfait pour les organisations de services de limousine qui fonctionnent sur des données précises.',
    premiumListItemOne: 'Forfait STANDARD inclus',
    premiumListItemTwo: 'BI et prévision des coûts',
    premiumListItemThree: 'Exportation de données',
    premiumListItemFour: 'Rapports hebdomadaires et mensuels',
    warningOne: '* Les prix indiqués sont des abonnements mensuels par utilisateur',
    warningTwo:
      "* Après avoir basculé vers un nouveau forfait, il n'est pas possible de passer à un forfait plus petit pendant les 6 mois suivants",
    warningThree:
      '* Les utilisateurs du plan de démonstration ont un aperçu des fonctionnalités fournies par le forfait premium',
    warningOneAnnualPackage:
      'Besoin de plus? Vous avez une plus grande organisation? Nous avons un forfait ENTREPRISE pour vous.',
    warningTwoAnnualPackage: 'et notre équipe commerciale vous contactera sous peu.',
    packageChange: 'Changement de forfait',
    changePackage: 'Êtes-vous sûr de vouloir passer à',
    package: 'forfait',
    annualPricing: 'Tarification annuelle',
    monthlyPricing: 'Tarification mensuelle',
    packageType10: 'Équipe 10',
    packageType25: 'Équipe 25',
    packageType50: 'Équipe 50',
    'Team 10': 'Équipe 10',
    'Team 25': 'Équipe 25',
    'Team 50': 'Équipe 50',
    annualPackagesMessage: 'Abonnement annuel par organisation.',
    packageTypeSoloMainMessage: '1 seul compte utilisateur.',
    packageType5MainMessage: "Jusqu'à 5 comptes d'utilisateur.",
    packageType10MainMessage: "Jusqu'à 10 comptes d'utilisateur.",
    packageType25MainMessage: "Jusqu'à 25 comptes d'utilisateur.",
    packageType50MainMessage: "Jusqu'à 50 comptes d'utilisateur.",
    allFeaturesIncluded: 'Toutes les fonctionnalités incluses.',
    unlimitedRides: 'Nombre illimité de trajets et de véhicules.',
    trialPeriod: "Période d'essai incluse.",
    onboardingFeeOptional: "Frais d'installation optionnels.",
    noHiddenCosts: 'Pas de coûts cachés.',
    freeSmsFlights10: '15 SMS et 50 vérifications de vols gratuits mensuellement.',
    freeSmsFlights25: '35 SMS et 100 vérifications de vols gratuits mensuellement.',
    freeSmsFlights50: '50 SMS et 200 vérifications de vols gratuits mensuellement.',
  },
  successPaymentPage: {
    message: 'Paiement réussi. Merci.',
  },
  demoExpiring: {
    notification: 'Notification',
    expiredSubscriptionMessage:
      'Nous vous informons que votre abonnement à cette plateforme a expiré. Pour continuer à utiliser nos services, veuillez renouveler votre abonnement.',
  },
  login: {
    username: "Nom d'utilisateur",
    password: 'Mot de passe',
    rememberMe: 'Se souvenir de moi',
    login: 'Connexion',
    noAccount: "Vous n'avez pas encore de compte ? Vous pouvez vous inscrire",
    forgetPassword: 'Mot de passe oublié ? Vous pouvez le réinitialiser',
    registerHere: 'ici',
    email: 'E-mail',
    enterEmail: 'Veuillez entrer votre e-mail',
    enterPassword: 'Veuillez entrer votre nouveau mot de passe',
    signInWithGoogle: 'Se connecter avec Google',
  },
  registration: {
    register: "S'inscrire",
    name: "Nom de l'organisation",
    address: 'Adresse',
    phoneNumber: 'Numéro de téléphone',
    email: 'E-mail',
    companyId: "Identifiant de l'entreprise",
    vat: 'TVA',
    language: 'Langue',
    package: 'Forfait',
    accept: "J'accepte",
    terms: "les conditions d'utilisation",
    successfullyRegistered:
      "L'utilisateur a été enregistré avec succès. Vous recevrez un e-mail avec un lien de vérification.",
    haveAccount: 'Déjà un compte? Vous pouvez vous connecter',
    loginHere: 'Ici',
    trialPeriodInfo: "Inclus est un essai gratuit de 14 jours. La facturation suivra à la fin de la période d'essai.",
  },
  driving: {
    drivingType: 'Type de réservation',
    general: 'Informations générales',
    newDriving: 'Programar un traslado',
    newDailyRent: 'Programar un alquiler diario',
    areYouSure: '¿Estás seguro?',
    vehicleIsUnavailable: 'El vehículo está ocupado.',
    vehicleIsDamaged: 'El vehículo está dañado.',
    sureAboutThisVehicle: '¿Estás seguro de que quieres elegir este vehículo?',
    driverUnavaliable: 'El conductor está ocupado.',
    sureAboutThisDriver: '¿Estás seguro de que quieres seleccionar este conductor?',
    checkVehicleSeatAvailability: 'Este vehículo no puede transportar a tantos pasajeros.',
    cancelDriving: '¿Estás seguro de que quieres cancelar la orden de conducción?',
    finishDriving: '¿Estás seguro de que quieres finalizar la orden de conducción?',
    noShowDriving: 'Êtes-vous sûr de vouloir marquer la commande de conduite comme non-présentation?',
    markDrivingAsStarted: 'Êtes-vous sûr de vouloir marquer la commande de conduite comme commencée ?',
    deleteDriving: '¿Estás seguro de que quieres supprimer la orden de conducción?',
    newTransfer: 'Nuevo traslado',
    driving: 'Orden de conducción',
    comments: 'Comentarios',
    id: 'ID',
    locationFrom: 'Punto de partida',
    locationTo: 'Destino',
    vehicleClass: 'Clase de vehículo',
    vehicle: 'Vehículo',
    driver: 'Conductor',
    pickupTime: 'Hora de recogida',
    clientConfirmed: 'Cliente confirmado',
    description: 'Descripción',
    extraInfo: 'Información adicional',
    drivingNotAccepted: 'No aceptaron la orden de conducción:',
    currency: 'Moneda',
    paymentMethod: 'Métodos de pago',
    price: 'Precio',
    paid: 'Pagado',
    distance: 'Distancia (km)',
    distanceMi: 'Distance (miles)',
    expectedTime: 'Duración estimada',
    waitingTime: 'Tiempo de espera (h)',
    EnterTheNumberOfHoursWaiting: 'Introduce el número de horas de espera',
    dropOffTime: 'Hora de llegada',
    comebackTime: 'Hora de regreso',
    acceptUntil: 'Aceptar hasta',
    drivingRequired: '¿Es obligatoria la orden de conducción?',
    comment: 'Comentario',
    attachments: 'Adjuntos',
    addFiles: '+ Agregar archivos',
    deleteFiles: '¿Estás seguro de que quieres eliminar este archivo?',
    passengers: 'Pasajeros',
    addPassenger: '+ Agregar pasajero',
    deletePassenger: '¿Estás seguro de que quieres eliminar este pasajero?',
    chooseClient: 'Choisir le client',
    costs: 'Costos',
    checkpoints: 'Points de passage',
    addCheckpoint: 'Ajouter un point de passage',
    deleteCheckpoint: 'Êtes-vous sûr de vouloir supprimer ce point de passage?',
    waitingBoardText: 'Texte en attente sur le tableau',
    flightNumber: 'Numéro de vol',
    flightInfo: 'Infos vol',
    createdBy: 'Commande de conduite créée à <b>{time}</b> par <b>{user}</b>',
    client: 'Client',
    gnet: 'GNET',
    note: 'Note',
    noteDriver: 'Note pour le conducteur',
    noteDispatcher: 'Note pour le répartiteur',
    passenger: 'Passager',
    fullName: 'Nom complet',
    phoneNumber: 'Numéro de téléphone',
    email: 'Email',
    suitcasesNumber: 'Nombre de valises',
    passengersNumber: 'Nombre de passagers',
    babySeatsNumber: 'Nombre de sièges bébé',
    stopsNumber: 'Nombre d’arrêts',
    steps: {
      mainInfo: 'Informations principales',
      additionalInfo: 'Informations supplémentaires',
      review: 'Revue',
    },
    commissionAmount: 'Commission',
    sendSms: 'Envoyer un SMS',
    message: 'Message',
    acceptDriving: 'Êtes-vous sûr de vouloir accepter la commande de conduite?',
    rejectDriving: 'Êtes-vous sûr de vouloir rejeter la commande de conduite?',
    calculatePriceViaZonePricing: 'Calculer le prix via la zone tarifaire',
    cannotCalculatePriceViaZonePricingForDailyRents:
      'Impossible de calculer le prix via la tarification par zone pour les locations journalières',
    disableCalculatePriceViaZonePricing:
      'Impossible de calculer le prix via la tarification par zone sans emplacement, devise et catégorie de véhicule',
    numberOfPassengers: 'Nombre de passagers',
    numberOfSuitcases: 'Nombre de valises',
    vat: 'TVA',
    vatPercentage: 'Pourcentage de TVA',
    priceType: 'Type de prix',
    accessibleForWheelchair: 'Accessible pour fauteuils roulants',
    priceForWaiting: 'Prix pour attente',
    numberOfHours: 'Nombre d’heures',
    getAQuote: 'Demandez un devis',
  },
  drivingStatus: {
    pending: 'Pendiente',
    accepted: 'Aceptado',
    rejected: 'Rechazado',
    expired: 'Caducado',
    canceled: 'Cancelado',
    draft: 'Borrador',
    done: 'Completado',
    noShow: 'Non-présentation',
    ongoing: 'Commencé',
  },
  drivingOrderType: {
    dailyRent: 'Location journalière',
    transfer: 'Transfert',
    hourlyDaily: "À l'heure / Au jour",
    oneWayTransfer: 'Transfert aller simple',
    fromAirport: "Depuis l'aéroport",
    toAirport: "Vers l'aéroport",
  },
  flightInfo: {
    airlineName: 'Nom de la compagnie aérienne',
    flightNumber: 'Numéro de vol',
    departureAirport: 'Aéroport de départ',
    departureCountry: 'Pays de départ',
    departureTime: 'Heure de départ',
    arrivalAirport: "Aéroport d'arrivée",
    arrivalCountry: "Pays d'arrivée",
    arrivalTime: "Heure d'arrivée",
    departureDelayed: 'Retard au départ',
    flightStatus: 'Statut du vol',
    percentageOfFlight: 'Pourcentage du vol',
  },
  customValidations: {
    fileType: 'El tipo de archivo .{extension} no está admitido',
    fileSize: 'Los archivos no pueden ser más grandes que {size}',
    fieldRequired: 'El campo {field} es obligatorio',
    fieldType: 'El campo {field} debe ser de tipo {type}',
    fieldLengthMin: 'El campo {field} debe tener {length} o más',
    fieldLengthMax: 'El campo {field} debe tener {length} o menos',
    fieldUnique: 'El campo {field} debe ser único',
    fieldValid: 'Le champ {field} doit être valide',
    noActiveSubscription: 'No se encontró una suscripción activa',
    usersLimitExceeded: 'Se ha alcanzado el límite de usuarios',
    fieldBefore: '{field1} doit être avant {field2}',
    flightNotExist: "Le vol {field} n'existe pas",
    gnetKeyNotValid: "La clé Gnet fournie n'est pas valide.",
    gnetPassengersRequired: "L'ordre de conduite ne peut pas être envoyé à GNET sans passagers.",
    priceAndCurrencyRequired: 'Le lien de paiement ne peut pas être généré sans prix et devise',
    fieldValueNotSupported: "La valeur du champ {field} n'est pas prise en charge",
    noCountryCode: "Vous n'avez sélectionné aucun pays",
    noStripeForYourCountry: "Stripe n'est pas disponible dans votre pays",
    stripeAlreadyEnabled: 'Stripe est déjà activé',
    fieldValueMin: 'Le champ {field} doit être {min} ou plus',
    fieldValueMax: 'Le champ {field} doit être {max} ou moins',
    markAsDoneDate: "L'heure de prise en charge ne peut pas être dans le futur",
    phoneNumberNotValidForSms:
      "Le numéro de téléphone est invalide pour l'envoi de SMS. Un numéro de téléphone valide doit inclure l'indicatif du pays.",
    affiliateIdDoesNotExist: "L'organisation avec l'ID d'affiliation donné n'existe pas.",
    numericDecimal: 'Le champ {field} ne peut contenir que des caractères numériques (les décimales sont autorisées)',
  },
  fieldTypes: {
    string: 'cadena',
    integer: 'número',
    date: 'fecha',
  },
  names: {
    username: 'nombre de usuario',
    password: 'contraseña',
    choose_client: 'elegir cliente',
    choose_month: 'elegir mes',
    currency: 'moneda',
    payment_method: 'método de pago',
    vehicle_class: 'clase de vehículo',
    first_name: 'primer nombre',
    last_name: 'apellido',
    passport: 'pasaporte',
    nationality: 'nacionalidad',
    plate_number: 'número de placa',
    brand: 'marca de automóvil',
    number_of_passengers: 'número de pasajeros',
    fuel_consumption: 'consumo de combustible',
    year_of_manufacture: 'año de fabricación',
    price_per_km: 'prix par km',
    price_per_mi: 'prix par mile',
    price_per_hour: 'precio por hora',
    price_per_waiting_hour: 'precio por hora de espera',
    vehicle: 'vehículo',
    description: 'descripción',
    time_from: 'hora desde',
    time_to: 'hora hasta',
    name: 'nombre',
    cost_type: 'tipo de costo',
    cost_time: 'tiempo de costo',
    price: 'precio',
    rebate_min: 'mínimo de órdenes de conducción',
    rebate_max: 'máximo de órdenes de conducción',
    rebate: 'descuento',
    phone_number: 'número de teléfono',
    organisation: 'organización',
    role: 'rol',
    image: 'imagen',
    location_from: 'ubicación desde',
    location_to: 'ubicación hasta',
    pickup_time: 'hora de recogida',
    distance: 'distancia',
    waiting_time: 'tiempo de espera',
    comment: 'comentario',
    address: 'dirección',
    vat: 'IVA',
    company_id: 'identificación de la empresa',
    company_personal_id: "id de l'entreprise/id personnel",
    licence: 'licencia',
    warrant_number: 'número de garantía',
    seal: 'sello',
    signature: 'firma',
    email: 'correo electrónico',
    percentage: 'porcentaje',
    organizationName: "nom de l'organisation",
    package: 'paquet',
    choose_currency: 'choisir la devise',
    team_10_price: "prix de l'équipe 10",
    team_10_paddle_price_id: "ID de prix Paddle de l'équipe 10",
    team_25_price: "prix de l'équipe 25",
    team_25_paddle_price_id: "ID de prix Paddle de l'équipe 25",
    team_50_price: "prix de l'équipe 50",
    team_50_paddle_price_id: "ID de prix Paddle de l'équipe 50",
    team_10_price_monthly: "prix de l'équipe 10",
    team_10_paddle_price_id_monthly: "ID de prix Paddle de l'équipe 10",
    team_25_price_monthly: "prix de l'équipe 25",
    team_25_paddle_price_id_monthly: "ID de prix Paddle de l'équipe 25",
    team_50_price_monthly: "prix de l'équipe 50",
    team_50_paddle_price_id_monthly: "ID de prix Paddle de l'équipe 50",
    countries: 'pays',
    invoice_number: 'numéro de facture',
    status: 'statut',
    total: 'total',
    billed_at: 'facturé le',
    articles_of_law: 'articles de loi',
    country: 'pays',
    referent: 'référent',
    language: 'langue',
    location: 'emplacement',
    arrival_time: "heure d'arrivée",
    waiting_board_text: 'texte en attente sur le tableau',
    flight_number: 'numéro de vol',
    gnet_id: 'ID GNET',
    receiver_id: 'ID du destinataire',
    preferred_vehicle_type: 'type de véhicule préféré',
    reservation_type: 'type de réservation',
    run_type: 'type de course',
    trip_duration_minutes: 'durée du voyage',
    client_type: 'type de client',
    client: 'client',
    card_holder_name: 'nom du titulaire',
    card_number: 'numéro de carte',
    expiry_date: "date d'expiration",
    cvv: 'cvv',
    code: 'code',
    symbol: 'symbole',
    quantity: 'quantité',
    discount: 'remise',
    driving_type: 'type de conduite',
    full_name: 'nom complet',
    suitcases_number: 'nombre de valises',
    passengers_number: 'nombre de passagers',
    baby_seats_number: 'nombre de sièges bébé',
    stops_number: "nombre d'arrêts",
    commission_amount: 'commission',
    message: 'message',
    affiliate_id: 'affiliate id',
    coordinates: 'coordonnées',
    zone_one: 'zone un',
    zone_two: 'zone deux',
    price_type: 'type de prix',
    price_for_waiting: 'tarif pour l’attente',
    number_of_hours: 'nombre d’heures',
    zones: 'Zones',
  },
  weekDays: {
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo',
  },
  select: {
    noDataAvailable: 'No hay datos disponibles',
    selected: 'sélectionné',
  },
  excelExport: {
    to_date_range: 'No se puede generar el informe para fechas futuras. Por favor, ingresa fechas válidas',
    overlap_range: 'La hora de finalización no puede ser mayor que la hora de inicio.',
  },
  setPassword: {
    success: 'Email enviado',
    checkEmail: 'Revise su correo electrónico para obtener instrucciones adicionales.',
    already_changed: 'Ya ha cambiado la contraseña',
    send_new_reset_request:
      'Ya ha cambiado la contraseña con este enlace. Envíe una nueva solicitud para restablecer la contraseña.',
  },
  statisticsPage: {
    reportType: 'Tipo de informe',
    reportTime: 'Tiempo del informe',
    vehicleUtilization: 'Utilización del vehículo',
    costEffectiveness: 'Eficiencia de costos',
    generalReport: 'Informe general',
    clientsReport: 'Rapport par Clients',
  },
  profile: {
    yourProfile: 'Tu perfil',
    name: 'Nombre',
    role: 'Rol',
    username: 'Nombre de usuario',
    password: 'Contraseña',
    phoneNumber: 'Número de teléfono',
    email: 'Correo electrónico',
    percentage: 'Porcentaje',
  },
  gpsMaps: {
    noTransfers: " Il n'y a aucun transfert en cours ou à venir.",
    map: 'Carte',
    transfers: 'Transferts',
    drivers: 'Chauffeurs',
    ongoing: 'En cours',
    upcoming: 'À venir',
    driving: 'Conduite',
    driver: 'Chauffeur',
    vehicle: 'Véhicule',
    requestDriverLocation: 'Demander la position du conducteur',
    lastRecordedLocation: 'Dernière position enregistrée',
  },
  driverStatus: {
    available: 'Disponible',
    offline: 'Hors ligne',
    inTransfer: 'En transfert',
  },
  onboardingTutorialMain: {
    languageStep: 'Ici, vous pouvez définir votre langue préférée pour la plateforme.',
    notificationsStep: 'Ici, vous trouverez vos notifications.',
    addTransferStep:
      'Cliquez sur ce bouton pour créer une nouvelle réservation, un transfert simple ou une location journalière.',
    sideMenuButtonStep: 'Cliquez sur cette icône pour afficher ou masquer la barre de menu.',
    sideMenuExpStep:
      'Depuis ici, vous pouvez naviguer vers les sections principales de la plateforme : Réservations, Flotte, Coûts, Administration, Factures, Statistiques, etc.',
    sideMenuButtonCloseStep: 'Cliquez à nouveau sur la même icône pour masquer la barre de menu.',
    supportStep:
      'Si vous avez des questions ou des problèmes, n’hésitez pas à contacter le support client en cliquant ici.',
  },
  onboardingTutorialVehicles: {
    tableStep: 'Les véhicules seront listés dans ce tableau après que vous les ayez ajoutés.',
    searchStep:
      'Vous pouvez facilement trouver des véhicules en recherchant directement par marque, plaque d’immatriculation, année, etc.',
    addStep:
      'Ajoutez de nouveaux véhicules en cliquant sur le bouton « Ajouter ». Veuillez noter que toutes les « Catégories de véhicules » doivent être ajoutées avant d’entrer des véhicules.',
  },
  onboardingTutorialStatistics: {
    typeStep:
      'Dans cette section, vous trouverez toutes vos données statistiques présentées sous forme de graphiques. Sélectionnez ici le type de statistique.',
    intervalStep: 'Choisissez un intervalle pour visualiser les statistiques.',
    downloadStep: 'Vous pouvez également télécharger et imprimer ces tableaux.',
  },
  onboardingTutorialGps: {
    tabOneStep:
      'Dans le module GPS, vous pouvez suivre vos conducteurs et transferts au même endroit. Sélectionnez cet onglet pour voir les transferts en cours et à venir (les 24 prochaines heures).',
    tabTwoStep:
      'Sélectionnez des véhicules pour les voir sur la carte. Vous pouvez assigner directement des véhicules disponibles à des transferts depuis ici.',
    mapStep: 'Sélectionnez ou désélectionnez des véhicules en cliquant sur leur épingle sur la carte.',
  },
  onboardingTutorialInvoices: {
    tableStep: 'Toutes les factures seront listées dans ce tableau, des plus récentes aux plus anciennes.',
    searchStep:
      'Trouvez facilement des factures en recherchant directement par numéro, montant, nom du client, date de création, etc.',
    addStep: 'Créez de nouvelles factures en cliquant sur le bouton « Ajouter ».',
  },
  onboardingTutorialOffers: {
    tableStep:
      'Les offres seront listées dans ce tableau après les avoir ajoutées, de la plus récente à la plus ancienne.',
    searchStep: 'Trouvez facilement des offres en recherchant directement par numéro, montant ou description.',
    addStep: 'Créez de nouvelles offres en cliquant sur le bouton « Ajouter ».',
  },
  onboardingTutorialResources: {
    infoStep: 'Affichez les ressources restantes et achetez davantage de messages SMS à la demande.',
    tableStep:
      'Dans ce tableau, vous trouverez un audit et l’historique de toutes les ressources utilisées (SMS envoyés et vols vérifiés).',
    searchStep:
      'Trouvez facilement des ressources en recherchant directement par contenu, utilisateur ou date de création.',
  },
  onboardingTutorialPassengerReviews: {
    tableStep: 'Les avis des passagers seront listés dans ce tableau, du plus récent au plus ancien.',
    searchStep:
      'Trouvez facilement des avis de passagers en recherchant directement par numéro ou commentaire du passager.',
  },
  onboardingTutorialPricingZones: {
    tableStep: 'Les zones tarifaires seront listées dans ce tableau après les avoir ajoutées.',
    searchStep: 'Trouvez facilement des zones tarifaires en recherchant directement.',
    addStep: 'Créez de nouvelles zones tarifaires en cliquant sur le bouton « Ajouter ».',
  },
  onboardingTutorialPricingZoneRelations: {
    tableStep: 'Les relations des zones tarifaires (prix) seront listées dans ce tableau après les avoir ajoutées.',
    searchStep: 'Trouvez facilement des relations des zones tarifaires en recherchant directement.',
    addStep:
      'Créez de nouvelles relations des zones tarifaires (prix) en cliquant sur le bouton « Ajouter ». Veuillez noter que les « Zones tarifaires » doivent d’abord être ajoutées.',
  },
  onboardingTutorialPricingZoneHourlyRelations: {
    tableStep:
      'Toutes les zones tarifaires sont listées dans ce tableau. En ouvrant une zone spécifique, vous pouvez définir des prix pour cette zone.',
    searchStep: 'Trouvez facilement des zones tarifaires en recherchant directement.',
  },
  onboardingTutorialDashboard: {
    infoStep: 'Affichez un résumé des statistiques de votre organisation pour les 30 derniers jours.',
    next3DaysStep: 'Les réservations pour les 3 prochains jours seront listées dans ce tableau.',
    notPaidOrConfirmedStep:
      'Les réservations pour les 7 prochains jours qui ne sont pas confirmées ou payées seront listées dans ce tableau.',
    next5DaysStep: 'Les conducteurs occupés pour les 5 prochains jours seront listés dans ce tableau.',
    latestReviewsStep: 'Les derniers avis de vos trajets seront listés dans ce tableau.',
  },
  onboardingTutorialAffiliate: {
    tabStep: 'En changeant d’onglet, vous pouvez voir vos partenaires, les demandes envoyées ou les demandes reçues.',
    idStep: 'Vous trouverez ici votre identifiant affilié, que vous pouvez partager avec vos partenaires.',
    addStep: 'Créez une nouvelle demande de partenariat affilié en cliquant sur le bouton « Ajouter ».',
  },
  onboardingTutorialAdvancedSettings: {
    advancedSettings:
      'En cliquant ici, vous accédez aux Paramètres avancés de votre organisation et pouvez configurer toutes les propriétés et intégrations.',
  },
  advancedSettings: {
    advancedSettings: 'Paramètres avancés',
    distanceUnit: 'Unité de distance',
    distanceUnitDescription: "Sélectionnez l'unité de mesure des distances.",
    kilometers: 'Kilomètres',
    miles: 'Miles',
    dateFormat: 'Format de date',
    dateFormatDescription: 'Sélectionnez le format de date que vous préférez utiliser.',
    ddmmyyyy: 'DD-MM-YYYY',
    mmddyyyy: 'MM-DD-YYYY',
    mandatoryDrivings: 'Ordres de conduite obligatoires',
    mandatoryDrivingsDescription:
      'Choisissez si vous souhaitez rendre toutes les ordres de conduite obligatoires par défaut.',
    mandatoryDrivingsDefault: 'Ordres de Conduite Obligatoires (Par défaut)',
    paidDrivings: 'Commandes de conduite payées',
    paidDrivingsDescription: 'Cochez pour rendre toutes les commandes de conduite payées par défaut.',
    paidDrivingsDefault: 'Commandes de conduite payées (Par défaut)',
    notificationsEmail: 'Email pour notifications',
    notificationsEmailDescription:
      "Modifiez l'email par défaut pour recevoir les notifications (si vous ne souhaitez pas utiliser l'adresse email de votre organisation).",
    email: 'Email',
    articlesOfLaw: 'Articles de loi',
    integrations: 'Intégrations',
    gnetId: 'GNET ID',
    gnetDescription:
      "GNET est une plateforme complète conçue pour optimiser le suivi en temps réel, la gestion de flotte et les opérations de répartition pour l'industrie du transport.",
    makeRoutesAvailable: "Rendre les itinéraires de l'intégration disponibles",
    makeRoutesAvailableDescription:
      'Activez votre clé API pour intégrer LimoExpress à votre site Web, système ERP et plus encore.',
    makePublicFormAvailable: 'Rendre le formulaire public disponible',
    makePublicFormAvailableDescription:
      'Activez cette option pour accéder à la page de réservation publique afin que vos clients puissent accéder au formulaire de réservation avancé et créer facilement des comptes. Vous pouvez utiliser le lien dans les newsletters, sur le site web, dans les flyers ; c’est un lien unique uniquement pour votre entreprise. Vous pouvez également obtenir un extrait de code pour intégrer le formulaire de réservation directement sur votre propre site web.',
    emailsEnabled: 'E-mails activés',
    emailsEnabledDefault: 'E-mails activés',
    emailsEnabledDescription: 'Cochez pour recevoir des notifications par e-mail.',
    vat: 'TVA',
    vatPercentage: 'Pourcentage de TVA',
    vatPercentageDescription: 'Entrez le pourcentage de TVA.',
    invoicePaymentInstructions: 'Instructions de paiement de la facture',
    stripe: 'Stripe',
    stripeDescription:
      'Activez Stripe pour pouvoir facturer vos clients pour vos services. LimoExpress ne prend aucune commission.',
    passengerReviewsEnabled: 'Avis des passagers activés',
    passengerReviewsEnabledDefault: 'Avis des passagers activés',
    passengerReviewsEnabledDescription:
      'En activant cette fonctionnalité, vos passagers (si un e-mail est saisi) recevront un e-mail de confirmation après la fin du trajet (marqué comme Terminé) et auront la possibilité de donner une note (feedback) sur vos services.',
    dataExport: 'Exportation des données',
    dataExportDescription: 'Vous pouvez exporter toutes vos données, elles vous seront envoyées par e-mail.',
    sureAboutDataExport:
      'Êtes-vous sûr de vouloir exporter toutes vos données ? Si oui, toutes vos données vous seront envoyées par e-mail dans les 2 prochaines heures.',
    defaultPriceType: 'Type de prix par défaut',
    defaultPriceTypeDescription:
      'Définissez le type de prix par défaut pour déterminer comment les prix seront affichés.',
    NET: 'NET',
    GROSS: 'BRUT',
    link: 'Lien',
    code: 'Code',
    language: 'Langue',
    chooseSize: 'Choisir la taille',
    small: 'Petit',
    medium: 'Moyen',
    large: 'Grand',
  },
  drivingExcelCheckboxes: {
    drivingNumber: 'Numéro de commande',
    drivingType: 'Type de réservation',
    drivingStatus: 'Statut',
    locationFrom: 'Point de départ',
    locationTo: 'Destination',
    driver: 'Conducteur',
    client: 'Client',
    plateNumber: "Numéro d'immatriculation",
    note: 'Description',
    fuelConsumption: 'Consommation de carburant',
    pickupTime: 'Heure de prise en charge',
    numberOfPassengers: 'Nombre de passagers',
    passengers: 'Passagers',
    price: 'Prix',
    paid: 'Payé',
    paymentMethod: 'Méthode de paiement',
    waitingTime: "Temps d'attente (h)",
    distance: 'Distance (km)',
    distanceMi: 'Distance (mile)',
    selectAll: 'Tout sélectionner',
  },
  gnet: {
    gnetInfo: 'Infos GNET',
    requesterId: "ID de l'auteur de la demande",
    receiverId: 'ID du destinataire',
    preferredVehicleType: 'Type de véhicule préféré',
    reservationType: 'Type de réservation',
    runType: 'Type de course',
    price: 'Prix',
    currency: 'Devise',
    noteDriver: 'Note pour le conducteur',
    noteDispatcher: 'Note pour le répartiteur',
    tripDurationMinutes: 'Durée du voyage (minutes)',
  },
  invoicesHeaders: {
    invoiceNumber: 'Numéro de facture',
    client: 'Client',
    totalPrice: 'Prix total',
    drivings: 'Conduites',
    paid: 'Payé',
    currency: 'Devise',
    createdAt: 'Créé à',
    actions: 'Actions',
  },
  invoicesPage: {
    newItem: 'Nouvelle entrée de facture',
    chooseClient: 'Choisir le client',
    chooseDrivings: 'Choisir les trajets',
    allDrivings: 'Tous les trajets',
    deleteMessage: 'Êtes-vous sûr de vouloir supprimer cette facture ?',
    markInvoiceAsPaid: 'Êtes-vous sûr de vouloir marquer cette facture comme payée ?',
  },
  drivingOffersHeaders: {
    number: "Numéro d'offre de conduite",
    totalPrice: 'Prix total',
    description: 'Description',
    paid: 'Payé',
    currency: 'Devise',
    createdAt: 'Créé à',
    actions: 'Actions',
  },
  drivingOffersPage: {
    newItem: "Nouvelle entrée d'offre de conduite",
    item: 'Élément',
    description: 'Description',
    title: 'Titre',
    vehicleClass: 'Classe de véhicule',
    quantity: 'Quantité',
    price: 'Prix',
    discount: 'Remise',
    vat: 'TVA',
    deleteMessage: 'Êtes-vous sûr de vouloir supprimer cette offre de conduite ?',
    markDrivingOfferAsPaid: 'Êtes-vous sûr de vouloir marquer cette offre de conduite comme payée ?',
  },
  modal: {
    reservationSuccessTitle: 'Succès',
    reservationSuccessMessage:
      'La demande de réservation a été envoyée avec succès. Quelqu’un vous contactera sous peu.',
    reservationErrorTitle: 'Formulaire désactivé',
    reservationErrorMessage:
      'Le formulaire est actuellement désactivé par l’organisation. Veuillez contacter le support ou réessayer plus tard.',
  },
  dashboardPage: {
    drivingCountToday: 'Nombre de trajets (aujourd’hui)',
    drivingCountThisMonth: 'Nombre de trajets (30 derniers jours)',
    dailyRentsAndTransfers: 'Nombre de trajets (locations/transferts quotidiens)',
    drivingTotalAmount: 'Montant total',
    driversCount: 'Conducteurs actifs (±7 jours)',
    passengerCount: 'Nombre de passagers (30 derniers jours)',
  },
  reviewsHeaders: {
    rating: 'Note',
    comment: 'Commentaire',
    createdAt: 'Créé le',
  },
  resourceOverviewHeaders: {
    actionType: "Type d'action",
    user: 'Utilisateur',
    action: 'Action',
    createdAt: 'Créé le',
  },
  resourceOverviewPage: {
    smsMessagesLeft: 'Nombre de messages SMS restants',
    flightsCheckLeft: 'Nombre de vérifications de vols restantes',
    sms: 'SMS',
    flightCheck: 'Vérification de vol',
  },
  affiliateHeaders: {
    organisation: 'Organisation',
    createdAt: 'Créé le',
    actions: 'Actions',
  },
  affiliatePage: {
    partners: 'Partenaires',
    sentRequests: 'Demandes envoyées',
    receivedRequests: 'Demandes reçues',
    acceptRequest: 'Êtes-vous sûr de vouloir accepter cette demande ?',
    rejectRequest: 'Êtes-vous sûr de vouloir rejeter cette demande ?',
    deletePartner: 'Êtes-vous sûr de vouloir supprimer ce partenaire ?',
    deleteRequest: 'Êtes-vous sûr de vouloir supprimer la demande ?',
    newItem: 'Nouvelle demande',
    affiliateId: 'Affiliate ID',
    registerNewPartner: 'Enregistrer un nouveau partenaire',
    registrationSuccessfull: 'Inscription réussie',
    registrationSuccessfullDescription:
      "Partenaire affilié enregistré avec succès ! Veuillez informer le partenaire qu'il recevra un e-mail pour vérifier son compte et définir un mot de passe. Une fois terminé, ils pourront commencer à utiliser la plateforme.",
  },
  affiliate: {
    affiliateInfo: 'Affiliate informations',
    affiliate: 'Affiliate',
    partner: 'Partenaire',
    preferredVehicleType: 'Type de véhicule préféré',
    driverInfo: 'Informations sur le conducteur',
    vehicleInfo: 'Informations sur le véhicule',
    price: 'Prix',
    currency: 'Devise',
  },
  pricingZonesHeaders: {
    name: 'Nom de la zone',
    code: 'Code',
    postalCode: 'Code postal',
    actions: 'Actions',
  },
  pricingZonesPage: {
    newItem: 'Nouvelle entrée de zone tarifaire',
    editItem: 'Modification de la zone tarifaire',
    id: 'ID',
    name: 'Nom de la zone',
    code: 'Code',
    deleteMessage: 'Êtes-vous sûr de vouloir supprimer cette zone tarifaire ?',
    postalCode: 'Code postal',
    postalCodeDescription: 'Définissez une zone sur la carte en utilisant un code postal',
    postalCodeDisabled: 'Veuillez entrer un code postal en premier',
  },
  pricingZoneRelationsHeaders: {
    zoneOne: 'Zone un',
    zoneTwo: 'Zone deux',
    client: 'Client',
    actions: 'Actions',
  },
  pricingZoneRelationsPage: {
    newItem: 'Nouvelle entrée de relation de zone tarifaire',
    editItem: 'Modification de la relation de zone tarifaire',
    zone: 'Zone',
    zoneOne: 'Zone une',
    zoneTwo: 'Zone deux',
    chooseClient: 'Choisir un client',
    chooseClientFrom: 'Cloner depuis un client',
    chooseClientTo: 'Cloner vers un client',
    cloneItem: 'Cloner la relation de zone tarifaire',
    zoneRelations: 'Relations de zones tarifaires',
    cloneItemHourly: 'Cloner les tarifs horaires',
    zones: 'Zones',
    fromTo: 'De - À',
    percentage: 'Porcentaje',
    deleteMessage: 'Êtes-vous sûr de vouloir supprimer cette relation de zone tarifaire ?',
  },
  referralPrize: {
    referToAFriend: 'Recommander à un ami',
    referToAFriendDescription:
      "Invitez vos amis à rejoindre la plateforme en saisissant leur adresse e-mail ci-dessous. Ils recevront une invitation personnalisée pour s'inscrire et commencer à utiliser la plateforme. Vous pouvez également partager votre lien d'inscription unique directement avec d'autres ! Lorsqu'une personne s'inscrit via votre lien ou votre invitation, vous recevrez une récompense de parrainage en guise de remerciement. Commencez à inviter et à partager dès aujourd'hui !",
  },
};
