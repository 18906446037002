export default {
  sidebar: {
    fleet: 'Парк автомобилей',
    vehicles: 'Транспортные средства',
    vehiclesClasses: 'Классы автомобилей',
    administration: 'Администрирование',
    currencies: 'Валюты',
    currenciesAdministration: 'Администрирование валют',
    paymentTypes: 'Способы оплаты',
    paymentTypesAdministration: 'Администрирование способов оплаты',
    users: 'Пользователи',
    usersAdministration: 'Администрирование пользователей',
    clients: 'Клиенты',
    clientsAdministration: 'Администрирование клиентов',
    organizations: 'Организации',
    organizationsAdministration: 'Администрирование организаций',
    saasAdmin: 'Админ Saas',
    administrators: 'Администраторы',
    saasAdminAdministration: 'Администрирование админа Saas',
    statistics: 'Статистика',
    generalOverview: 'Общий отчет',
    payment: 'Оплата',
    rebates: 'Скидки',
    pricingRegions: 'Ценовые регионы',
    pricingRegionsAdministration: 'Администрирование ценовых регионов',
    help: 'Помощь',
    drivings: 'Заказы на поездки',
    calendar: 'Календарь',
    tabularView: 'Табличный вид',
    expenses: 'Расходы',
    vehicleExpensesAdministration: 'Администрирование затрат на транспортные средства',
    vehicleMaintenance: 'Техническое обслуживание автомобилей',
    vehicleMaintenanceAdministration: 'Администрирование технического обслуживания автомобилей',
    organization: 'Организация',
    organizationAdminstration: 'Администрирование организации',
    packages: 'Пакеты',
    packagesChange: 'Изменение пакетов',
    logout: 'Выйти',
    logoutText: 'Вы уверены, что хотите выйти?',
    unaccessibleRoutes: 'Вы не можете получить доступ к этой странице. Обновите пакет для полного доступа.',
    transactions: 'Транзакции',
    gpsMap: 'GPS карта',
    team: 'Команда',
    teamAdministration: 'Управление командой',
    invoices: 'Счета',
    invoicesAdministration: 'Управление счетами',
    drivingOffers: 'Предложения',
    drivingOffersAdministration: 'Управление предложениями',
    dashboard: 'Панель управления',
    dashboardAdministration: 'Администрирование панели управления',
    resourceOverview: 'Обзор ресурсов',
    resourceOverviewAdministration: 'Управление обзором ресурсов',
    rides: 'Поездки',
    passengerReviews: 'Отзывы пассажиров',
    passengerReviewsAdministration: 'Администрирование отзывов пассажиров',
    pricingAndRates: 'Цены и ставки',
    pricingZones: 'Зоны и районы',
    pricingZonesAdministration: 'Управление зонами и районами',
    pricingPerZones: 'Цены по зонам',
    pricingPerZonesAdministration: 'Управление ценами по зонам',
    pricingPerHour: 'Цены за час',
    pricingPerHourAdministration: 'Управление почасовыми ценами',
    availability: 'Доступность',
    availabilityAdministration: 'Доступность водителей и транспортных средств',
  },
  header: {
    organization: 'Организация',
    newDriving: 'Заказ на поездку',
    dailyRent: 'По дням',
    transfer: 'Трансфер',
    notifications: 'Уведомления',
    changeLanguage: 'Сменить язык',
    help: 'Помощь',
  },
  footer: {
    poweredBy: 'Предоставлено',
    subscriptionTo: 'Ваша подписка на',
    trialEndsAt: 'Ваш испытательный период для',
    packageExpiresIn: 'подписка истекает через',
    clickToChangePackage: 'Нажмите, чтобы изменить пакет',
    clickToActivatePackage: 'Нажмите, чтобы активировать пакет',
    clickToPay: 'Кликните для оплаты',
    packageHasExpired: 'пакет истек',
    affiliateUser: 'Если вы хотите разблокировать все функции платформы, вам нужно подписаться ',
    here: 'ЗДЕСЬ',
  },
  buttons: {
    ok: 'ОК',
    yes: 'Да',
    no: 'Нет',
    save: 'Сохранить',
    send: 'Отправить',
    sendMessage: 'Отправить сообщение',
    edit: 'Изменить',
    add: 'Добавить',
    confirm: 'Подтвердить',
    cancel: 'Отмена',
    cancelDriving: 'Отменить заказ на поездку',
    markAsDone: 'Отметить как выполненное',
    markAsNoShow: 'Отметить как неявку',
    deleteDriving: 'Удалить вождение',
    newOrganisation: 'Новая организация',
    changePackage: 'Изменить пакет',
    downloadStats: 'Загрузить статистику',
    downloadPDF: 'Загрузить PDF',
    today: 'Сегодня',
    choose: 'Выбрать',
    login: 'Войти',
    generateFile: 'Создать контракт',
    generateDrivingOrder: 'Печать заказа на поездку',
    downloadFile: 'Загрузить файл',
    activate: 'Активировать',
    deactivate: 'Деактивировать',
    showImage: 'Показать изображение',
    showDetails: 'Показать детали',
    actions: 'Действия',
    delete: 'Удалить',
    open: 'Открыть',
    show: 'Показать',
    options: 'Опции',
    prev: 'Предыдущий',
    next: 'Следующий',
    skip: 'Пропустить',
    finish: 'Завершить',
    settings: 'Настройки',
    cancelSubscription: 'Отменить подписку',
    backToEditOrganization: 'Вернуться к редактированию организации',
    payNow: 'Заплатить сейчас',
    back: 'Назад',
    integrationRoutes: 'Маршруты интеграции',
    transactions: 'Транзакции',
    articlesOfLaw: 'Статьи закона',
    export: 'Экспорт',
    duplicate: 'Сделайте копию',
    makeReturnTrip: 'Сделать обратное путешествие',
    selectOrganisation: 'Выберите организацию',
    advancedSettings: 'Расширенные настройки',
    accept: 'Принять',
    reject: 'Отклонить',
    sendViaGnet: 'Отправить через GNET',
    printInvoice: 'Распечатать счёт',
    enable: 'Включить',
    disable: 'Отключить',
    generatePaymentLink: 'Сгенерировать ссылку для оплаты',
    addItem: '+ Добавить элемент',
    visitWebsite: 'Посетите наш веб-сайт',
    buyMoreSms: 'Купить больше SMS',
    forward: 'Переслать',
    sendViaAffiliate: 'Отправить через Affiliate',
    markInvoiceAsPaid: 'Отметить счет как оплаченный',
    markDrivingOfferAsPaid: 'Отметить предложение поездки как оплачено',
    selectAll: 'Выбрать все',
    viaAffiliateId: 'Добавить через affiliate ID',
    registerPartner: 'Зарегистрировать партнера',
    createNew: 'Создать новое',
    clone: 'Клонировать',
    cloneExisting: 'Клонировать существующее',
    copyLink: 'Скопировать ссылку',
    markAsStarted: 'Отметить как начатое',
  },
  tooltips: {
    save: 'Сохранить',
    edit: 'Изменить',
    delete: 'Удалить',
    showImage: 'Показать изображение',
    open: 'Открыть',
    show: 'Показать',
    showDetails: 'Показать детали ',
    downloadFile: 'Загрузить файл',
    generateFile: 'Создать контракт',
    generateDrivingOrder: 'Печать заказа на поездку',
    activate: 'Активировать',
    deactivate: 'Деактивировать',
    cancel: 'Отмена',
    cancelDriving: 'Отменить заказ на поездку',
    cannotMarkAsCanceled: 'Заказ не может быть отмечен как отмененный, потому что он уже принят',
    markAsDone: 'Отметить как выполненное',
    cannotMarkAsDone: 'Заказ не может быть отмечен как выполненный, потому что он еще не произошел',
    markAsNoShow: 'Отметить как неявку',
    cannotMarkAsNoShow: 'Заказ не может быть отмечен как неявка, потому что он еще не состоялся',
    deleteDriving: 'Удалить вождение',
    cannotDelete:
      'Извините, но по соображениям безопасности пользователи не могут быть удалены, пока они находятся в системе.',
    duplicate: 'Сделайте копию',
    makeReturnTrip: 'Сделать обратное путешествие',
    refresh: 'Обновить',
    selectOrganisation: 'Выберите организацию',
    sendViaGnet: 'Отправить через GNET',
    receivedViaGnet: 'Получено через GNET',
    sentViaGnet: 'Отправлено через GNET',
    printInvoice: 'Распечатать счёт',
    optionsDisabled: 'Вы должны отклонить или принять заказ, чтобы получить доступ к меню опций',
    cannotMakeReturnTrip: 'Вы не можете сделать обратную поездку для ежедневной аренды',
    cannotSendGnetOrderViaGnet: 'Вы не можете отправить заказ через gnet',
    cannotSendViaGnetWithoutGnetId: 'Вы не можете отправить заказ через gnet без ID gnet',
    cannotPrintInvoiceWithoutClient: 'Вы не можете распечатать счет без выбора клиента',
    cannotMarkAsDoneStatusRejected: 'Заказ не может быть отмечен как выполненный, потому что он уже был отклонен',
    cannotMarkAsDoneStatusCanceled: 'Заказ не может быть отмечен как выполненный, потому что он уже был отменен',
    cannotMarkAsDoneStatusDone:
      'Заказ не может быть отмечен как выполненный, потому что он уже был отмечен как выполненный',
    cannotMarkAsDoneStatusNoShow:
      'Заказ не может быть отмечен как выполненный, потому что он уже был отмечен как неявка',
    cannotMarkAsNoShowStatusRejected: 'Заказ не может быть отмечен как неявка, потому что он уже был отклонен',
    cannotMarkAsNoShowStatusCanceled: 'Заказ не может быть отмечен как неявка, потому что он уже был отменен',
    cannotMarkAsNoShowStatusDone: 'Заказ не может быть отмечен как неявка, потому что он уже был выполнен',
    cannotMarkAsNoShowStatusNoShow: 'Заказ не может быть отмечен как неявка, потому что он уже был отмечен как неявка',
    cannotMarkAsCanceledGnet: 'Заказ не может быть отменен, потому что он был получен через gnet',
    cannotMarkAsCanceledStatusRejected: 'Заказ не может быть отменен, потому что он уже был отклонен',
    cannotMarkAsCanceledStatusExpired: 'Заказ не может быть отменен, потому что он уже истек',
    cannotMarkAsCanceledStatusCanceled: 'Заказ не может быть отменен, потому что он уже был отменен',
    cannotMarkAsCanceledStatusDraft: 'Заказ не может быть отменен, потому что он уже находится в черновике',
    cannotMarkAsCanceledStatusCompleted: 'Заказ не может быть отменен, потому что он уже был выполнен',
    cannotMarkAsCanceledStatusNoShow: 'Заказ не может быть отменен, потому что он уже был отмечен как неявка',
    cannotDeleteDrivingSentViaGnet: 'Заказ не может быть удален, потому что он был отправлен через gnet',
    cannotDeleteDrivingReceivedViaGnet: 'Заказ не может быть удален, потому что он был получен через gnet и принят',
    generatePaymentLink: 'Сгенерировать ссылку для оплаты',
    cannotGeneratePaymentLInk: 'Ссылка для оплаты не может быть создана, так как вы не активировали Stripe',
    disabledExport: 'Вы можете экспортировать данные один раз в 24 часа',
    sendSms: 'Отправить SMS',
    accept: 'Принять',
    reject: 'Отклонить',
    sendViaAffiliate: 'Отправить через Affiliate',
    receivedViaAffiliate: 'Получено через Affiliate',
    sentViaAffiliate: 'Отправлено через Affiliate',
    cannotSendViaAffiliate: 'Вы не можете отправить заказ на поездку через affiliate.',
    cannotForwardDrivingOrder: 'Вы не можете переслать заказ на поездку, который находится в прошлом.',
    cannotMarkAsCanceledAffiliate:
      'Заказ на поездку не может быть помечен как отменённый, так как он был получен через affiliate.',
    cannotMarkAsCanceled12Hours:
      'Заказ не может быть помечен как отменённый, потому что прошло 12 часов с момента времени получения.',
    cannotDeleteActiveDriving: 'Активный заказ на поездку не может быть удалён.',
    noImageToShow: 'Нет изображения для показа',
    disabledForAffiliateUser:
      "Ваш аккаунт типа 'Аффилиат'. Это действие доступно для подписанных [Обычных] аккаунтов. Перейдите по ссылке внизу страницы, чтобы подписаться.",
    markInvoiceAsPaid: 'Отметить счет как оплаченный',
    markDrivingOfferAsPaid: 'Отметить предложение поездки как оплачено',
    invoiceIsPaid: 'Счет уже помечен как оплаченный',
    drivingOfferIsPaid: 'Предложение поездки уже помечено как оплачено',
    selectAreaToDelete: 'Выберите область, которую хотите удалить',
    deleteSelectedArea: 'Удалить выбранную область',
    markAsStarted: 'Отметить как начатое',
    cannotMarkAsStarted: 'Заказ не может быть отмечен как начатый, так как он еще не состоялся',
    cannotMarkAsStartedStatusStarted: 'Заказ не может быть отмечен как начатый, так как он уже отмечен как начатый',
    cannotMarkAsStartedStatusRejected: 'Заказ не может быть отмечен как начатый, так как он уже отмечен как отклоненный',
    cannotMarkAsStartedStatusCanceled: 'Заказ не может быть отмечен как начатый, так как он уже отмечен как отмененный',
    cannotMarkAsStartedStatusDone: 'Заказ не может быть отмечен как начатый, так как он уже отмечен как завершенный',
    cannotMarkAsStartedStatusNoShow: 'Заказ не может быть отмечен как начатый, так как он уже отмечен как отсутствующий',
  },
  snackbar: {
    close: 'Закрыть',
    savedSuccessfully: 'Успешно сохранено',
    excelDownloadedSuccessfully: 'Успешно загружено',
    deletedSuccessfully: 'Успешно удалено',
    sentSuccessfully: 'Успешно отправлено',
    statusChanged: 'Статус успешно изменен',
    activeStatusChanged: 'Статус активности успешно изменен',
    defaultStatusChanged: 'Статус по умолчанию успешно изменен',
    primaryStatusChanged: 'Основной статус успешно изменен',
    anErrorOccured: 'Произошла ошибка, сообщите в службу поддержки',
    loadingCommentsFailed: 'Не удалось загрузить комментарии',
    drivingCanceled: 'Заказ на поездку успешно отменен',
    drivingFinished: 'Заказ на поездку успешно выполнен',
    drivingStarted: 'Заказ на вождение успешно отмечен как начатый',
    drivingNoShow: 'Заказ на вождение успешно отмечен как неявка.',
    drivingDeleted: 'приказ о вождении успешно удален',
    drivingAccepted: 'Заказ на вождение успешно принят',
    drivingRejected: 'Заказ на вождение успешно отклонен',
    planChanged: 'План успешно изменен',
    planChangeFailed: 'Изменение плана невозможно',
    saveChangesFirst: 'Сначала сохраните внесенные изменения',
    makeRoutesPublic: 'Маршруты стали общедоступными',
    makeRoutesNotPublic: 'Маршруты больше не общедоступны',
    makePublicLinkActive: 'Публичная ссылка теперь доступна',
    makePublicLinkInactive: 'Публичная ссылка больше недоступна',
    integrationKeyCopied: 'Интеграционный ключ скопирован',
    copyToken: 'Вручную скопируйте токен',
    publicLinkCopied: 'Публичная ссылка скопирована',
    copyPublicLink: 'Скопируйте публичную ссылку вручную',
    organisationChanged: 'Организация успешно изменена',
    changeFailed: 'Изменение не удалось, повторите попытку',
    loginFailed: 'Неправильное имя пользователя или пароль',
    organisationSuspended: 'Ваша организация приостановлена',
    userEmailDoesNotExists: 'Пользователя с этим адресом электронной почты не существует',
    canceledSubscrSuccessfully: 'Подписка успешно отменена',
    toManyRequests: 'Слишком много запросов. Попробуйте снова через минуту.',
    visibleStatusChanged: 'Видимый статус успешно изменен',
    noInternetConnection: 'Нет интернета. Пожалуйста, проверьте ваше интернет-соединение.',
    backOnline: 'Вы снова онлайн.',
    driverNotifiedToTurnGpsOn: 'Водитель уведомлен включить GPS',
    savedSuccessfullyWithGnetError: 'Синхронизация с GNET не удалась, заказ на вождение успешно сохранен.',
    logoutFromAnotherDevice: 'Вы вышли из системы на другом устройстве.',
    stripeEnabled: 'Stripe успешно активирован',
    stripeDisabled: 'Stripe успешно деактивирован',
    finishStripeConnect: 'Пожалуйста, завершите настройку Stripe',
    requestSentSuccessfully: 'Запрос успешно отправлен',
    pricingZoneNoMatch: 'Мы не можем найти соответствующие ценовые зоны для расчета стоимости.',
    areaAlreadyExists: 'Эта область уже существует',
    noDataForThisPostalCode: 'Нет данных для этого почтового индекса',
    affiliateRequestSentSuccessfully: 'Организация уже существует, запрос на партнёрство отправлен',
    organizationRegisteredSuccessfully: 'Организация успешно зарегистрирована',
    organizationIsAlreadyAPartner: 'Организация уже является партнером',
    recordAlreadyExists: 'Эта запись уже существует.',
  },
  filters: {
    filter: 'Фильтр',
    currentYear: 'Текущий год',
    lastYear: 'Прошлый год',
    lastQuarter: 'Прошлый квартал',
    lastThreeYears: 'Последние три года',
    filterByDriver: 'Фильтр по водителю',
    filterByVehicle: 'Фильтр по транспортному средству',
    filterByClient: 'Фильтр по клиентам',
    day: 'День',
    week: 'Неделя',
    month: 'Месяц',
    vehicle: 'Транспортное средство',
    driving: 'Вождение',
    user: 'Пользователь',
    fromDate: 'С даты',
    toDate: 'По дату',
    threeMonths: 'Три месяца',
    halfYear: 'Полгода',
    year: 'Год',
  },
  months: {
    jan: 'Январь',
    feb: 'Февраль',
    mar: 'Март',
    apr: 'Апрель',
    may: 'Май',
    jun: 'Июнь',
    jul: 'Июль',
    aug: 'Август',
    sep: 'Сентябрь',
    oct: 'Октябрь',
    nov: 'Ноябрь',
    dec: 'Декабрь',
    January: 'Январь',
    February: 'Февраль',
    March: 'Март',
    April: 'Апрель',
    May: 'Май',
    June: 'Июнь',
    July: 'Июль',
    August: 'Август',
    September: 'Сентябрь',
    October: 'Октябрь',
    November: 'Ноябрь',
    December: 'Декабрь',
  },
  usersPageHeaders: {
    firstName: 'Имя',
    lastName: 'Фамилия',
    username: 'Имя пользователя',
    email: 'Электронная почта',
    phoneNumber: 'Номер телефона',
    role: 'Роль',
    active: 'Активный',
    actions: 'Действия',
  },
  usersRoles: {
    masterAdmin: 'Главный администратор',
    manager: 'Менеджер',
    driver: 'Водитель',
    operator: 'Оператор',
    externalAssociate: 'Внешний сотрудник',
    sales: 'Продажи',
    salesLead: 'Руководитель отдела продаж',
    gnet: 'GNET',
    customer: 'Пользователь портала',
    publicUser: 'Публичный пользователь',
  },
  masterUsersPageHeaders: {
    firstName: 'Имя',
    lastName: 'Фамилия',
    username: 'Имя пользователя',
    email: 'Электронная почта',
    phoneNumber: 'Номер телефона',
    role: 'Роль',
    organisation: 'Организация',
    active: 'Активный',
    actions: 'Действия',
  },
  usersPage: {
    newItem: 'Новая запись пользователя',
    editItem: 'Редактировать пользователя',
    id: 'ID',
    firstName: 'Имя',
    lastName: 'Фамилия',
    username: 'Имя пользователя',
    password: 'Пароль',
    phoneNumber: 'Номер телефона',
    role: 'Роль',
    client: 'Клиент',
    email: 'Электронная почта',
    organisation: 'Организация',
    percentage: 'Процент',
    externalAssociate: 'Внешний сотрудник',
    image: 'Изображение',
    deleteUser: 'Удалить пользователя',
    deleteMessage: 'Вы уверены, что хотите удалить пользователя',
    emailChanged: 'Изменение электронной почты',
    verificationEmailSent:
      'Запрос на изменение адреса электронной почты отправлен. Для завершения процесса и обеспечения безопасности учетной записи пользователя новый адрес электронной почты должен быть подтвержден путем нажатия на ссылку подтверждения, отправленную на новый адрес. Адрес электронной почты не будет изменен до успешного подтверждения.',
    passengersInfoAvailableToDriver: 'Информация о пассажирах доступна водителю',
    colorPicker:
      'Вы можете выбрать уникальный цвет для каждого пользователя. Он будет использоваться на странице доступности для упрощения идентификации.',
  },
  clientsPageHeaders: {
    name: 'Имя',
    phoneNumber: 'Номер телефона',
    address: 'Адрес',
    email: 'Электронная почта',
    companyId: 'Идентификационный номер компании/Личный ID',
    clientType: 'Тип клиента',
    percentage: 'Процент',
    active: 'Активный',
    actions: 'Действия',
  },
  clientsPage: {
    newItem: 'Новая запись клиента',
    editItem: 'Редактировать клиента',
    id: 'ID',
    name: 'Имя',
    address: 'Адрес',
    phoneNumber: 'Номер телефона',
    email: 'Электронная почта',
    companyId: 'Идентификационный номер компании/Личный ID',
    clientType: 'Тип клиента',
    individual: 'Физическое лицо',
    business: 'Юридическое лицо',
    percentage: 'Процент',
    deleteClient: 'Удалить клиента',
    deleteMessage: 'Вы уверены, что хотите удалить клиента',
    tabs: {
      general: 'Общие',
      transfers: 'Переводы',
      users: 'Пользователи',
      invoices: 'Счета',
      cards: 'Карты',
    },
  },
  drivingsHeaders: {
    drivingNumber: 'Номер заказа на поездку',
    drivingType: 'Тип бронирования',
    drivingStatus: 'Статус',
    locationFrom: 'Начальная точка',
    locationTo: 'Место назначения',
    vehicleClass: 'Класс автомобиля',
    vehicle: 'Автомобиль',
    driversName: 'Водитель',
    startTime: 'Время подачи',
    actions: 'Действия',
    exportExcel: 'Экспорт в Excel',
    price: 'Цена',
    totalPrice: 'Общая цена',
    confirmed: 'Подтверждено',
    paid: 'Оплачено',
    numberOfDrivings: 'Количество заказов на поездки',
  },
  vehiclesHeaders: {
    plateNumber: 'Регистрационный номер',
    brand: 'Марка автомобиля',
    vehicleClass: 'Класс автомобиля',
    passengersNumber: 'Количество пассажиров',
    fuelConsumption: 'Расход топлива',
    yearOfManufacture: 'Год выпуска',
    pricePerKm: 'Цена за км',
    pricePerMi: 'Цена за милю',
    pricePerHour: 'Цена в час',
    pricePerWaitingHour: 'Цена за ожидание в час',
    active: 'Активный',
    actions: 'Действия',
  },
  vehiclesPage: {
    id: 'ID',
    newItem: 'Новая запись автомобиля',
    editItem: 'Изменить автомобиль',
    vehiclePlates: 'Регистрационные знаки',
    vehicleBrand: 'Марка автомобиля',
    vehicleClass: 'Класс автомобиля',
    passengersNumber: 'Количество пассажиров',
    fuelConsumption: 'Расход топлива (100 км)',
    fuelConsumptionMi: 'Расход топлива автомобиля (100 миль)',
    manufactureYear: 'Год выпуска',
    price: 'Цена за км',
    priceMi: 'Цена за милю',
    hourPrice: 'Цена в час',
    image: 'Изображение',
    hourWaitingPrice: 'Цена за ожидание в час',
    deleteMessage: 'Вы уверены, что хотите удалить автомобиль?',
    tabs: {
      general: 'Общее',
      transfers: 'Перевозки',
      costs: 'Затраты',
      damages: 'Повреждения',
    },
    licence: 'Номер лицензии',
    color: 'Цвет',
    colorPicker:
      'Вы можете выбрать уникальный цвет для каждого автомобиля. Он будет использоваться на странице доступности для упрощения идентификации.',
  },
  vehicleClassesHeaders: {
    vehicleClass: 'Класс автомобиля',
    numberOfPassengers: 'Количество пассажиров',
    numberOfSuitcases: 'Количество чемоданов',
    pricePerKm: 'Цена за км',
    pricePerMi: 'Цена за милю',
    pricePerHour: 'Цена в час',
    pricePerWaitingHour: 'Цена за ожидание в час',
    availableForPublicBookingForm: 'Доступно для формы публичного бронирования',
    active: 'Активный',
    actions: 'Действия',
  },
  vehicleClassesPage: {
    newItem: 'Новая запись класса автомобиля',
    editItem: 'Изменить класс автомобиля',
    id: 'ID',
    vehicleClass: 'Класс автомобиля',
    numberOfPassengers: 'Количество пассажиров',
    numberOfSuitcases: 'Количество чемоданов',
    availableForPublicBookingForm: 'Доступно для формы публичного бронирования',
    price: 'Цена за км',
    priceMi: 'Цена за милю',
    hourPrice: 'Цена в час',
    image: 'Изображение',
    hourWaitingPrice: 'Цена за ожидание в час',
    deleteMessage: 'Вы уверены, что хотите удалить класс автомобиля?',
  },
  expensesHeaders: {
    name: 'Название',
    vehicle: 'Автомобиль',
    type: 'Тип',
    description: 'Описание',
    price: 'Цена',
    expenseDate: 'Дата',
    actions: 'Действия',
    driving: 'Вождение',
    exportExcel: 'Экспорт в Excel',
  },
  vehicleExpensesTypes: {
    minorService: 'Мелкое ТО',
    majorService: 'Капитальное ТО',
    fuel: 'Топливо',
    tires: 'Шины',
    parking: 'Парковка',
    other: 'Другое',
  },
  expensesPage: {
    newItem: 'Новый ввод затрат',
    editItem: 'Изменить затраты',
    id: 'ID',
    name: 'Название',
    vehicle: 'Автомобиль',
    costType: 'Тип затрат',
    price: 'Цена',
    currency: 'Валюта',
    costTime: 'Дата затрат',
    description: 'Описание',
    deleteMessage: 'Вы уверены, что хотите удалить эту запись о затратах?',
    costTypes: {
      'Mali servis': 'Мелкое ТО',
      'Veliki servis': 'Капитальное ТО',
      Gorivo: 'Топливо',
      Gume: 'Шины',
      Parking: 'Парковка',
      Ostalo: 'Другое',
    },
  },
  vehicleMaintenanceHeaders: {
    name: 'Автомобиль',
    details: 'Детали',
    dateFrom: 'Дата с',
    dateTo: 'Дата по',
    actions: 'Действия',
  },
  vehicleMaintenancePage: {
    newItem: 'Новая запись о поломке автомобиля',
    editItem: 'Изменить информацию о поломке автомобиля',
    id: 'ID',
    vehicle: 'Автомобиль',
    description: 'Описание',
    dateFrom: 'Дата начала',
    dateTo: 'Дата окончания',
    deleteMessage: 'Вы уверены, что хотите удалить эту запись о поломке автомобиля?',
  },
  rebatesHeaders: {
    min: 'Минимальное количество заказов',
    max: 'Максимальное количество заказов',
    percentage: 'Скидка (%)',
    actions: 'Действия',
  },
  rebatesPage: {
    newItem: 'Новая запись о скидке',
    editItem: 'Изменить скидку',
    rebateMin: 'Минимальное количество заказов',
    rebateMax: 'Максимальное количество заказов',
    rebate: 'Скидка (%)',
    deleteRebate: 'Удалить скидку',
    deleteMessage: 'Вы уверены, что хотите удалить эту запись о скидке?',
  },
  customerCardsHeaders: {
    cardholderName: 'Имя держателя карты',
    cardNumber: 'Номер карты',
    expiryDate: 'Срок действия',
    cvv: 'CVV',
    actions: 'Действия',
  },
  customerCardsPage: {
    newItem: 'Новая запись карты',
    editItem: 'Изменение карты',
    cardholderName: 'Имя держателя карты',
    cardNumber: 'Номер карты',
    expiryDate: 'Срок действия',
    cvv: 'CVV',
    deleteCard: 'Удалить карту',
    deleteMessage: 'Вы уверены, что хотите удалить эту карту?',
  },
  pricingRegionsHeaders: {
    name: 'Название',
    actions: 'Действия',
  },
  pricingRegionsPage: {
    newItem: 'Новая запись региона цен',
    editItem: 'Изменение региона цен',
    name: 'Название',
    chooseCurrency: 'Выберите валюту',
    soloPrice: 'Цена Solo - Год',
    soloPaddlePriceId: 'ID цены Solo paddle - Год',
    team5Price: 'Цена команды 5 - Год',
    team5PaddlePriceId: 'ID Paddle цены команды 5 - Год',
    team10Price: 'Цена команды 10 - Год',
    team10PaddlePriceId: 'ID Paddle цены команды 10 - Год',
    team25Price: 'Цена команды 25 - Год',
    team25PaddlePriceId: 'ID Paddle цены команды 25 - Год',
    team50Price: 'Цена команды 50 - Год',
    team50PaddlePriceId: 'ID Paddle цены команды 50 - Год',
    soloPriceMonthly: 'Цена Solo - Месяц',
    soloPaddlePriceIdMonthly: 'ID цены Solo paddle - Месяц',
    team5PriceMonthly: 'Цена команды 5 - Месяц',
    team5PaddlePriceIdMonthly: 'ID Paddle цены команды 5 - Месяц',
    team10PriceMonthly: 'Цена команды 10 - Месяц',
    team10PaddlePriceIdMonthly: 'ID Paddle цены команды 10 - Месяц',
    team25PriceMonthly: 'Цена команды 25 - Месяц',
    team25PaddlePriceIdMonthly: 'ID Paddle цены команды 25 - Месяц',
    team50PriceMonthly: 'Цена команды 50 - Месяц',
    team50PaddlePriceIdMonthly: 'ID Paddle цены команды 50 - Месяц',
    chooseCountries: 'Выберите страны',
    description: 'Описание',
    deletePricingRegion: 'Удалить регион цен',
    deleteMessage: 'Вы уверены, что хотите удалить этот регион цен?',
  },
  notificationsHeaders: {
    time: 'Время',
    notification: 'Уведомление',
  },
  organisationsHeaders: {
    name: 'Организация',
    phoneNumber: 'Номер телефона',
    email: 'Электронная почта',
    address: 'Адрес',
    companyId: 'Идентификационный номер компании',
    vat: 'НДС',
    active: 'Активная',
    createdAt: 'Зарегистрирована',
    actions: 'Действия',
    billingDate: 'Дата выставления счета',
  },
  organisationsPage: {
    newItem: 'Новая запись организации',
    editItem: 'Изменить организацию',
    id: 'ID',
    name: 'Организация',
    address: 'Адрес',
    phoneNumber: 'Номер телефона',
    email: 'Электронная почта',
    vat: 'НДС',
    companyId: 'Идентификационный номер компании',
    warrantNumber: 'Номер решения',
    image: 'Изображение',
    signature: 'Подпись',
    seal: 'Печать',
    deactivateMessage: 'Вы уверены, что хотите ',
    deactivate: 'ДЕАКТИВИРОВАТЬ',
    activate: 'АКТИВИРОВАТЬ',
    organisation: 'организацию',
    deleteOrganisation: 'Удалить организацию',
    deleteMessage: 'Вы уверены, что хотите удалить эту организацию?',
    cancelSubscrMessage:
      'Вы уверены, что хотите отменить подписку? Обратите внимание, что подтверждая это действие: 1. Аккаунт вашей организации и все связанные с ним данные будут безвозвратно удалены. Это действие нельзя отменить. 2. Вы будете автоматически выведены из системы сразу после отмены подписки. 3. У вас больше не будет доступа для входа в систему или получения каких-либо данных, связанных с вашей организацией в будущем. Мы настоятельно рекомендуем просмотреть необходимую информацию или экспортировать важные данные перед тем, как приступить к этому необратимому действию. Если у вас есть вопросы или вам нужна помощь, пожалуйста, не стесняйтесь обращаться в нашу службу поддержки. Хотите ли вы продолжить отмену?',
    allDrivings: 'Общее количество заказов',
    allDrivingsAccepted: 'Общее количество принятых заказов',
    allDrivingsCanceled: 'Общее количество отклоненных заказов',
    allVehicles: 'Общее количество автомобилей',
    allUsers: 'Общее количество пользователей',
    areYouSure: 'Вы уверены?',
    makeRoutesAvailable: 'Сделать маршруты доступными для интеграции',
    percentage: 'Процент',
    deleteImageMessage: 'Вы уверены, что хотите удалить это изображение?',
    deleteImageTitle: 'Удалить изображение',
    cancelSubscriptionTitle: 'Отменить подписку',
    subscriptionType: 'Тип подписки',
    billingDate: 'Дата выставления счета',
    transactions: 'Транзакции',
    articlesOfLaw: 'Статьи закона',
    country: 'Страна',
    referent: 'Референт',
    language: 'Язык',
    gnet: 'GNET',
    affiliateId: 'Affiliate ID',
    trialEndsAt: 'Пробный период заканчивается',
    languageTooltip:
      'Язык по умолчанию для новых пользователей и язык, на котором будут отправляться все корпоративные электронные письма (не письма отдельных пользователей).',
    organisationType: 'Тип организации',
    regular: 'Обычный',
    affiliateSender: 'Аффилиат-отправитель',
    affiliateReceiver: 'Аффилиат-получатель',
    dispatcher: 'Диспетчер',
  },
  transactionsHeaders: {
    invoiceNumber: 'Номер счета',
    status: 'Статус',
    total: 'Итого',
    currency: 'Валюта',
    billedAt: 'Дата выставления',
    actions: 'Действия',
  },
  transactionsPage: {
    newItem: 'Новая запись транзакции',
    editItem: 'Редактировать транзакцию',
    id: 'ID',
    invoiceNumber: 'Номер счета',
    status: 'Статус',
    sent: 'Отправлено',
    completed: 'Завершено',
    total: 'Итого',
    currency: 'Валюта',
    billedAt: 'Дата выставления',
    actions: 'Действия',
    deleteTransaction: 'Удалить транзакцию',
    deleteMessage: 'Вы уверены, что хотите удалить эту транзакцию?',
    currentSubscription: 'Текущий пакет',
    nextBillingDate: 'Дата следующего счета',
    numberOfSeatsTaken: 'Количество занятых мест в команде',
    numberOfSeatsAvailable: 'Количество доступных мест в команде',
  },
  currenciesHeaders: {
    name: 'Название',
    code: 'Код',
    symbol: 'Символ',
    active: 'Активная',
    default: 'Основная',
    actions: 'Действия',
  },
  currenciesPage: {
    newItem: 'Новая запись валюты',
    editItem: 'Изменить валюту',
    id: 'ID',
    currency: 'Валюта',
    code: 'Код',
    symbol: 'Символ',
    deleteMessage: 'Вы уверены, что хотите удалить эту валюту?',
  },
  paymentMethodsHeaders: {
    name: 'Название',
    active: 'Активный',
    default: 'Основной',
    priceVisible: 'Цена видима',
    actions: 'Действия',
  },
  paymentMethodsPage: {
    newItem: 'Новый способ оплаты',
    editItem: 'Изменить способ оплаты',
    id: 'ID',
    paymentMethod: 'Способ оплаты',
    deleteMessage: 'Вы уверены, что хотите удалить этот способ оплаты?',
  },
  feedbacksHeaders: {
    subject: 'Тема',
    organisation: 'Организация',
    user: 'Пользователь',
    actions: 'Действия',
  },
  passengersHeaders: {
    firstName: 'Имя',
    lastName: 'Фамилия',
    phoneNumber: 'Номер телефона',
    email: 'Электронная почта',
    passport: 'Паспорт',
    nationality: 'Национальность',
    actions: 'Действия',
  },
  checkpointsHeaders: {
    checkpointLocation: 'Местоположение контрольной точки',
    arrivalTime: 'Время прибытия',
    actions: 'Действия',
  },
  tables: {
    noDataText: 'Нет данных',
    search: 'Поиск',
    drivings: 'Заказы на поездки',
    drivingsByPage: 'Заказы на поездки на странице',
    vehicles: 'Транспортные средства',
    vehiclesByPage: 'Транспортные средства на странице',
    vehiclesClasses: 'Классы автомобилей',
    vehiclesClassesByPage: 'Классы автомобилей на странице',
    expenses: 'Расходы',
    expensesByPage: 'Затраты на страницу',
    vehiclesMaintenance: 'Техническое обслуживание транспортных средств',
    vehiclesMaintenanceByPage: 'Техническое обслуживание транспортных средств на странице',
    reportsByPage: 'Отчеты на странице',
    currencies: 'Валюты',
    currencieseByPage: 'Валюты на странице',
    payments: 'Способы оплаты',
    paymentsByPage: 'Способы оплаты на странице',
    saasAdministrators: 'Все администраторы',
    users: 'Все пользователи',
    usersByPage: 'Пользователи на странице',
    clients: 'Все клиенты',
    clientsByPage: 'Клиенты на странице',
    organisations: 'Все организации',
    organisationsByPage: 'Организации на странице',
    rebates: 'Скидки',
    rebatesByPage: 'Скидки на странице',
    cards: 'Карты',
    cardsByPage: 'Карты на странице',
    transactions: 'Транзакции',
    transactionsByPage: 'Транзакции на странице',
    pricingRegions: 'Ценовые регионы',
    pricingRegionsByPage: 'Ценовые регионы по странице',
    feedback: 'Отзывы',
    feedbacksByPage: 'Отзывы на странице',
    notifications: 'Уведомления',
    notificationsByPage: 'Уведомления на странице',
    all: 'Все',
    passengers: 'Пассажиры',
    passengersByPage: 'Пассажиры на странице',
    of: 'из',
    checkpoints: 'Путевые точки',
    checkpointsByPage: 'Путевые точки на странице',
    invoices: 'Счета',
    invoicesByPage: 'Счета по странице',
    drivingOffers: 'Предложения по вождению',
    drivingOffersByPage: 'Предложения по вождению на странице',
    smsMessages: 'SMS-сообщения',
    smsMessagesByPage: 'SMS-сообщения на странице',
    resourcesUsed: 'Использованные ресурсы',
    resourcesUsedByPage: 'Ресурсы по страницам',
    inNext3Days: 'В течение следующих 3 дней',
    notConfirmedOrPaid: 'Не подтверждено или не оплачено',
    inNext5Days: 'В течение следующих 5 дней',
    latestReviews: 'Последние отзывы',
    passengerReviews: 'Отзывы пассажиров',
    driversByPage: 'Водители на странице',
    reviewsByPage: 'Отзывы на странице',
    partners: 'Партнеры',
    partnersByPage: 'Партнеры на странице',
    sentRequests: 'Отправленные запросы',
    receivedRequests: 'Полученные запросы',
    requestsByPage: 'Запросы на странице',
    pricingZones: 'Зоны и районы',
    pricingZonesByPage: 'Зоны и районы по странице',
    pricingPerZones: 'Цены по зонам',
    pricingPerZonesByPage: 'Цены по странице',
    pricingPerHour: 'Цены за час',
    pricingPerHourByPage: 'Цены по странице',
  },
  statistics: {
    numberOfAcceptedDrivings: 'Количество принятых заказов на поездку',
    numberOfRejectedDrivings: 'Количество отклоненных заказов на поездку для водителя',
    vehicleDrivingsNumber: 'Количество заказов на поездку на транспортное средство',
    vehicleProfit: 'Заработок на транспортном средстве',
    monthDrivingsNumber: 'Количество заказов на поездку в месяц',
    driverProfit: 'Заработок водителя',
  },
  reportsHeaders: {
    vehicle: 'Транспортное средство',
    expenses: 'Расходы',
    profit: 'Прибыль',
    earnings: 'Заработок',
    daysWorking: 'Рабочие дни',
    usageIndex: 'Индекс использования',
    client: 'Клиент',
    numberOfDrivings: 'Количество Поездок',
    percentForClient: 'Цена для клиента',
    sumOfPrice: 'Количество',
  },
  packages: {
    active: 'Активный',
    starterTitle: 'Стартовый',
    monthlyPackagesMessage: 'Ежемесячная подписка на организацию.',
    starterText:
      'Идеально подходит для физических лиц или небольших команд, которым нужна только базовая функциональность.',
    starterListItemOne: 'Мобильные приложения',
    starterListItemTwo: 'Уведомления push',
    starterListItemThree: 'Доступ к администрации',
    starterListItemFour: 'Интеграция с веб-сайтом',
    standardTitle: 'Стандартный',
    standardText: 'Отлично подходит для организаций с более чем 5 водителями.',
    standardListItemOne: 'Пакет STARTER включен',
    standardListItemTwo: 'Нет ограничения на количество водителей',
    standardListItemThree: 'Доступность 99,9%',
    standardListItemFour: 'Отчеты и управление транспортными средствами',
    premiumTitle: 'Премиум',
    premiumText: 'Идеально подходит для организаций лимузинского обслуживания, работающих с точными данными.',
    premiumListItemOne: 'Пакет STANDARD включен',
    premiumListItemTwo: 'Бизнес-интеллект и прогнозирование расходов',
    premiumListItemThree: 'Экспорт данных',
    premiumListItemFour: 'Еженедельные и ежемесячные отчеты',
    warningOne: '* Показанные цены - ежемесячная подписка на пользователя',
    warningTwo:
      '* После перехода на новый пакет нельзя переключиться на более маленький пакет в течение следующих 6 месяцев',
    warningThree:
      '* Пользователи демонстрационного плана имеют доступ к функциональности, предоставляемой премиальным пакетом',
    warningOneAnnualPackage: 'Нужно больше? У вас есть большая организация? У нас есть пакет ПРЕДПРИЯТИЕ для вас.',
    warningTwoAnnualPackage: 'и наша команда по продажам свяжется с вами в ближайшее время.',
    packageChange: 'Сменить пакет',
    changePackage: 'Вы уверены, что хотите перейти на',
    package: 'пакет',
    annualPricing: 'Годовая ценовая политика',
    monthlyPricing: 'Ежемесячная ценовая политика',
    packageType10: 'Команда 10',
    packageType25: 'Команда 25',
    packageType50: 'Команда 50',
    'Team 10': 'Команда 10',
    'Team 25': 'Команда 25',
    'Team 50': 'Команда 50',
    annualPackagesMessage: 'Годовая подписка на организацию.',
    packageTypeSoloMainMessage: 'Только 1 учетная запись пользователя.',
    packageType10MainMessage: 'До 10 учетных записей пользователя.',
    packageType25MainMessage: 'До 25 учетных записей пользователя.',
    packageType50MainMessage: 'До 50 учетных записей пользователя.',
    allFeaturesIncluded: 'Все функции включены.',
    unlimitedRides: 'Неограниченное количество поездок и транспортных средств.',
    trialPeriod: 'Включен пробный период.',
    onboardingFeeOptional: 'Опциональная плата за подключение.',
    noHiddenCosts: 'Без скрытых затрат.',
    freeSmsFlights10: 'Бесплатно 15 SMS и 50 проверок рейсов в месяц.',
    freeSmsFlights25: 'Бесплатно 35 SMS и 100 проверок рейсов в месяц.',
    freeSmsFlights50: 'Бесплатно 50 SMS и 200 проверок рейсов в месяц.',
  },
  successPaymentPage: {
    message: 'Оплата прошла успешно. Спасибо.',
  },
  demoExpiring: {
    notification: 'Уведомление',
    expiredSubscriptionMessage:
      'Мы информируем вас о том, что ваша подписка на эту платформу истекла. Чтобы продолжить пользоваться нашими услугами, пожалуйста, обновите свою подписку.',
  },
  login: {
    username: 'Имя пользователя',
    password: 'Пароль',
    rememberMe: 'Запомнить меня',
    login: 'Войти',
    noAccount: 'Еще нет аккаунта? Вы можете зарегистрироваться',
    forgetPassword: 'Забыли пароль? Вы можете сбросить его',
    registerHere: 'Здесь',
    email: 'Email',
    enterEmail: 'Пожалуйста, введите ваш email',
    enterPassword: 'Пожалуйста, введите ваш новый пароль',
    signInWithGoogle: 'Войти с помощью Google',
  },
  registration: {
    register: 'Регистрация',
    name: 'Название организации',
    address: 'Адрес',
    phoneNumber: 'Номер телефона',
    email: 'Электронная почта',
    companyId: 'Идентификатор компании',
    vat: 'НДС',
    language: 'Язык',
    package: 'Пакет',
    accept: 'Я принимаю',
    terms: 'условия использования',
    successfullyRegistered:
      'Пользователь успешно зарегистрирован. Вы получите электронное письмо с ссылкой для подтверждения.',
    haveAccount: 'У вас уже есть аккаунт? Вы можете войти',
    loginHere: 'Здесь',
    trialPeriodInfo:
      'Включен бесплатный пробный период 14 дней. Оплата будет осуществляться после окончания испытательного периода.',
  },
  driving: {
    drivingType: 'Тип бронирования',
    general: 'Общая информация',
    newDriving: 'Запланировать трансфер',
    newDailyRent: 'Запланировать аренду на день',
    areYouSure: 'Вы уверены?',
    vehicleIsUnavailable: 'Транспортное средство занято.',
    vehicleIsDamaged: 'Транспортное средство повреждено.',
    sureAboutThisVehicle: 'Вы уверены, что хотите выбрать это транспортное средство?',
    driverUnavaliable: 'Водитель занят.',
    sureAboutThisDriver: 'Вы уверены, что хотите выбрать этого водителя?',
    checkVehicleSeatAvailability: 'Это транспортное средство не может вместить такое количество пассажиров.',
    cancelDriving: 'Вы уверены, что хотите отменить заказ на поездку?',
    finishDriving: 'Вы уверены, что хотите завершить заказ на поездку?',
    noShowDriving: 'Вы уверены, что хотите отметить заказ на вождение как неявку?',
    markDrivingAsStarted: 'Вы уверены, что хотите отметить заказ на вождение как начатый?',
    deleteDriving: 'Вы уверены, что хотите удалить заказ на поездку?',
    newTransfer: 'Новый трансфер',
    driving: 'Заказ на поездку',
    comments: 'Комментарии',
    id: 'ID',
    locationFrom: 'Пункт отправления',
    locationTo: 'Пункт назначения',
    vehicleClass: 'Класс транспортного средства',
    vehicle: 'Транспортное средство',
    driver: 'Водитель',
    pickupTime: 'Время подачи',
    clientConfirmed: 'Клиент подтвердил',
    description: 'Описание',
    extraInfo: 'Дополнительная информация',
    drivingNotAccepted: 'Заказ на поездку не был принят:',
    currency: 'Валюта',
    paymentMethod: 'Способы оплаты',
    price: 'Цена',
    paid: 'Оплачено',
    distance: 'Расстояние (км)',
    distanceMi: 'Расстояние (миль)',
    expectedTime: 'Ожидаемая продолжительность',
    waitingTime: 'Время ожидания (ч)',
    EnterTheNumberOfHoursWaiting: 'Введите количество часов ожидания',
    dropOffTime: 'Время прибытия',
    comebackTime: 'Время возврата',
    acceptUntil: 'Принять до',
    drivingRequired: 'Обязателен заказ на поездку',
    comment: 'Комментарий',
    attachments: 'Вложения',
    addFiles: '+ Добавить файлы',
    deleteFiles: 'Вы уверены, что хотите удалить этот файл?',
    passengers: 'Пассажиры',
    addPassenger: '+ Добавить пассажира',
    deletePassenger: 'Вы уверены, что хотите удалить этого пассажира?',
    chooseClient: 'Выберите клиента',
    costs: 'Затраты',
    checkpoints: 'Путевые точки',
    addCheckpoint: 'Добавить путевую точку',
    deleteCheckpoint: 'Вы уверены, что хотите удалить эту путевую точку?',
    waitingBoardText: 'Текст ожидания на доске',
    flightNumber: 'Номер рейса',
    flightInfo: 'Информация о рейсе',
    createdBy: 'Заказ на вождение создан в <b>{time}</b> пользователем <b>{user}</b>',
    client: 'Клиент',
    note: 'Заметка',
    noteDriver: 'Заметка для водителя',
    noteDispatcher: 'Заметка для диспетчера',
    passenger: 'Пассажир',
    fullName: 'Полное имя',
    phoneNumber: 'Номер телефона',
    email: 'Электронная почта',
    suitcasesNumber: 'Количество чемоданов',
    passengersNumber: 'Количество пассажиров',
    babySeatsNumber: 'Количество детских сидений',
    stopsNumber: 'Количество остановок',
    steps: {
      mainInfo: 'Основная информация',
      additionalInfo: 'Дополнительная информация',
      review: 'Обзор',
    },
    commissionAmount: 'Комиссия',
    sendSms: 'Отправить SMS',
    message: 'Сообщение',
    acceptDriving: 'Вы уверены, что хотите принять заказ на вождение?',
    rejectDriving: 'Вы уверены, что хотите отклонить заказ на вождение?',
    calculatePriceViaZonePricing: 'Рассчитать цену через ценовую зону',
    cannotCalculatePriceViaZonePricingForDailyRents:
      'Невозможно рассчитать цену через зонирование для ежедневной аренды',
    disableCalculatePriceViaZonePricing:
      'Невозможно рассчитать цену через ценовую зону без местоположения, валюты и класса автомобиля',
    numberOfPassengers: 'Количество пассажиров',
    numberOfSuitcases: 'Количество чемоданов',
    vat: 'НДС',
    vatPercentage: 'Процент НДС',
    priceType: 'Тип цены',
    accessibleForWheelchair: 'Доступно для инвалидных колясок',
    priceForWaiting: 'Цена за ожидание',
    numberOfHours: 'Количество часов',
    getAQuote: 'Запросите цену',
  },
  drivingStatus: {
    pending: 'В ожидании',
    accepted: 'Принято',
    rejected: 'Отклонено',
    expired: 'Истекло',
    canceled: 'Отменено',
    draft: 'Черновик',
    done: 'Завершено',
    noShow: 'Неявка',
    ongoing: 'Начато',
  },
  drivingOrderType: {
    dailyRent: 'По дням',
    transfer: 'Трансфер',
    hourlyDaily: 'Почасовая/ежедневная аренда',
    oneWayTransfer: 'Трансфер в один конец',
    fromAirport: 'От аэропорта',
    toAirport: 'В аэропорт',
  },
  flightInfo: {
    airlineName: 'Название авиакомпании',
    flightNumber: 'Номер рейса',
    departureAirport: 'Аэропорт отправления',
    departureCountry: 'Страна отправления',
    departureTime: 'Время отправления',
    arrivalAirport: 'Аэропорт прибытия',
    arrivalCountry: 'Страна прибытия',
    arrivalTime: 'Время прибытия',
    departureDelayed: 'Задержка вылета',
    flightStatus: 'Статус рейса',
    percentageOfFlight: 'Процент выполнения полета',
  },
  customValidations: {
    fileType: 'Тип файла .{extension} не поддерживается',
    fileSize: 'Файлы не могут быть больше {size}',
    fieldRequired: 'Поле {field} обязательно для заполнения',
    fieldType: 'Поле {field} должно быть типа {type}',
    fieldLengthMin: 'Поле {field} должно быть не менее {length}',
    fieldLengthMax: 'Поле {field} должно быть не более {length}',
    fieldUnique: 'Поле {field} должно быть уникальным',
    fieldValid: 'Поле {field} должно быть действительным',
    noActiveSubscription: 'Активная подписка не найдена',
    usersLimitExceeded: 'Превышен лимит пользователей',
    fieldBefore: '{field1} должно быть перед {field2}',
    flightNotExist: 'Рейс {field} не существует',
    gnetKeyNotValid: 'Указанный ключ Gnet недействителен.',
    gnetPassengersRequired: 'Заказ на поездку не может быть отправлен в GNET без пассажиров.',
    priceAndCurrencyRequired: 'Ссылка для оплаты не может быть создана без указания цены и валюты',
    fieldValueNotSupported: 'Значение поля {field} не поддерживается',
    noCountryCode: 'Вы не выбрали страну',
    noStripeForYourCountry: 'Stripe недоступен в вашей стране',
    stripeAlreadyEnabled: 'Stripe уже активирован',
    fieldValueMin: 'Поле {field} должно быть {min} или больше',
    fieldValueMax: 'Поле {field} должно быть {max} или меньше',
    markAsDoneDate: 'Время забора не может быть в будущем',
    phoneNumberNotValidForSms:
      'Номер телефона недействителен для отправки SMS. Действительный номер телефона должен содержать код страны.',
    affiliateIdDoesNotExist: 'Организация с указанным партнерским ID не существует.',
    numericDecimal: 'Поле {field} может содержать только числовые символы (разрешены десятичные)',
  },
  fieldTypes: {
    string: 'строка',
    integer: 'число',
    date: 'дата',
  },
  names: {
    username: 'имя пользователя',
    password: 'пароль',
    choose_client: 'выберите клиента',
    choose_month: 'выберите месяц',
    currency: 'валюта',
    payment_method: 'способ оплаты',
    vehicle_class: 'класс транспортного средства',
    first_name: 'имя',
    last_name: 'фамилия',
    passport: 'паспорт',
    nationality: 'национальность',
    plate_number: 'государственный номер',
    brand: 'марка автомобиля',
    number_of_passengers: 'количество пассажиров',
    fuel_consumption: 'расход топлива',
    year_of_manufacture: 'год выпуска',
    price_per_km: 'цена за км',
    price_per_mi: 'цена за милю',
    price_per_hour: 'цена в час',
    price_per_waiting_hour: 'цена за час ожидания',
    vehicle: 'транспортное средство',
    description: 'описание',
    time_from: 'время с',
    time_to: 'время до',
    name: 'имя',
    cost_type: 'тип расхода',
    cost_time: 'время расхода',
    price: 'цена',
    rebate_min: 'минимальное количество заказов',
    rebate_max: 'максимальное количество заказов',
    rebate: 'скидка',
    phone_number: 'номер телефона',
    organisation: 'организация',
    role: 'роль',
    image: 'изображение',
    location_from: 'место отправления',
    location_to: 'место назначения',
    pickup_time: 'время подачи',
    distance: 'расстояние',
    waiting_time: 'время ожидания',
    comment: 'комментарий',
    address: 'адрес',
    vat: 'НДС',
    company_id: 'идентификационный номер компании',
    company_personal_id: 'id компании/личный id',
    licence: 'лицензия',
    warrant_number: 'номер решения',
    seal: 'печать',
    signature: 'подпись',
    email: 'электронная почта',
    percentage: 'процент',
    organizationName: 'название организации',
    package: 'пакет',
    choose_currency: 'выберите валюту',
    team_10_price: 'цена за команду 10',
    team_10_paddle_price_id: 'ID цены Paddle для команды 10',
    team_25_price: 'цена за команду 25',
    team_25_paddle_price_id: 'ID цены Paddle для команды 25',
    team_50_price: 'цена за команду 50',
    team_50_paddle_price_id: 'ID цены Paddle для команды 50',
    team_10_price_monthly: 'цена за команду 10',
    team_10_paddle_price_id_monthly: 'ID цены Paddle для команды 10',
    team_25_price_monthly: 'цена за команду 25',
    team_25_paddle_price_id_monthly: 'ID цены Paddle для команды 25',
    team_50_price_monthly: 'цена за команду 50',
    team_50_paddle_price_id_monthly: 'ID цены Paddle для команды 50',
    countries: 'страны',
    invoice_number: 'номер счета',
    status: 'статус',
    total: 'итого',
    billed_at: 'дата выставления',
    articles_of_law: 'статьи закона',
    country: 'страна',
    referent: 'референт',
    language: 'язык',
    location: 'местоположение',
    arrival_time: 'время прибытия',
    waiting_board_text: 'текст ожидания на доске',
    flight_number: 'номер рейса',
    gnet_id: 'GNET ID',
    receiver_id: 'ID получателя',
    preferred_vehicle_type: 'предпочтительный тип транспортного средства',
    reservation_type: 'тип бронирования',
    run_type: 'тип пробега',
    trip_duration_minutes: 'продолжительность поездки',
    client: 'клиент',
    client_type: 'тип клиента',
    card_holder_name: 'имя держателя карты',
    card_number: 'номер карты',
    expiry_date: 'срок действия',
    cvv: 'cvv',
    code: 'код',
    symbol: 'символ',
    quantity: 'количество',
    discount: 'скидка',
    driving_type: 'тип вождения',
    full_name: 'полное имя',
    suitcases_number: 'количество чемоданов',
    passengers_number: 'количество пассажиров',
    baby_seats_number: 'количество детских сидений',
    stops_number: 'количество остановок',
    commission_amount: 'комиссия',
    message: 'сообщение',
    affiliate_id: 'affiliate id',
    coordinates: 'координаты',
    zone_one: 'зона один',
    zone_two: 'зона два',
    price_type: 'тип цены',
    price_for_waiting: 'цена за ожидание',
    number_of_hours: 'количество часов',
    zones: 'Зоны',
  },
  weekDays: {
    monday: 'Понедельник',
    tuesday: 'Вторник',
    wednesday: 'Среда',
    thursday: 'Четверг',
    friday: 'Пятница',
    saturday: 'Суббота',
    sunday: 'Воскресенье',
  },
  select: {
    noDataAvailable: 'Нет доступных данных',
    selected: 'выбрано',
  },
  excelExport: {
    to_date_range: 'Отчет не может быть сгенерирован для будущих дат. Пожалуйста, введите корректные даты',
    overlap_range: 'Время окончания не может быть больше времени начала.',
  },
  setPassword: {
    success: 'Письмо отправлено',
    checkEmail: 'Проверьте вашу почту для получения дальнейших инструкций.',
    already_changed: 'Уже изменено',
    send_new_reset_request: 'Вы уже меняли пароль с этой ссылкой. Отправьте новый запрос на сброс пароля.',
  },
  statisticsPage: {
    reportType: 'Тип отчета',
    reportTime: 'Время отчета',
    vehicleUtilization: 'Использование транспортных средств',
    costEffectiveness: 'Эффективность затрат',
    generalReport: 'Общий отчет',
    clientsReport: 'Отчет по Клиентам',
  },
  profile: {
    yourProfile: 'Ваш профиль',
    name: 'Имя',
    role: 'Роль',
    username: 'Имя пользователя',
    password: 'Пароль',
    phoneNumber: 'Номер телефона',
    email: 'Электронная почта',
    percentage: 'Процент',
  },
  gpsMaps: {
    noTransfers: 'Нет текущих или предстоящих переводов.',
    map: 'Карта',
    transfers: 'Переводы',
    drivers: 'Водители',
    ongoing: 'Текущие',
    upcoming: 'Предстоящие',
    driving: 'Вождение',
    driver: 'Водитель',
    vehicle: 'Транспортное средство',
    requestDriverLocation: 'Запрос местоположения водителя',
    lastRecordedLocation: 'Последнее зафиксированное местоположение',
  },
  driverStatus: {
    available: 'Доступен',
    offline: 'Не в сети',
    inTransfer: 'В процессе передачи',
  },
  onboardingTutorialMain: {
    languageStep: 'Здесь вы можете установить предпочитаемый язык платформы.',
    notificationsStep: 'Здесь вы можете найти свои уведомления.',
    addTransferStep: 'Нажмите эту кнопку, чтобы создать новое бронирование, одноразовые трансферы и аренду на день.',
    sideMenuButtonStep: 'Нажмите на эту иконку, чтобы показать или скрыть меню.',
    sideMenuExpStep:
      'Отсюда вы можете перейти к основным разделам платформы: Бронирования, Парк, Затраты, Администрация, Счета, Статистика и т.д.',
    sideMenuButtonCloseStep: 'Нажмите на ту же иконку, чтобы скрыть меню.',
    supportStep: 'Если у вас есть вопросы или проблемы, свяжитесь со службой поддержки, нажав здесь.',
  },
  onboardingTutorialVehicles: {
    tableStep: 'Транспортные средства будут перечислены в этой таблице после их добавления.',
    searchStep: 'Вы можете легко найти транспортные средства, выполняя поиск по марке, номерному знаку, году и т.д.',
    addStep:
      'Добавьте новые транспортные средства, нажав кнопку «Добавить». Пожалуйста, добавьте все «Классы транспортных средств» перед вводом транспортных средств.',
  },
  onboardingTutorialStatistics: {
    typeStep:
      'В этом разделе вы можете найти все статистические данные, отображаемые в виде графиков. Выберите здесь тип статистики.',
    intervalStep: 'Выберите интервал для просмотра статистики.',
    downloadStep: 'Вы также можете скачать и распечатать эти таблицы.',
  },
  onboardingTutorialGps: {
    tabOneStep:
      'В GPS модуле вы можете отслеживать своих водителей и трансферы в одном месте. Выберите эту вкладку для просмотра текущих и предстоящих (в течение следующих 24 часов) трансферов.',
    tabTwoStep:
      'Выберите транспортные средства, чтобы увидеть их на карте. Вы можете назначать доступные транспортные средства на трансферы прямо отсюда.',
    mapStep: 'Выберите или отмените выбор транспортных средств, нажав на их значок на карте.',
  },
  onboardingTutorialInvoices: {
    tableStep: 'Все счета будут отображены в этой таблице от новых к старым.',
    searchStep: 'Легко найдите счета, выполнив поиск по номеру, сумме, имени клиента, дате создания и т.д.',
    addStep: 'Создайте новые счета, нажав кнопку «Добавить».',
  },
  onboardingTutorialOffers: {
    tableStep: 'Предложения будут перечислены в этой таблице после добавления, от новых к старым.',
    searchStep: 'Легко найдите предложения, выполнив поиск по номеру, сумме или описанию.',
    addStep: 'Создайте новые предложения, нажав кнопку «Добавить».',
  },
  onboardingTutorialResources: {
    infoStep: 'Просмотрите оставшиеся ресурсы и при необходимости приобретите больше SMS-сообщений.',
    tableStep:
      'В этой таблице вы найдете аудит и историю всех использованных ресурсов (отправленных SMS и проверенных рейсов).',
    searchStep: 'Легко найдите ресурсы, выполнив поиск по содержимому, пользователю или дате создания.',
  },
  onboardingTutorialPassengerReviews: {
    tableStep: 'Отзывы пассажиров будут перечислены в этой таблице, от новых к старым.',
    searchStep: 'Легко найдите отзывы пассажиров, выполнив поиск по номеру или комментарию пассажира.',
  },
  onboardingTutorialPricingZones: {
    tableStep: 'Ценовые зоны будут перечислены в этой таблице после добавления.',
    searchStep: 'Легко найдите ценовые зоны, выполняя прямой поиск.',
    addStep: 'Создайте новые ценовые зоны, нажав кнопку «Добавить».',
  },
  onboardingTutorialPricingZoneRelations: {
    tableStep: 'Связи ценовых зон (цены) будут перечислены в этой таблице после добавления.',
    searchStep: 'Легко найдите связи ценовых зон, выполнив прямой поиск.',
    addStep:
      'Создайте новые связи ценовых зон (цены), нажав кнопку «Добавить». Обратите внимание, что сначала нужно добавить «Ценовые зоны».',
  },
  onboardingTutorialPricingZoneHourlyRelations: {
    tableStep:
      'Все зоны цен указаны в этой таблице. Открыв определённую зону, вы можете установить цены для этой зоны.',
    searchStep: 'Легко находите зоны цен с помощью прямого поиска.',
  },
  onboardingTutorialDashboard: {
    infoStep: 'Просмотр краткого обзора статистики вашей организации за последние 30 дней.',
    next3DaysStep: 'Бронирования на следующие 3 дня будут отображены в этой таблице.',
    notPaidOrConfirmedStep:
      'Бронирования на следующие 7 дней, которые не подтверждены или не оплачены, будут отображены в этой таблице.',
    next5DaysStep: 'Занятые водители на следующие 5 дней будут отображены в этой таблице.',
    latestReviewsStep: 'Ваши последние отзывы о поездках будут отображены в этой таблице.',
  },
  onboardingTutorialAffiliate: {
    tabStep: 'Переключаясь между вкладками, вы можете видеть своих партнеров, отправленные или полученные запросы.',
    idStep: 'Здесь вы можете найти свой affiliate ID, который можно поделиться с партнерами.',
    addStep: 'Создайте новый запрос на партнерство, нажав кнопку «Добавить».',
  },
  onboardingTutorialAdvancedSettings: {
    advancedSettings:
      'Нажмите здесь, чтобы получить доступ к расширенным настройкам вашей организации и настроить все свойства и интеграции.',
  },
  advancedSettings: {
    advancedSettings: 'Расширенные настройки',
    distanceUnit: 'Единица измерения расстояния',
    distanceUnitDescription: 'Выберите единицу измерения расстояния.',
    kilometers: 'Километры',
    miles: 'Мили',
    dateFormat: 'Формат даты',
    dateFormatDescription: 'Выберите предпочитаемый формат даты.',
    ddmmyyyy: 'DD-MM-YYYY',
    mmddyyyy: 'MM-DD-YYYY',
    mandatoryDrivings: 'Обязательные поездки',
    mandatoryDrivingsDescription: 'Выберите, если хотите сделать все поездки обязательными по умолчанию.',
    mandatoryDrivingsDefault: 'Обязательные Поездки (По умолчанию)',
    paidDrivings: 'Платные заказы на поездку',
    paidDrivingsDescription: 'Отметьте, чтобы сделать все заказы на поездку платными по умолчанию.',
    paidDrivingsDefault: 'Платные заказы на поездку (По умолчанию)',
    notificationsEmail: 'Электронная почта для уведомлений',
    notificationsEmailDescription:
      'Измените адрес электронной почты по умолчанию для получения уведомлений (если вы не хотите использовать адрес электронной почты вашей организации).',
    email: 'Электронная почта',
    articlesOfLaw: 'Статьи закона',
    integrations: 'Интеграции',
    gnetId: 'GNET ID',
    gnetDescription:
      'GNET — это комплексная платформа, предназначенная для оптимизации отслеживания в режиме реального времени, управления парком и диспетчерских операций в транспортной отрасли.',
    makeRoutesAvailable: 'Сделать маршруты доступными для интеграции',
    makeRoutesAvailableDescription:
      'Активируйте свой API-ключ, чтобы интегрировать LimoExpress с вашим веб-сайтом, ERP-системой и другими системами.',
    makePublicFormAvailable: 'Сделать публичную форму доступной',
    makePublicFormAvailableDescription:
      'Активируйте эту опцию, чтобы получить доступ к публичной странице бронирования, чтобы ваши клиенты могли получить доступ к расширенной форме бронирования и легко создавать аккаунты. Вы можете использовать ссылку в новостных рассылках, на веб-сайте, в листовках; это уникальная ссылка только для вашей компании. Кроме того, вы можете получить фрагмент кода, чтобы встроить форму бронирования прямо на свой сайт.',
    emailsEnabled: 'Включенные электронные письма',
    emailsEnabledDefault: 'Включенные электронные письма (По умолчанию)',
    emailsEnabledDescription: 'Отметьте, чтобы получать уведомления по электронной почте.',
    passengerReviewsEnabled: 'Отзывы пассажиров включены',
    passengerReviewsEnabledDefault: 'Отзывы пассажиров включены',
    passengerReviewsEnabledDescription:
      'Активировав эту функцию, ваши пассажиры (если введен email) получат подтверждение по электронной почте после завершения поездки (отмечена как Завершена) и смогут оставить отзыв о ваших услугах.',
    vat: 'НДС',
    vatPercentage: 'Процент НДС',
    vatPercentageDescription: 'Введите процент НДС.',
    invoicePaymentInstructions: 'Инструкции по оплате счета',
    stripe: 'Stripe',
    stripeDescription:
      'Активируйте Stripe, чтобы иметь возможность взимать плату с клиентов за свои услуги. LimoExpress не берет комиссию.',
    dataExport: 'Экспорт данных',
    dataExportDescription: 'Вы можете экспортировать все свои данные, и они будут отправлены на ваш электронный адрес.',
    sureAboutDataExport:
      'Вы уверены, что хотите экспортировать все свои данные? Если да, все данные будут отправлены на ваш электронный адрес в течение следующих 2 часов.',
    defaultPriceType: 'Тип цены по умолчанию',
    defaultPriceTypeDescription: 'Установите тип цены по умолчанию для определения отображения цен.',
    NET: 'ЧИСТЫЙ',
    GROSS: 'ГРУБЫЙ',
    link: 'Ссылка',
    code: 'Код',
    language: 'Язык',
    chooseSize: 'Выберите размер',
    small: 'Маленький',
    medium: 'Средний',
    large: 'Большой',
  },
  drivingExcelCheckboxes: {
    drivingNumber: 'Номер заказа',
    drivingType: 'Тип бронирования',
    drivingStatus: 'Статус',
    locationFrom: 'Место отправления',
    locationTo: 'Пункт назначения',
    driver: 'Водитель',
    client: 'Клиент',
    plateNumber: 'Регистрационный номер',
    note: 'Описание',
    fuelConsumption: 'Расход топлива',
    pickupTime: 'Время отправления',
    numberOfPassengers: 'Количество пассажиров',
    passengers: 'Пассажиры',
    price: 'Цена',
    paid: 'Оплачено',
    paymentMethod: 'Метод оплаты',
    waitingTime: 'Время ожидания (ч)',
    distance: 'Расстояние (км)',
    distanceMi: 'Расстояние (миль)',
    selectAll: 'Выбрать все',
  },
  gnet: {
    gnetInfo: 'Информация GNET',
    requesterId: 'ID запросившего',
    receiverId: 'ID получателя',
    preferredVehicleType: 'Предпочтительный тип автомобиля',
    reservationType: 'Тип бронирования',
    runType: 'Тип поездки',
    price: 'Цена',
    currency: 'Валюта',
    noteDriver: 'Заметка для водителя',
    noteDispatcher: 'Заметка для диспетчера',
    tripDurationMinutes: 'Продолжительность поездки (минуты)',
  },
  invoicesHeaders: {
    invoiceNumber: 'Номер счета',
    client: 'Клиент',
    totalPrice: 'Общая стоимость',
    drivings: 'Поездки',
    paid: 'Оплачено',
    currency: 'Валюта',
    createdAt: 'Создано',
    actions: 'Действия',
  },
  invoicesPage: {
    newItem: 'Новая запись счета',
    chooseClient: 'Выберите клиента',
    chooseDrivings: 'Выберите поездки',
    allDrivings: 'Все поездки',
    deleteMessage: 'Вы уверены, что хотите удалить этот счет?',
    markInvoiceAsPaid: 'Вы уверены, что хотите отметить этот счет как оплаченный?',
  },
  drivingOffersHeaders: {
    number: 'Номер предложения по вождению',
    totalPrice: 'Общая стоимость',
    description: 'Описание',
    paid: 'Оплачено',
    currency: 'Валюта',
    createdAt: 'Создано',
    actions: 'Действия',
  },
  drivingOffersPage: {
    newItem: 'Новая запись предложения по вождению',
    item: 'Позиция',
    description: 'Описание',
    title: 'Заголовок',
    vehicleClass: 'Класс транспортного средства',
    quantity: 'Количество',
    price: 'Цена',
    discount: 'Скидка',
    vat: 'НДС',
    deleteMessage: 'Вы уверены, что хотите удалить это предложение по вождению?',
    markDrivingOfferAsPaid: 'Вы уверены, что хотите отметить это предложение поездки как оплачено?',
  },
  modal: {
    reservationSuccessTitle: 'Успех',
    reservationSuccessMessage: 'Запрос на бронирование успешно отправлен. Кто-то свяжется с вами в ближайшее время.',
    reservationErrorTitle: 'Форма отключена',
    reservationErrorMessage:
      'Форма в настоящее время отключена организацией. Пожалуйста, свяжитесь с поддержкой или попробуйте снова позже.',
  },
  dashboardPage: {
    drivingCountToday: 'Количество заказов на вождение (сегодня)',
    drivingCountThisMonth: 'Количество заказов на вождение (за последние 30 дней)',
    dailyRentsAndTransfers: 'Количество заказов на вождение (ежедневная аренда/трансферы)',
    drivingTotalAmount: 'Общая сумма',
    driversCount: 'Активные водители (±7 дней)',
    passengerCount: 'Количество пассажиров (за последние 30 дней)',
  },
  reviewsHeaders: {
    rating: 'Рейтинг',
    comment: 'Комментарий',
    createdAt: 'Создано',
  },
  resourceOverviewHeaders: {
    actionType: 'Тип действия',
    user: 'Пользователь',
    action: 'Действие',
    createdAt: 'Создано',
  },
  resourceOverviewPage: {
    smsMessagesLeft: 'Количество оставшихся SMS-сообщений',
    flightsCheckLeft: 'Количество оставшихся проверок рейсов',
    sms: 'SMS',
    flightCheck: 'Проверка рейса',
  },
  affiliateHeaders: {
    organisation: 'Организация',
    createdAt: 'Создано',
    actions: 'Действия',
  },
  affiliatePage: {
    partners: 'Партнеры',
    sentRequests: 'Отправленные запросы',
    receivedRequests: 'Полученные запросы',
    acceptRequest: 'Вы уверены, что хотите принять этот запрос?',
    rejectRequest: 'Вы уверены, что хотите отклонить этот запрос?',
    deletePartner: 'Вы уверены, что хотите удалить этого партнера?',
    deleteRequest: 'Вы уверены, что хотите удалить запрос?',
    newItem: 'Новый запрос',
    affiliateId: 'Affiliate ID',
    registerNewPartner: 'Зарегистрировать нового партнера',
    registrationSuccessfull: 'Регистрация успешна',
    registrationSuccessfullDescription:
      'Партнер успешно зарегистрирован! Пожалуйста, сообщите партнеру, что он получит письмо для подтверждения аккаунта и установки пароля. После этого он сможет начать использовать платформу.',
  },
  affiliate: {
    affiliateInfo: 'Affiliate информация',
    affiliate: 'Affiliate',
    partner: 'Партнер',
    preferredVehicleType: 'Предпочитаемый тип автомобиля',
    driverInfo: 'Информация о водителе',
    vehicleInfo: 'Информация о транспортном средстве',
    price: 'Цена',
    currency: 'Валюта',
  },
  pricingZonesHeaders: {
    name: 'Название зоны',
    code: 'Код',
    postalCode: 'Почтовый индекс',
    actions: 'Действия',
  },
  pricingZonesPage: {
    newItem: 'Новая запись зоны цен',
    editItem: 'Изменение зоны цен',
    id: 'ID',
    name: 'Название зоны',
    code: 'Код',
    deleteMessage: 'Вы уверены, что хотите удалить эту зону цен?',
    postalCode: 'Почтовый индекс',
    postalCodeDescription: 'Определите зону на карте с помощью почтового индекса',
    postalCodeDisabled: 'Пожалуйста, сначала введите почтовый индекс',
  },
  pricingZoneRelationsHeaders: {
    zoneOne: 'Зона один',
    zoneTwo: 'Зона два',
    client: 'Клиент',
    actions: 'Действия',
  },
  pricingZoneRelationsPage: {
    newItem: 'Новая запись связи зон ценообразования',
    editItem: 'Изменение связи зон ценообразования',
    zone: 'Зона',
    zoneOne: 'Зона один',
    zoneTwo: 'Зона два',
    chooseClient: 'Выберите клиента',
    chooseClientFrom: 'Клонировать от клиента',
    chooseClientTo: 'Клонировать к клиенту',
    cloneItem: 'Клонировать связь зон ценообразования',
    zoneRelations: 'Связи зон',
    cloneItemHourly: 'Клонирование почасовых цен',
    zones: 'Зоны',
    fromTo: 'От - До',
    percentage: 'Процент',
    deleteMessage: 'Вы уверены, что хотите удалить эту связь зон ценообразования?',
  },
  referralPrize: {
    referToAFriend: 'Порекомендовать другу',
    referToAFriendDescription:
      'Пригласите своих друзей присоединиться к платформе, введя их адрес электронной почты ниже. Они получат персонализированное приглашение для регистрации и начала использования платформы. Кроме того, вы можете поделиться своей уникальной ссылкой для регистрации напрямую с другими! Когда кто-то регистрируется по вашей ссылке или приглашению, вы получаете награду за рекомендацию в знак благодарности. Начните приглашать и делиться уже сегодня!',
  },
};
