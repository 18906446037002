import moment from 'moment';
import store from '@/store';

export const formatDate = (date) => {
  if (date) {
    const newDate = new Date(date);

    const year = newDate.getFullYear();
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const day = newDate.getDate().toString().padStart(2, '0');

    return store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd'
      ? `${month}-${day}-${year}`
      : `${day}-${month}-${year}`;
  } else {
    return '';
  }
};

export const formatTime = (date) => {
  if (date) {
    const newDate = new Date(date);

    const hours = newDate.getHours().toString().padStart(2, '0');
    const minutes = newDate.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes}`;
  } else {
    return '';
  }
};

export const formatTimeFromSeconds = (seconds) => {
  // Calculate hours and minutes
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  // Format hours and minutes with leading zero if needed
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}

export const formatDateTime = (date) => {
  if (date) {
    const newDate = new Date(date);

    const year = newDate.getFullYear();
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const day = newDate.getDate().toString().padStart(2, '0');
    const hours = newDate.getHours().toString().padStart(2, '0');
    const minutes = newDate.getMinutes().toString().padStart(2, '0');

    return store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd'
      ? `${month}-${day}-${year} ${hours}:${minutes}`
      : `${day}-${month}-${year} ${hours}:${minutes}`;
  } else {
    return '';
  }
};

export const formatDateToDDMM = (date) => {
  if (store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd') {
    const [month, day, year] = date.split('-');

    return `${day}-${month}-${year}`;
  } else {
    return date;
  }
};

export const formatDateTimeToDDMMHHmm = (date) => {
  if (store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd') {
    const [datePart, timePart] = date.split(' ');

    const [month, day, year] = datePart.split('-');

    return `${day}-${month}-${year} ${timePart}`;
  } else {
    return date;
  }
};

export const formatDateTimeToMMDDHHmm = (date) => {
  const [datePart, timePart] = date.split(' ');

  const [day, month, year] = datePart.split('-');

  return `${month}-${day}-${year} ${timePart}`;
};

export const formatTimeAgo = (date) => {
  if (date) {
    let lang = store.state.language.currentLanguage;

    if (lang == 'sr') {
      moment.locale('sr-cyrl'); // Set the locale based on the user's choice
    } else {
      moment.locale(lang); // Set the locale based on the user's choice
    }

    const now = moment(new Date()); // current time
    const receivedDate = moment(date);
    return receivedDate.from(now);
  } else {
    return '';
  }
};
