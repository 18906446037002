import {
  getAllPricingZones,
  savePricingZone,
  updatePricingZone,
  deletePricingZone,
  getAllPricingZoneRelations,
  savePricingZoneRelation,
  clonePricingZoneRelation,
  deletePricingZoneRelation,
  getAllPricingHourRelations,
  savePricingHourRelation,
  clonePricingHourRelation,
  deletePricingHourRelation,
  getZonePricingData,
  calculatePriceViaZonePricing,
  calculatePriceViaZonePricingCustomer,
  getDataByPostalCode
} from '@/api/zonePricing';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllPricingZones(state, item) {
      return getAllPricingZones(item);
    },
    async savePricingZone(state, item) {
      return savePricingZone(item);
    },
    async updatePricingZone(state, item) {
      return updatePricingZone(item);
    },
    async deletePricingZone(state, item) {
      return deletePricingZone(item);
    },
    async getAllPricingZoneRelations(state, item) {
      return getAllPricingZoneRelations(item);
    },
    async savePricingZoneRelation(state, item) {
      return savePricingZoneRelation(item);
    },
    async clonePricingZoneRelation(state, item) {
      return clonePricingZoneRelation(item);
    },
    async deletePricingZoneRelation(state, item) {
      return deletePricingZoneRelation(item);
    },
    async getAllPricingHourRelations(state, item) {
      return getAllPricingHourRelations(item);
    },
    async savePricingHourRelation(state, item) {
      return savePricingHourRelation(item);
    },
    async clonePricingHourRelation(state, item) {
      return clonePricingHourRelation(item);
    },
    async deletePricingHourRelation(state, item) {
      return deletePricingHourRelation(item);
    },
    async getZonePricingData(state, item) {
      return getZonePricingData(item);
    },
    async calculatePriceViaZonePricing(state, item) {
      return calculatePriceViaZonePricing(item);
    },
    async calculatePriceViaZonePricingCustomer(state, item) {
      return calculatePriceViaZonePricingCustomer(item);
    },
    async getDataByPostalCode(state, item) {
      return getDataByPostalCode(item);
    },
  },
  getters: {},
};
