import { getHttpClient } from '@/api/client';

export const login = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/login', 'POST', data).then((response) => response.data);
};

export const logout = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/logout', 'POST').then((response) => response.data);
};

export const customerLogout = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/customer/logout', 'POST').then((response) => response.data);
};

export const socialLogin = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/get-auth-user-data', 'POST', null, data).then(
    (response) => response.data
  );
};

export const getUserData = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/auth-user', data).then(
    (response) => response.data
  );
};

export const saveUser = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/admin/save-user', 'POST', data).then(
    (response) => response.data
  );
};

export const registerUser = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/register', 'POST', data).then((response) => response.data);
};

export const updateUser = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/update-user', 'POST', data).then(
    (response) => response.data
  );
};

export const activeSubscription = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/active-subscription', 'GET').then(
    (response) => response.data
  );
};

export const verifyUserAndSetPassword = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/set-pass-change', 'POST', data).then(
    (response) => response.data
  );
};
