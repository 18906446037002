import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';

Vue.use(VueRouter);
const routePrefix = process.env.VUE_APP_ROUTE_PREFIX;

const routes = [
  {
    path: routePrefix + '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "home" */ '@/views/pages/DashboardPage'),
    meta: {
      auth: true,
      roles: [1, 2, 4],
    },
  },
  {
    path: routePrefix + '/drivings/calendar-view',
    name: 'CalendarView',
    component: () => import(/* webpackChunkName: "home" */ '@/views/pages/drivings/CalendarView'),
    meta: {
      auth: true,
      roles: [2, 4, 11],
    },
  },
  {
    path: routePrefix + '/drivings/tabular-view',
    name: 'TabularView',
    component: () => import(/* webpackChunkName: "homeTabularView" */ '@/views/pages/drivings/TabularView'),
    meta: {
      auth: true,
      roles: [1, 2, 4, 11],
    },
  },
  {
    path: routePrefix + '/availability',
    name: 'AvailabilityPage',
    component: () => import(/* webpackChunkName: "AvailabilityPage" */ '@/views/pages/AvailabilityPage'),
    meta: {
      auth: true,
      roles: [1, 2, 4],
    },
  },
  {
    path: routePrefix + '/currencies',
    name: 'Currencies',
    component: () => import(/* webpackChunkName: "Currency" */ '@/views/pages/currencies/CurrencyPage'),
    meta: {
      auth: true,
      roles: [1],
    },
  },
  {
    path: routePrefix + '/organisation-currencies',
    name: 'OrganisationCurrencies',
    component: () => import(/* webpackChunkName: "Currency" */ '@/views/pages/currencies/OrganisationCurrencyPage'),
    meta: {
      auth: true,
      roles: [1, 2, 4],
    },
  },
  {
    path: routePrefix + '/payment',
    name: 'Payment',
    component: () => import(/* webpackChunkName: "Payment" */ '@/views/pages/PaymentMethodPage'),
    meta: {
      auth: true,
      roles: [1, 2, 4],
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login'),
    meta: {
      auth: false,
    },
    params: true,
    props: true,
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import('@/views/Registration.vue'),
    meta: {
      auth: false,
    },
  },
  {
    path: '/social-login',
    name: 'SocialLogin',
    component: () => import(/* webpackChunkName: "login" */ '@/views/SocialLogin'),
    meta: {
      auth: false,
    },
    params: true,
  },
  // DELETE SET_PASSWORD WHEN WE DELETE REGISTRATION FROM WEBSITE
  {
    path: '/set-password',
    name: 'SetPassword',
    component: () => import('@/views/SetPasswordPage'),
    meta: {
      auth: false,
    },
  },
  {
    path: '/change-password',
    name: 'EnterEmailPage',
    component: () => import(/* webpackChunkName: "setEmail" */ '@/views/EnterEmailPage'),
    meta: {
      auth: false,
    },
    params: true,
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',

    component: () => import(/* webpackChunkName: "forgetPassword" */ '@/views/ForgetPassword'),
    meta: {
      auth: false,
    },
    props: true,
  },
  {
    path: '/vehicles',
    name: 'Vehicles',
    component: () => import(/* webpackChunkName: "vehicles" */ '@/views/pages/vehicles/vehicles-page/VehiclesPage'),
    meta: {
      auth: true,
      roles: [1, 2, 4],
    },
  },
  {
    path: '/vehicle-classes',
    name: 'VehicleClasses',
    component: () => import(/* webpackChunkName: "vehicleClasses" */ '@/views/pages/vehicles/VehicleClassesPage'),
    meta: {
      auth: true,
      roles: [1, 2, 4],
    },
  },
  {
    path: '/organisations',
    name: 'Organisations',
    component: () => import(/* webpackChunkName: "organisations" */ '@/views/pages/OrganisationsPage'),
    meta: {
      auth: true,
      roles: [1, 8],
    },
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import(/* webpackChunkName: "feedback" */ '@/views/pages/FeedbacksPage'),
    meta: {
      auth: true,
      roles: [1],
    },
  },
  {
    path: '/users-organisation',
    name: 'UsersOrganisation',
    component: () => import(/* webpackChunkName: "usersOrganisation" */ '@/views/pages/UsersOrganisationPage'),
    meta: {
      auth: true,
      roles: [2, 4],
    },
  },
  {
    path: '/advanced-settings',
    name: 'AdvancedSettings',
    component: () => import(/* webpackChunkName: "advancedSettings" */ '@/views/pages/AdvancedSettingsPage'),
    meta: {
      auth: true,
      roles: [2, 4],
    },
  },
  {
    path: '/team',
    name: 'Team',
    component: () => import(/* webpackChunkName: "team" */ '@/views/pages/UsersPage'),
    meta: {
      auth: true,
      roles: [1, 2, 4],
    },
    params: true,
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () =>
      import(/* webpackChunkName: "costEffectivenessReport" */ '@/views/pages/statistics/StatisticsPage.vue'),
    meta: {
      auth: true,
      roles: [1, 2, 4],
    },
  },
  {
    path: '/costs',
    name: 'Expenses',
    component: () => import(/* webpackChunkName: "vehicleExpenses" */ '@/views/pages/expenses/ExpensesPage.vue'),
    meta: {
      auth: true,
      roles: [1, 2, 4],
    },
  },
  {
    path: '/vehicle-breakdowns',
    name: 'VehicleMaintenances',
    component: () =>
      import(/* webpackChunkName: "vehicleMaintenance" */ '@/views/pages/vehicles/VehicleMaintenancesPage'),
    meta: {
      auth: true,
      roles: [1, 2, 4],
    },
  },
  {
    path: '/packages',
    name: 'Packages',
    component: () => import(/* webpackChunkName: "packages" */ '@/views/pages/PackagesPage'),
    meta: {
      auth: true,
      roles: [2, 4],
    },
  },
  {
    path: '/rebates',
    name: 'Rebates',
    component: () => import(/* webpackChunkName: "rebates" */ '@/views/pages/RebatesPage'),
    meta: {
      auth: true,
      roles: [1],
    },
  },
  // {
  //   path: '/cards',
  //   name: 'Cards',
  //   component: () => import(/* webpackChunkName: "customerCards" */ '@/views/pages/CustomerCardsPage'),
  //   meta: {
  //     auth: true,
  //     roles: [11],
  //   },
  // },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import(/* webpackChunkName: "notifications" */ '@/views/pages/NotificationsPage'),
    meta: {
      auth: true,
      roles: [1, 2, 4, 11],
    },
  },
  {
    path: '/clients',
    name: 'Clients',
    component: () => import(/* webpackChunkName: "notifications" */ '@/views/pages/ClientsPage'),
    meta: {
      auth: true,
      roles: [1, 2, 4],
    },
  },
  {
    path: '/saas-admins',
    name: 'SaasAdmins',
    component: () => import(/* webpackChunkName: "UsersPage" */ '@/views/pages/UsersPage'),
    meta: {
      auth: true,
      roles: [1],
    },
    props: { saasPage: true },
  },
  {
    path: '/pricing-regions',
    name: 'PricingRegions',
    component: () => import(/* webpackChunkName: "pricingRegions" */ '@/views/pages/PricingRegionsPage'),
    meta: {
      auth: true,
      roles: [1],
    },
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: () => import(/* webpackChunkName: "Transactions" */ '@/views/pages/TransactionsPage'),
    meta: {
      auth: true,
      roles: [1, 2, 4],
    },
  },
  {
    path: '/gps-map',
    name: 'GPSMap',
    component: () => import(/* webpackChunkName: "GPSMap" */ '@/views/pages/GPSMapPage'),
    meta: {
      auth: true,
      roles: [1, 2, 4, 11],
    },
  },
  {
    path: routePrefix + '/invoices',
    name: 'Invoices',
    component: () => import(/* webpackChunkName: "Invoices" */ '@/views/pages/InvoicesPage'),
    meta: {
      auth: true,
      roles: [1, 2, 4, 11],
    },
  },
  {
    path: routePrefix + '/driving-offers',
    name: 'DrivingOffers',
    component: () => import(/* webpackChunkName: "DrivingOffers" */ '@/views/pages/DrivingOffersPage'),
    meta: {
      auth: true,
      roles: [1, 2, 4],
    },
  },
  {
    path: routePrefix + '/resources-overview',
    name: 'ResourceOverview',
    component: () => import(/* webpackChunkName: "ResourceOverview" */ '@/views/pages/ResourceOverviewPage'),
    meta: {
      auth: true,
      roles: [1, 2, 4],
    },
  },
  {
    path: '/public/reservation-form',
    name: 'PublicReservationPage',

    component: () => import(/* webpackChunkName: "PublicReservationPage" */ '@/views/pages/PublicReservationPage'),
    meta: {
      auth: false,
    },
    props: true,
  },
  {
    path: '/public/reservation-embedded-form',
    name: 'PublicReservationEmbeddedPage',

    component: () =>
      import(/* webpackChunkName: "PublicReservationEmbeddedPage" */ '@/views/pages/PublicReservationPage'),
    meta: {
      auth: false,
    },
    props: true,
  },
  {
    path: '/passenger-reviews',
    name: 'PassengerReviews',
    component: () => import(/* webpackChunkName: "PassengerReviews" */ '@/views/pages/PassengerReviewsPage'),
    meta: {
      auth: true,
      roles: [1, 2, 4],
    },
  },
  {
    path: '/affiliate',
    name: 'AffiliatePage',
    component: () => import(/* webpackChunkName: "AffiliatePage" */ '@/views/pages/AffiliatePage'),
    meta: {
      auth: true,
      roles: [1, 2, 4],
    },
  },
  {
    path: '/zones-and-areas',
    name: 'PricingZonesPage',
    component: () =>
      import(/* webpackChunkName: "PricingZonesPage" */ '@/views/pages/zone-pricing/PricingZonesPage.vue'),
    meta: {
      auth: true,
      roles: [1, 2, 4],
    },
  },
  {
    path: '/pricing-per-zones',
    name: 'PricingZoneRelationsPage',
    component: () =>
      import(
        /* webpackChunkName: "PricingZoneRelationsPage" */ '@/views/pages/zone-pricing/PricingZoneRelationsPage.vue'
      ),
    meta: {
      auth: true,
      roles: [1, 2, 4],
    },
  },
  {
    path: '/pricing-per-hour',
    name: 'PricingHourRelationsPage',
    component: () =>
      import(
        /* webpackChunkName: "PricingHourRelationsPage" */ '@/views/pages/zone-pricing/PricingHourRelationsPage.vue'
      ),
    meta: {
      auth: true,
      roles: [1, 2, 4],
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('@/views/NotFoundPage'), // or your custom 404 component
  },
];

export const rolesMap = [
  {
    role: 'masterAdmin',
    id: 1,
    redirect: 'Dashboard',
  },
  {
    role: 'Admin',
    id: 2,
    redirect: 'Dashboard',
  },
  {
    role: 'Operater',
    id: 4,
    redirect: 'Dashboard',
  },
  {
    role: 'Sales',
    id: 8,
    redirect: 'Organisations',
  },
  {
    role: 'Customer',
    id: 11,
    redirect: 'CalendarView',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  rolesMap,
});

router.beforeEach((to, from, next) => {
  let roleId = store.getters['auth/role']
    ? parseInt(store.getters['auth/role'])
    : localStorage.getItem('LimoExpressUser')
    ? JSON.parse(localStorage.getItem('LimoExpressUser')).role_id
    : '';

  let token = store.getters['auth/authToken']
    ? store.getters['auth/authToken']
    : localStorage.getItem('LimoExpressToken')
    ? localStorage.getItem('LimoExpressToken')
    : '';

  //check if route needs auth
  if (to.matched.some((record) => record.meta.auth)) {
    // check if user is already logged in
    if (token) {
      const userRole = rolesMap.find((e) => e.id === roleId);
      let userHasRight = validateUserPermissions(to, userRole);

      // Additional condition to block route access for affiliate users
      if (to.name === 'DrivingOffers' && store.getters['auth/organisationType'] === 'affiliate_receiver') {
        next({ name: 'AccessDenied' }); // Redirect if the user does not meet the condition
      }
      // Check if user does not have right to access route
      else if (!userHasRight) {
        // You can redirect to another route or prevent navigation here
        next({ name: userRole.redirect });
      }
      next();
    } else {
      next({ name: 'Login' }); // if not auth then login
    }
  } else {
    if (token) {
      const userRole = rolesMap.find((e) => e.id === roleId);
      next({ name: userRole.redirect }); // if auth and try login, go home
    } else {
      next();
    }
  }
});

function validateUserPermissions(to, userRole) {
  let final = false;
  routes.forEach((route) => {
    if (route.name === to.name && route.name !== 'Login' && route.name !== 'SetPassword') {
      if (route.meta.roles.includes(parseInt(userRole.id))) final = true;
    } else if (route.to === 'Login' && route.to === 'SetPassword') {
      final = true;
    }
  });
  return final;
}

export default router;
