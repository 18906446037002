import {
  cancelDriving,
  cancelDrivingProposal,
  deleteDriving,
  deleteDrivingAttachment,
  deleteDrivingCheckpoint,
  deleteDrivingPassenger,
  exportExcel,
  userExportPreferences,
  finishDriving,
  markAsStarted,
  noShowDriving,
  generateContract,
  generateDrivingOrder,
  printInvoice,
  getAllDrivings,
  getAllDrivingsData,
  getAllCustomerDrivings,
  getAllDrivingsForClient,
  getAllDrivingsForInvoices,
  getDriving,
  getDrivingProposals,
  saveDriving,
  sendPublicReservation,
  getPublicBookingData,
  getVehicleClassPrices,
  saveDrivingPassenger,
  saveDrivingProposal,
  updateDriving,
  getFlightInfo,
  generateDrivingPaymentLink,
  sendSms,
  updateAffiliateDriving,
} from '@/api/drivings';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllDrivings(state, item) {
      return getAllDrivings(item);
    },
    async getAllDrivingsData(state, item) {
      return getAllDrivingsData(item);
    },
    async getAllCustomerDrivings(state, item) {
      return getAllCustomerDrivings(item);
    },
    async getAllDrivingsForClient(state, item) {
      return getAllDrivingsForClient(item);
    },
    async getAllDrivingsForInvoices(state, item) {
      return getAllDrivingsForInvoices(item);
    },
    async getDriving(state, item) {
      return getDriving(item);
    },
    async saveDriving(state, item) {
      return saveDriving(item);
    },
    async sendPublicReservation(state, item) {
      return sendPublicReservation(item);
    },
    async getPublicBookingData(state, item) {
      return getPublicBookingData(item);
    },
    async getVehicleClassPrices(state, item) {
      return getVehicleClassPrices(item);
    },
    async updateDriving(state, item) {
      return updateDriving(item);
    },
    async deleteDriving(state, item) {
      return deleteDriving(item);
    },
    async getDrivingProposals(state, item) {
      return getDrivingProposals(item);
    },
    async saveDrivingProposal(state, item) {
      return saveDrivingProposal(item);
    },
    async cancelDrivingProposal(state, item) {
      return cancelDrivingProposal(item);
    },
    async cancelDriving(state, item) {
      return cancelDriving(item);
    },
    async finishDriving(state, item) {
      return finishDriving(item);
    },
    async markAsStarted(state, item) {
      return markAsStarted(item);
    },
    async noShowDriving(state, item) {
      return noShowDriving(item);
    },
    async deleteDrivingAttachment(state, item) {
      return deleteDrivingAttachment(item);
    },
    async saveDrivingPassenger(state, item) {
      return saveDrivingPassenger(item);
    },
    async deleteDrivingCheckpoint(state, item) {
      return deleteDrivingCheckpoint(item);
    },
    async deleteDrivingPassenger(state, item) {
      return deleteDrivingPassenger(item);
    },
    async generateContract(state, item) {
      return generateContract(item);
    },
    async generateDrivingOrder(state, item) {
      return generateDrivingOrder(item);
    },
    async printInvoice(state, item) {
      return printInvoice(item);
    },
    async exportExcel(state, item) {
      return exportExcel(item);
    },
    async userExportPreferences(state, item) {
      return userExportPreferences(item);
    },
    async getFlightInfo(state, item) {
      return getFlightInfo(item);
    },
    async generateDrivingPaymentLink(state, item) {
      return generateDrivingPaymentLink(item);
    },
    async sendSms(state, item) {
      return sendSms(item);
    },
    async updateAffiliateDriving(state, item) {
      return updateAffiliateDriving(item);
    },
  },
  getters: {},
};
