export default {
  sidebar: {
    fleet: 'Vozni park',
    vehicles: 'Vozila',
    vehiclesClasses: 'Klase vozila',
    administration: 'Administracija',
    currencies: 'Valute',
    currenciesAdministration: 'Administracija valuta',
    paymentTypes: 'Tipovi plaćanja',
    paymentTypesAdministration: 'Administracija tipova plaćanja',
    users: 'Korisnici',
    usersAdministration: 'Administracija korisnika',
    clients: 'Klijenti',
    clientsAdministration: 'Administracija klijenata',
    organizations: 'Organizacije',
    organizationsAdministration: 'Administracija organizacija',
    saasAdmin: 'Saas admin',
    administrators: 'Administratori',
    saasAdminAdministration: 'Administracija saas admina',
    statistics: 'Statistika',
    generalOverview: 'Generalni izvještaj',
    payment: 'Naplata',
    rebates: 'Rabati',
    pricingRegions: 'Cjenovni regioni',
    pricingRegionsAdministration: 'Upravljanje cjenovnim regionima',
    help: 'Pomoć',
    drivings: 'Nalozi',
    calendar: 'Kalendar',
    tabularView: 'Tabelarni prikaz',
    expenses: 'Troškovi',
    vehicleExpensesAdministration: 'Administracija troškova vozila',
    vehicleMaintenance: 'Kvarovi vozila',
    vehicleMaintenanceAdministration: 'Administracija kvarova vozila',
    organization: 'Organizacija',
    organizationAdminstration: 'Administracija organizacije',
    packages: 'Paketi',
    packagesChange: 'Promjena paketa',
    logout: 'Odjava',
    logoutText: 'Da li ste sigurni da želite da se odjavite?',
    unaccessibleRoutes: 'Ne možete pristupiti ovoj stranici. Nadogradite paket za potpuni pristup.',
    transactions: 'Transakcije',
    gpsMap: 'GPS mapa',
    team: 'Tim',
    teamAdministration: 'Administracija tima',
    invoices: 'Fakture',
    invoicesAdministration: 'Administracija faktura',
    drivingOffers: 'Ponude',
    drivingOffersAdministration: 'Administracija ponuda',
    dashboard: 'Početna strana',
    dashboardAdministration: 'Administracija početne strane',
    resourceOverview: 'Pregled resursa',
    resourceOverviewAdministration: 'Administracija pregleda resursa',
    rides: 'Vožnje',
    passengerReviews: 'Recenzije putnika',
    passengerReviewsAdministration: 'Administracija recenzija putnika',
    pricingAndRates: 'Cijene i stope',
    pricingZones: 'Zone i područja',
    pricingZonesAdministration: 'Administracija zona i područja',
    pricingPerZones: 'Cijene po zonama',
    pricingPerZonesAdministration: 'Administracija cijena po zonama',
    pricingPerHour: 'Cijene po satu',
    pricingPerHourAdministration: 'Administracija cijena po satu',
    availability: 'Dostupnost',
    availabilityAdministration: 'Dostupnost vozača i vozila',
  },
  header: {
    organization: 'Organizacija',
    newDriving: 'Nalog',
    dailyRent: 'Dnevni najam',
    transfer: 'Transfer',
    notifications: 'Obavještenja',
    changeLanguage: 'Promijeni jezik',
    help: 'Pomoć',
  },
  footer: {
    poweredBy: 'Powered by',
    subscriptionTo: 'Vaša pretplata na',
    trialEndsAt: 'Probni period za ',
    packageExpiresIn: 'paket ističe za',
    clickToChangePackage: 'Klik za promjenu paketa',
    clickToActivatePackage: 'Klik za aktivaciju paketa',
    clickToPay: 'Kliknite za plaćanje',
    packageHasExpired: 'paket je istekla',
    affiliateUser: 'Ako želite da otključate sve funkcionalnosti platforme, neophodno je da se pretplatite ',
    here: 'OVDJE',
  },
  buttons: {
    ok: 'Ok',
    yes: 'Da',
    no: 'Ne',
    save: 'Sačuvaj',
    send: 'Pošalji',
    sendMessage: 'Pošalji poruku',
    edit: 'Izmijeni',
    add: 'Dodaj',
    confirm: 'Potvrdi',
    cancel: 'Otkaži',
    cancelDriving: 'Otkaži nalog',
    markAsDone: 'Označi kao odrađeno',
    markAsNoShow: 'Označi kao nepojavljivanje',
    deleteDriving: 'Izbriši nalog',
    newOrganisation: 'Nova organizacija',
    changePackage: 'Promjena paketa',
    downloadStats: 'Preuzmi statistiku',
    downloadPDF: 'Preuzmi PDF',
    today: 'Danas',
    choose: 'Izaberi',
    login: 'Prijavi se',
    generateFile: 'Generiši ugovor',
    generateDrivingOrder: 'Štampaj nalog',
    downloadFile: 'Preuzmi fajl',
    activate: 'Aktiviraj',
    deactivate: 'Deaktiviraj',
    showImage: 'Prikaži sliku',
    showDetails: 'Prikaži detalje',
    options: 'Opcije',
    actions: 'Akcije',
    delete: 'Izbriši',
    open: 'Otvori',
    show: 'Prikaži',
    prev: 'Prethodno',
    next: 'Sljedeće',
    skip: 'Preskoči',
    finish: 'Završi',
    settings: 'Podešavanja',
    cancelSubscription: 'Ukini pretplatu',
    backToEditOrganization: 'Nazad na izmjenu organizacije',
    payNow: 'Plati sada',
    back: 'Idi nazad',
    integrationRoutes: 'Integracione rute',
    transactions: 'Transakcije',
    articlesOfLaw: 'Zakoni',
    export: 'Preuzmi',
    duplicate: 'Napravi kopiju',
    makeReturnTrip: 'Napravite povratno putovanje',
    selectOrganisation: 'Izaberite organizaciju',
    advancedSettings: 'Napredna podešavanja',
    accept: 'Prihvati',
    reject: 'Odbij',
    sendViaGnet: 'Pošalji preko GNET-a',
    printInvoice: 'Štampaj fakturu',
    enable: 'Omogući',
    disable: 'Onemogući',
    generatePaymentLink: 'Generiši link za plaćanje',
    addItem: '+ Dodaj stavku',
    visitWebsite: 'Posjetite naš web sajt',
    buyMoreSms: 'Kupite još SMS-ova',
    forward: 'Proslijedi',
    sendViaAffiliate: 'Pošalji putem Affiliate',
    markInvoiceAsPaid: 'Označi fakturu kao plaćenu',
    markDrivingOfferAsPaid: 'Označi ponudu za vožnju kao plaćenu',
    selectAll: 'Izaberi sve',
    viaAffiliateId: 'Dodajte putem affiliate ID-a',
    registerPartner: 'Registrujte partnera',
    createNew: 'Kreiraj novo',
    clone: 'Kloniraj',
    cloneExisting: 'Kloniraj postojeće',
    copyLink: 'Kopiraj link',
    markAsStarted: 'Označi kao započeto',
  },
  tooltips: {
    save: 'Sačuvaj',
    edit: 'Izmijeni',
    delete: 'Izbriši',
    showImage: 'Prikaži sliku',
    open: 'Otvori',
    show: 'Prikaži',
    showDetails: 'Prikaži detalje',
    downloadFile: 'Preuzmi fajl',
    generateFile: 'Generiši ugovor',
    generateDrivingOrder: 'Štampaj nalog',
    activate: 'Aktiviraj',
    deactivate: 'Deaktiviraj',
    cancel: 'Otkaži',
    cancelDriving: 'Otkaži nalog',
    cannotMarkAsCanceled: 'Nalog ne može biti označen kao otkazan jer je već prihvaćen',
    markAsDone: 'Označi kao odrađeno',
    cannotMarkAsDone: 'Nalog ne može biti označen kao završen, jer se još nije desio',
    markAsNoShow: 'Označi kao nepojavljivanje',
    cannotMarkAsNoShow: 'Nalog ne može biti označen kao nepojavljivanje, jer još nije realizovan',
    deleteDriving: 'Izbriši nalog',
    cannotDelete: 'Žao nam je, ali iz sigurnosnih razloga korisnici se ne mogu brisati dok su trenutno prijavljeni.',
    duplicate: 'Napravi kopiju',
    makeReturnTrip: 'Napravite povratno putovanje',
    refresh: 'Osvježi',
    selectOrganisation: 'Izaberite organizaciju',
    sendViaGnet: 'Pošalji preko GNET-a',
    receivedViaGnet: 'Primljeno putem GNET-a',
    sentViaGnet: 'Poslato putem GNET-a',
    printInvoice: 'Štampaj fakturu',
    optionsDisabled: 'Morate odbiti ili prihvatiti nalog da biste pristupili meniju opcija',
    cannotMakeReturnTrip: 'Ne možete napraviti povratno putovanje za dnevni najam',
    cannotSendGnetOrderViaGnet: 'Ne možete poslati Gnet nalog putem Gnet-a',
    cannotSendViaGnetWithoutGnetId: 'Ne možete poslati nalog putem Gnet-a bez Gnet ID-a',
    cannotPrintInvoiceWithoutClient: 'Ne možete štampati fakturu bez odabira klijenta',
    cannotMarkAsDoneStatusRejected: 'Nalog ne može biti označen kao završen jer je već označen kao odbijen',
    cannotMarkAsDoneStatusCanceled: 'Nalog ne može biti označen kao završen jer je već označen kao otkazan',
    cannotMarkAsDoneStatusDone: 'Nalog ne može biti označen kao završen jer je već označen kao završen',
    cannotMarkAsDoneStatusNoShow: 'Nalog ne može biti označen kao završen jer je već označen kao nedolazak',
    cannotMarkAsNoShowStatusRejected: 'Nalog ne može biti označen kao nedolazak jer je već označen kao odbijen',
    cannotMarkAsNoShowStatusCanceled: 'Nalog ne može biti označen kao nedolazak jer je već označen kao otkazan',
    cannotMarkAsNoShowStatusDone: 'Nalog ne može biti označen kao nedolazak jer je već označen kao završen',
    cannotMarkAsNoShowStatusNoShow: 'Nalog ne može biti označen kao nedolazak jer je već označen kao nedolazak',
    cannotMarkAsCanceledGnet: 'Nalog ne može biti označen kao otkazan jer je primljen putem Gnet-a',
    cannotMarkAsCanceledStatusRejected: 'Nalog ne može biti označen kao otkazan jer je već označen kao odbijen',
    cannotMarkAsCanceledStatusExpired: 'Nalog ne može biti označen kao otkazan jer je već označen kao istekao',
    cannotMarkAsCanceledStatusCanceled: 'Nalog ne može biti označen kao otkazan jer je već označen kao otkazan',
    cannotMarkAsCanceledStatusDraft: 'Nalog ne može biti označen kao otkazan jer je već označen kao nacrt',
    cannotMarkAsCanceledStatusCompleted: 'Nalog ne može biti označen kao otkazan jer je već označen kao završen',
    cannotMarkAsCanceledStatusNoShow: 'Nalog ne može biti označen kao otkazan jer je već označen kao nedolazak',
    cannotDeleteDrivingSentViaGnet: 'Nalog ne može biti obrisan jer je poslat putem Gnet-a',
    cannotDeleteDrivingReceivedViaGnet: 'Nalog ne može biti obrisan jer je primljen putem Gnet-a i prihvaćen',
    generatePaymentLink: 'Generiši link za plaćanje',
    cannotGeneratePaymentLInk: 'Link za plaćanje ne može biti generisan jer niste aktivirali Stripe',
    disabledExport: 'Možete exportovati podatke jednom u 24 sata',
    sendSms: 'Pošalji SMS',
    accept: 'Prihvati',
    reject: 'Odbij',
    sendViaAffiliate: 'Pošalji putem Affiliate',
    receivedViaAffiliate: 'Primljeno putem Affiliate',
    sentViaAffiliate: 'Poslato putem Affiliate',
    cannotSendViaAffiliate: 'Ne možete poslati affiliate nalog za vožnju putem affiliate-a.',
    cannotForwardDrivingOrder: 'Ne možete proslijediti nalog za vožnju koji je u prošlosti.',
    cannotMarkAsCanceledAffiliate:
      'Nalog za vožnju ne može biti označen kao otkazan jer je primljen putem affiliate-a.',
    cannotMarkAsCanceled12Hours: 'Nalog ne može biti označen kao otkazan jer je prošlo 12 sati od vremena preuzimanja.',
    cannotDeleteActiveDriving: 'Aktivni nalog za vožnju ne može biti obrisan.',
    noImageToShow: 'Nema slike za prikaz',
    disabledForAffiliateUser:
      "Vaš nalog je tipa 'Affiliate'. Ova akcija je dostupna za pretplaćene [Regularne] korisnike. Idite na link u futeru kako bi se pretplatili.",
    markInvoiceAsPaid: 'Označi fakturu kao plaćenu',
    markDrivingOfferAsPaid: 'Označi ponudu za vožnju kao plaćenu',
    invoiceIsPaid: 'Faktura je već označena kao plaćena',
    drivingOfferIsPaid: 'Ponuda za vožnju je već označena kao plaćena',
    selectAreaToDelete: 'Odaberite područje koje želite obrisati',
    deleteSelectedArea: 'Obrišite odabrano područje',
    markAsStarted: 'Označi kao započeto',
    cannotMarkAsStarted: 'Nalog ne može biti označen kao započet jer se još nije dogodio',
    cannotMarkAsStartedStatusStarted: 'Nalog ne može biti označen kao započet jer je već označen kao započet',
    cannotMarkAsStartedStatusRejected: 'Nalog ne može biti označen kao započet jer je već označen kao odbijen',
    cannotMarkAsStartedStatusCanceled: 'Nalog ne može biti označen kao započet jer je već označen kao otkazan',
    cannotMarkAsStartedStatusDone: 'Nalog ne može biti označen kao započet jer je već označen kao završen',
    cannotMarkAsStartedStatusNoShow: 'Nalog ne može biti označen kao započet jer je već označen kao nedolazak',
  },
  snackbar: {
    close: 'Zatvori',
    savedSuccessfully: 'Uspješno sačuvano',
    excelDownloadedSuccessfully: 'Excel file je skinut uspješno',
    deletedSuccessfully: 'Uspješno obrisano',
    sentSuccessfully: 'Uspješno poslato',
    statusChanged: 'Status uspješno izmijenjen',
    activeStatusChanged: 'Status aktivan uspješno izmijenjen',
    defaultStatusChanged: 'Status default uspješno izmijenjen',
    primaryStatusChanged: 'Status primaran uspješno izmijenjen',
    anErrorOccured: 'Došlo je do greške, prijavite podršci',
    loadingCommentsFailed: 'Učitavanje komentara nije uspjelo',
    drivingCanceled: 'Vožnja je uspješno otkazana',
    drivingFinished: 'Vožnja je uspješno završena',
    drivingStarted: 'Nalog za vožnju je uspješno označen kao započet',
    drivingNoShow: 'Nalog za vožnju je uspešno označen kao nepojavljivanje.',
    drivingDeleted: 'Vožnja je uspješno izbrisana',
    drivingAccepted: 'Vožnja je uspješno prihvaćena',
    drivingRejected: 'Vožnja je uspješno odbijena',
    planChanged: 'Plan uspješno promijenjen',
    planChangeFailed: 'Nije moguće preći na niži plan',
    saveChangesFirst: 'Prvo sačuvajte prethodno unesene izmjene',
    makeRoutesPublic: 'Rute su sada javno dostupne',
    makeRoutesNotPublic: 'Rute više nisu javno dostupne',
    makePublicLinkActive: 'Javni link je sada dostupan',
    makePublicLinkInactive: 'Javni link više nije dostupan',
    integrationKeyCopied: 'Integracioni ključ kopiran',
    copyToken: 'Ručno kopirajte token',
    publicLinkCopied: 'Javni link je kopiran',
    copyPublicLink: 'Ručno kopirajte javni link',
    organisationChanged: 'Kompanija uspješno promijenjena',
    changeFailed: 'Promjena nije uspjela, probajte opet',
    loginFailed: 'Korisničko ime ili lozinka nisu ispravni',
    organisationSuspended: 'Vaša kompanija je suspendovana',
    userEmailDoesNotExists: 'Korisnik sa ovim e-mailom ne postoji',
    canceledSubscrSuccessfully: 'Uspješno ste ukinuli pretplatu',
    toManyRequests: 'Previše zahtjeva. Pokušajte ponovo za minut.',
    visibleStatusChanged: 'Status vidljiv uspješno izmijenjen',
    noInternetConnection: 'Nema interneta. Molimo provjerite vašu internet konekciju.',
    backOnline: 'Ponovo ste povezani na internet.',
    driverNotifiedToTurnGpsOn: 'Vozač je obaviješten da uključi GPS',
    savedSuccessfullyWithGnetError: 'GNET sinhronizacija nije uspela, nalog za vožnju je uspešno sačuvan.',
    logoutFromAnotherDevice: 'Odjavljeni ste sa drugog uređaja.',
    stripeEnabled: 'Stripe uspješno aktiviran',
    stripeDisabled: 'Stripe uspješno deaktiviran',
    finishStripeConnect: 'Molimo završite podešavanje Stripe-a',
    requestSentSuccessfully: 'Zahtjev uspješno poslat',
    pricingZoneNoMatch: 'Ne možemo pronaći odgovarajuće cjenovne zone za proračun cijene.',
    areaAlreadyExists: 'Ova oblast već postoji',
    noDataForThisPostalCode: 'Nema podataka za ovaj poštanski broj',
    affiliateRequestSentSuccessfully: 'Organizacija već postoji, zahtjev za saradnju je poslat',
    organizationRegisteredSuccessfully: 'Organizacija je uspješno registrovana',
    organizationIsAlreadyAPartner: 'Organizacija je već partner',
    recordAlreadyExists: 'Ovaj zapis već postoji.',
  },
  filters: {
    filter: 'Filter',
    currentYear: 'Tekuća godina',
    lastYear: 'Prethodna godina',
    lastQuarter: 'Posljednji kvartal',
    lastThreeYears: 'Prethodne tri godine',
    filterByDriver: 'Filtriraj po vozaču',
    filterByVehicle: 'Filtriraj po vozilu',
    filterByClient: 'Filtriraj po klijentu',
    day: 'Dan',
    week: 'Nedelja',
    month: 'Mjesec',
    vehicle: 'Vozilo',
    driving: 'Vožnja',
    user: 'Korisnik',
    fromDate: 'Period od',
    toDate: 'Period do',
    threeMonths: 'Tri mjeseca',
    halfYear: 'Pola godine',
    year: 'Godina',
  },
  months: {
    jan: 'Januar',
    feb: 'Februar',
    mar: 'Mart',
    apr: 'April',
    may: 'Maj',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Avgust',
    sep: 'Septembar',
    oct: 'Oktobar',
    nov: 'Novembar',
    dec: 'Decembar',
    January: 'Januar',
    February: 'Februar',
    March: 'Mart',
    April: 'April',
    May: 'Maj',
    June: 'Jun',
    July: 'Jul',
    August: 'Avgust',
    September: 'Septembar',
    October: 'Oktobar',
    November: 'Novembar',
    December: 'Decembar',
  },
  usersPageHeaders: {
    firstName: 'Ime',
    lastName: 'Prezime',
    username: 'Korisničko ime',
    email: 'Email',
    phoneNumber: 'Telefon',
    role: 'Uloga',
    active: 'Aktivno',
    actions: 'Akcije',
  },
  usersRoles: {
    masterAdmin: 'Master administrator',
    manager: 'Menadžer',
    driver: 'Vozač',
    operator: 'Operater',
    externalAssociate: 'Eksterni saradnik',
    sales: 'Prodaja',
    salesLead: 'Vođa prodaje',
    gnet: 'GNET',
    customer: 'Korisnik portala',
    publicUser: 'Javni korisnik',
  },
  masterUsersPageHeaders: {
    firstName: 'Ime',
    lastName: 'Prezime',
    username: 'Korisničko ime',
    email: 'Email',
    phoneNumber: 'Telefon',
    role: 'Uloga',
    organisation: 'Organizacija',
    active: 'Aktivno',
    actions: 'Akcije',
  },
  usersPage: {
    newItem: 'Novi unos korisnika ',
    editItem: 'Izmjena korisnika',
    id: 'Id',
    firstName: 'Ime',
    lastName: 'Prezime',
    username: 'Korisničko ime',
    password: 'Lozinka',
    phoneNumber: 'Telefon',
    role: 'Uloga',
    client: 'Klijent',
    email: 'Email',
    organisation: 'Organizacija',
    percentage: 'Procenti',
    externalAssociate: 'Eksterni saradnik',
    image: 'Slika',
    deleteUser: 'Brisanje korisnika',
    deleteMessage: 'Da li ste sigurni da želite da izbrišete korisnika ',
    emailChanged: 'Promjena emaila',
    verificationEmailSent:
      'Zahtjev za promjenu email adrese je poslat. Da bi se završio proces i osigurala sigurnost korisničkog naloga, nova email adresa mora biti verifikovana  klikom na link za verifikaciju koji je poslat na novi email. Email adresa neće biti promijenjena dok ne bude uspješno verifikovana.',
    passengersInfoAvailableToDriver: 'Informacije o putnicima dostupne vozaču',
    colorPicker:
      'Možete odabrati jedinstvenu boju za svakog korisnika. Koristiće se na stranici dostupnosti radi lakše identifikacije.',
  },
  clientsPageHeaders: {
    name: 'Naziv',
    address: 'Adresa',
    phoneNumber: 'Telefon',
    email: 'Email',
    companyId: 'PIB/Lični ID',
    clientType: 'Tip klijenta',
    percentage: 'Procenat',
    active: 'Aktivno',
    actions: 'Akcije',
  },
  clientsPage: {
    newItem: 'Novi unos klijenta ',
    editItem: 'Izmjena klijenta',
    id: 'Id',
    name: 'Naziv',
    phoneNumber: 'Telefon',
    address: 'Adresa',
    email: 'Email',
    companyId: 'PIB/Lični ID',
    clientType: 'Tip klijenta',
    individual: 'Fizičko lice',
    business: 'Pravno lice',
    percentage: 'Procenat',
    deleteClient: 'Brisanje klijenta',
    deleteMessage: 'Da li ste sigurni da želite da izbrišete klijenta ',
    tabs: {
      general: 'Opšte',
      transfers: 'Transferi',
      users: 'Korisnici',
      invoices: 'Fakture',
      cards: 'Kartice',
    },
  },
  drivingsHeaders: {
    drivingNumber: 'Broj naloga',
    drivingType: 'Tip rezervacije',
    drivingStatus: 'Status',
    locationFrom: 'Polazište',
    locationTo: 'Odredište',
    vehicleClass: 'Klasa vozila',
    vehicle: 'Vozilo',
    driversName: 'Vozač',
    startTime: 'Vrijeme polaska',
    actions: 'Akcije',
    exportExcel: 'Preuzmi excel',
    price: 'Cijena',
    totalPrice: 'Ukupna cijena',
    confirmed: 'Potvrđeno',
    paid: 'Plaćeno',
    numberOfDrivings: 'Broj vožnji',
  },
  vehiclesHeaders: {
    plateNumber: 'Registarska oznaka',
    brand: 'Marka',
    vehicleClass: 'Klasa vozila',
    passengersNumber: 'Broj putnika',
    fuelConsumption: 'Potrošnja',
    yearOfManufacture: 'Godina proizvodnje',
    pricePerKm: 'Cijena (km)',
    pricePerMi: 'Cijena po milji',
    pricePerHour: 'Cijena po satu',
    pricePerWaitingHour: 'Cijena čekanja',
    active: 'Aktivno',
    actions: 'Akcije',
  },
  vehiclesPage: {
    id: 'Id',
    newItem: 'Novi unos vozila',
    editItem: 'Izmjena vozila',
    vehiclePlates: 'Registarske oznake',
    vehicleBrand: 'Marka vozila',
    vehicleClass: 'Klasa vozila',
    passengersNumber: 'Broj putnika',
    fuelConsumption: 'Potrošnja (100km)',
    fuelConsumptionMi: 'Potrošnja (100 milja)',
    manufactureYear: 'Godina proizvodnje',
    price: 'Cijena po km',
    priceMi: 'Cijena po milji',
    image: 'Slika',
    hourPrice: 'Cijena po satu',
    hourWaitingPrice: 'Cijena po satu čekanja',
    deleteMessage: 'Da li ste sigurni da želite da izbrišete ovo vozilo?',
    tabs: {
      general: 'Opšte',
      transfers: 'Transferi',
      costs: 'Troškovi',
      damages: 'Štete',
    },
    licence: 'Broj licence',
    color: 'Boja',
    colorPicker:
      'Možete odabrati jedinstvenu boju za svako vozilo. Koristiće se na stranici dostupnosti radi lakše identifikacije.',
  },
  vehicleClassesHeaders: {
    vehicleClass: 'Klasa vozila',
    numberOfPassengers: 'Broj putnika',
    numberOfSuitcases: 'Broj kofera',
    pricePerKm: 'Cijena (km)',
    pricePerMi: 'Cijena po milji',
    pricePerHour: 'Cijena po satu',
    pricePerWaitingHour: 'Cijena čekanja',
    availableForPublicBookingForm: 'Dostupno za javnu booking rezervaciju',
    active: 'Aktivno',
    actions: 'Akcije',
  },
  vehicleClassesPage: {
    newItem: 'Novi unos klase vozila',
    editItem: 'Izmjena klase vozila',
    id: 'Id',
    vehicleClass: 'Klasa vozila',
    numberOfPassengers: 'Broj putnika',
    numberOfSuitcases: 'Broj kofera',
    availableForPublicBookingForm: 'Dostupno za javnu booking rezervaciju',
    price: 'Cijena po km',
    priceMi: 'Cijena po milji',
    image: 'Slika',
    hourPrice: 'Cijena po satu',
    hourWaitingPrice: 'Cijena po satu čekanja',
    deleteMessage: 'Da li ste sigurni da želite da izbrišete ovu klasu vozila?',
  },
  expensesHeaders: {
    name: 'Naziv',
    vehicle: 'Vozilo',
    driving: 'Nalog',
    type: 'Tip',
    description: 'Opis',
    price: 'Cijena',
    expenseDate: 'Datum',
    actions: 'Akcije',
    exportExcel: 'Preuzmi excel',
  },
  vehicleExpensesTypes: {
    minorService: 'Mali servis',
    majorService: 'Veliki servis',
    fuel: 'Gorivo',
    tires: 'Gume',
    parking: 'Parking',
    other: 'Ostalo',
  },
  expensesPage: {
    newItem: 'Novi unos troškova ',
    editItem: 'Izmjena troškova',
    id: 'Id',
    name: 'Naziv',
    vehicle: 'Vozilo',
    costType: 'Tip troška',
    driving: 'Nalog',
    price: 'Cijena',
    currency: 'Valuta',
    costTime: 'Vrijeme troška',
    description: 'Opis',
    deleteMessage: 'Da li ste sigurni da želite da izbrišete ovaj trošak?',
    costTypes: {
      'Mali servis': 'Mali servis',
      'Veliki servis': 'Veliki servis',
      Gorivo: 'Gorivo',
      Gume: 'Gume',
      Parking: 'Parking',
      Ostalo: 'Ostalo',
    },
  },
  vehicleMaintenanceHeaders: {
    name: 'Vozilo',
    details: 'Detalji',
    dateFrom: 'Datum od',
    dateTo: 'Datum do',
    actions: 'Akcije',
  },
  vehicleMaintenancePage: {
    newItem: 'Novi unos kvara vozila ',
    editItem: 'Izmjena kvara vozila',
    id: 'Id',
    vehicle: 'Vozilo',
    description: 'Opis',
    dateFrom: 'Vrijeme od',
    dateTo: 'Vrijeme do',
    deleteMessage: 'Da li ste sigurni da želite da izbrišete ovaj kvar?',
  },
  rebatesHeaders: {
    min: 'Minimalni broj naloga',
    max: 'Maksimalni broj naloga',
    percentage: 'Rabat (%)',
    actions: 'Akcije',
  },
  rebatesPage: {
    newItem: 'Novi unos rabata',
    editItem: 'Izmjena rabata',
    rebateMin: 'Minimalni broj naloga',
    rebateMax: 'Maksimalni broj naloga',
    rebate: 'Rabat (%)',
    deleteRebate: 'Izbriši rabat',
    deleteMessage: 'Da li ste sigurni da želite da izbrišete ovaj rabat?',
  },
  customerCardsHeaders: {
    cardholderName: 'Ime vlasnika kartice',
    cardNumber: 'Broj kartice',
    expiryDate: 'Datum isteka',
    cvv: 'CVV',
    actions: 'Akcije',
  },
  customerCardsPage: {
    newItem: 'Novi unos kartice',
    editItem: 'Izmjena kartice',
    cardholderName: 'Ime vlasnika kartice',
    cardNumber: 'Broj kartice',
    expiryDate: 'Datum isteka',
    cvv: 'CVV',
    deleteCard: 'Obriši karticu',
    deleteMessage: 'Da li ste sigurni da želite obrisati ovu karticu?',
  },
  pricingRegionsHeaders: {
    name: 'Ime',
    actions: 'Radnje',
  },
  pricingRegionsPage: {
    newItem: 'Novi cjenovni region',
    editItem: 'Promjena cjenovnog regiona',
    name: 'Ime',
    chooseCurrency: 'Odaberite valutu',
    soloPrice: 'Solo cijena - Godina',
    soloPaddlePriceId: 'Solo ID cijene paddle - Godina',
    team5Price: 'Cijena za tim 5 - Godina',
    team5PaddlePriceId: 'ID paddle cijene za tim 5 - Godina',
    team10Price: 'Cijena za tim 10 - Godina',
    team10PaddlePriceId: 'ID paddle cijene za tim 10 - Godina',
    team25Price: 'Cijena za tim 25 - Godina',
    team25PaddlePriceId: 'ID paddle cijene za tim 25 - Godina',
    team50Price: 'Cijena za tim 50 - Godina',
    team50PaddlePriceId: 'ID paddle cijene za tim 50 - Godina',
    soloPriceMonthly: 'Solo cijena - Mjesec',
    soloPaddlePriceIdMonthly: 'Solo ID cijene paddle - Mjesec',
    team5PriceMonthly: 'Cijena za tim 5 - Mjesec',
    team5PaddlePriceIdMonthly: 'ID paddle cijene za tim 5 - Mjesec',
    team10PriceMonthly: 'Cijena za tim 10 - Mjesec',
    team10PaddlePriceIdMonthly: 'ID paddle cijene za tim 10 - Mjesec',
    team25PriceMonthly: 'Cijena za tim 25 - Mjesec',
    team25PaddlePriceIdMonthly: 'ID paddle cijene za tim 25 - Mjesec',
    team50PriceMonthly: 'Cijena za tim 50 - Mjesec',
    team50PaddlePriceIdMonthly: 'ID paddle cijene za tim 50 - Mjesec',
    chooseCountries: 'Odaberite zemlje',
    description: 'Opis',
    deletePricingRegion: 'Izbriši cjenovni region',
    deleteMessage: 'Jeste li sigurni da želite izbrisati ovaj cjenovni region?',
  },
  notificationsHeaders: {
    time: 'Vrijeme',
    notification: 'Obavještenje',
  },
  organisationsHeaders: {
    name: 'Organizacija',
    phoneNumber: 'Telefon',
    email: 'Email',
    address: 'Adresa',
    companyId: 'PIB',
    vat: 'PDV',
    active: 'Aktivna',
    createdAt: 'Registrovana',
    actions: 'Akcije',
    billingDate: 'Datum naplate',
  },
  organisationsPage: {
    newItem: 'Novi unos korisnika ',
    editItem: 'Izmjena korisnika',
    id: 'Id',
    name: 'Organizacija',
    address: 'Adresa',
    phoneNumber: 'Telefon',
    email: 'Email',
    vat: 'PDV',
    companyId: 'PIB',
    warrantNumber: 'Broj rješenja',
    image: 'Slika',
    signature: 'Potpis',
    seal: 'Pečat',
    deactivateMessage: 'Da li ste sigurni da želite da ',
    deactivate: 'DEAKTIVIRATE',
    activate: 'AKTIVIRATE',
    organisation: 'organizaciju',
    deleteOrganisation: 'Brisanje organizacije',
    deleteMessage: 'Da li ste sigurni da želite da obrišete ovu organizaciju?',
    cancelSubscrMessage:
      'Da li ste sigurni da želite otkazati pretplatu? Imajte na umu da potvrdom ove akcije: 1. Račun vaše organizacije i svi povezani podaci bit će trajno obrisani. Ova akcija se ne može poništiti. 2. Bićete automatski odjavljeni iz sistema odmah nakon otkazivanja. 3. Više nećete imati pristup za prijavljivanje niti za preuzimanje bilo kakvih podataka vezanih za vašu organizaciju u budućnosti. Preporučujemo da pregledate sve potrebne informacije ili izvezete ključne podatke prije nastavka s ovom nepovratnom akcijom. Ako imate pitanja ili vam je potrebna pomoć, ne oklijevajte kontaktirati naš tim za podršku. Da li želite nastaviti s otkazivanjem?',
    allDrivings: 'Ukupan broj naloga',
    allDrivingsAccepted: 'Ukupno prihvaćenih naloga',
    allDrivingsCanceled: 'Ukupno odbijenih naloga',
    allVehicles: 'Ukupan broj vozila',
    allUsers: 'Ukupan broj korisnika',
    areYouSure: 'Da li ste sigurni?',
    makeRoutesAvailable: 'Učini integracione rute dostupnim',
    percentage: 'Procenat',
    deleteImageMessage: 'Da li ste sigurni da želite da izbrišete ovu sliku?',
    deleteImageTitle: 'Brisanje slike',
    cancelSubscriptionTitle: 'Otkazivanje pretplate',
    subscriptionType: 'Tip pretplate',
    billingDate: 'Datum naplate',
    articlesOfLaw: 'Zakoni',
    country: 'Država',
    referent: 'Referent',
    language: 'Jezik',
    gnetId: 'GNET ID',
    affiliateId: 'Affiliate ID',
    trialEndsAt: 'Probni period završava',
    languageTooltip:
      'Podrazumijevani jezik za nove korisnike i jezik na kojem će biti poslati svi mejlovi kompanije (ne individualni mejlovi korisnika).',
    organisationType: 'Tip organizacije',
    regular: 'Regularni',
    affiliateSender: 'Affiliate pošiljalac',
    affiliateReceiver: 'Affiliate primalac',
    dispatcher: 'Dispečer',
  },
  transactionsHeaders: {
    invoiceNumber: 'Broj fakture',
    status: 'Status',
    total: 'Ukupno',
    currency: 'Valuta',
    billedAt: 'Izdato',
    actions: 'Akcije',
  },
  transactionsPage: {
    newItem: 'Nova transakcija',
    editItem: 'Uredi transakciju',
    id: 'ID',
    invoiceNumber: 'Broj fakture',
    status: 'Status',
    sent: 'Poslato',
    completed: 'Završeno',
    total: 'Ukupno',
    currency: 'Valuta',
    billedAt: 'Izdato',
    actions: 'Akcije',
    deleteTransaction: 'Izbriši transakciju',
    deleteMessage: 'Da li ste sigurni da želite izbrisati ovu transakciju?',
    currentSubscription: 'Trenutni paket',
    nextBillingDate: 'Datum sljedećeg plaćanja',
    numberOfSeatsTaken: 'Broj dodatih članova tima',
    numberOfSeatsAvailable: 'Broj dostupnih članova tima',
  },
  currenciesHeaders: {
    name: 'Naziv',
    code: 'Kod',
    symbol: 'Simbol',
    active: 'Aktivna',
    default: 'Primarna',
    actions: 'Akcije',
  },
  currenciesPage: {
    newItem: 'Novi unos valute ',
    editItem: 'Izmjena valute',
    id: 'Id',
    currency: 'Valuta',
    code: 'Kod',
    symbol: 'Simbol',
    deleteMessage: 'Da li ste sigurni da želite da izbrišete ovu valutu?',
  },
  paymentMethodsHeaders: {
    name: 'Naziv',
    active: 'Aktivna',
    default: 'Primarna',
    priceVisible: 'Cijena vidljiva',
    actions: 'Akcije',
  },
  paymentMethodsPage: {
    newItem: 'Novi unos metode plaćanja ',
    editItem: 'Izmjena metode plaćanja',
    id: 'Id',
    paymentMethod: 'Metoda plaćanja',
    deleteMessage: 'Da li ste sigurni da želite da izbrišete ovu metodu plaćanja?',
  },
  feedbacksHeaders: {
    subject: 'Naziv',
    organisation: 'Organizacija',
    user: 'Korisnik',
    actions: 'Akcije',
  },
  passengersHeaders: {
    firstName: 'Ime',
    lastName: 'Prezime',
    phoneNumber: 'Telefon',
    email: 'Email',
    passport: 'Pasoš',
    nationality: 'Nacionalnost',
    actions: 'Akcije',
  },
  checkpointsHeaders: {
    checkpointLocation: 'Lokacija putne tačke',
    arrivalTime: 'Vrijeme dolaska',
    actions: 'Akcije',
  },
  reportsHeaders: {
    vehicle: 'Vozilo',
    expenses: 'Trošak',
    earnings: 'Zarada',
    profit: 'Profit',
    daysWorking: 'Radnih dana',
    usageIndex: 'Indeks korišćenja',
    client: 'Klijent',
    numberOfDrivings: 'Broj naloga',
    percentForClient: 'Cijena za klijenta',
    sumOfPrice: 'Iznos',
  },
  tables: {
    noDataText: 'Nema podataka',
    search: 'Pretraga',
    drivings: 'Nalozi',
    drivingsByPage: 'Naloga po strani',
    vehicles: 'Vozila',
    vehiclesByPage: 'Vozila po strani',
    vehiclesClasses: 'Klase vozila',
    vehiclesClassesByPage: 'Klasa vozila po strani',
    expenses: 'Troškovi',
    expensesByPage: 'Troškova po strani',
    vehiclesMaintenance: 'Kvarovi vozila',
    vehiclesMaintenanceByPage: 'Kvarova po strani',
    reportsByPage: 'Izvještaja po strani',
    currencies: 'Valute',
    currencieseByPage: 'Valuta po strani',
    payments: 'Metode plaćanja',
    paymentsByPage: 'Metoda plaćanja po strani',
    saasAdministrators: 'Svi administratori',
    users: 'Svi korisnici',
    usersByPage: 'Korisnika po strani',
    clients: 'Svi klijenti',
    clientsByPage: 'Klijenata po strani',
    organisations: 'Sve organizacije',
    organisationsByPage: 'Organizacija po strani',
    rebates: 'Rabati',
    rebatesByPage: 'Rabati po strani',
    cards: 'Kartice',
    cardsByPage: 'Kartice po strani',
    transactions: 'Transakcije',
    transactionsByPage: 'Transakcije po strani',
    pricingRegions: 'Cjenovni regioni',
    pricingRegionsByPage: 'Cjenovnih regiona po strani',
    feedback: 'Feedback',
    feedbacksByPage: 'Feedbackova po strani',
    notifications: 'Obavještenja',
    notificationsByPage: 'Obavještenja po strani',
    all: 'Svi',
    passengers: 'Putnici',
    passengersByPage: 'Putnika po strani',
    of: 'od',
    checkpoints: 'Putne tačke',
    checkpointsByPage: 'Putne tačke po strani',
    invoices: 'Fakture',
    invoicesByPage: 'Faktura po strani',
    drivingOffers: 'Ponude za vožnju',
    drivingOffersByPage: 'Ponuda za vožnju po strani',
    smsMessages: 'SMS poruke',
    smsMessagesByPage: 'SMS poruke po stranici',
    resourcesUsed: 'Iskorišćeni resursi',
    resourcesUsedByPage: 'Resursi po stranici',
    inNext3Days: 'U naredna 3 dana',
    notConfirmedOrPaid: 'Nije potvrđeno ili plaćeno',
    inNext5Days: 'U narednih 5 dana',
    latestReviews: 'Najnovije recenzije',
    passengerReviews: 'Recenzije putnika',
    driversByPage: 'Vozači po stranici',
    reviewsByPage: 'Recenzija po stranici',
    partners: 'Partneri',
    partnersByPage: 'Partneri po stranici',
    sentRequests: 'Poslati zahtjevi',
    receivedRequests: 'Primljeni zahtjevi',
    requestsByPage: 'Zahtjevi po stranici',
    pricingZones: 'Zone i područja',
    pricingZonesByPage: 'Zone i područja po stranici',
    pricingPerZones: 'Cijene po zonama',
    pricingPerZonesByPage: 'Cijene po stranici',
    pricingPerHour: 'Cijene po satu',
    pricingPerHourByPage: 'Cijene po stranici',
  },
  statistics: {
    numberOfAcceptedDrivings: 'Broj prihvaćenih vožnji po vozaču',
    numberOfRejectedDrivings: 'Broj odbijenih vožnji po vozaču',
    vehicleDrivingsNumber: 'Broj vožnji po vozilima',
    vehicleProfit: 'Zarada po vozilima',
    monthDrivingsNumber: 'Broj vožnji po mjesecima',
    driverProfit: 'Zarada po vozaču',
  },
  packages: {
    active: 'Aktivan',
    starterTitle: 'Starter',
    monthlyPackagesMessage: 'Mjesečna pretplata po organizaciji.',
    starterText: 'Za pojedince ili male timove kojima trebaju samo osnovne funkcionalnosti.',
    starterListItemOne: 'Mobilne Aplikacije',
    starterListItemTwo: 'Push Obavještenja',
    starterListItemThree: 'Pristup Administraciji',
    starterListItemFour: 'Websajt Integracija',
    standardTitle: 'Standard',
    standardText: 'Savršeno za organizacije sa preko 5 vozača.',
    standardListItemOne: 'Paket STARTER Uključen',
    standardListItemTwo: 'Nema Limita Na Broj Vozača',
    standardListItemThree: '99.9% Uptime',
    standardListItemFour: 'Izvještaji & Upravljanje Vozilima',
    premiumTitle: 'Premium',
    premiumText: 'Savršen za limo servis organizacije koje djeluju na osnovu tačnih podataka.',
    premiumListItemOne: 'Paket STANDARD Uključen',
    premiumListItemTwo: 'BI & Predviđanje Troškova',
    premiumListItemThree: 'Export Podataka',
    premiumListItemFour: 'Nedeljni i Mjesečni Izvještaji',
    warningOne: '* Prikazane cijene su mjesečna pretplata po korisniku',
    warningTwo: '* Nakon prelaska na novi paket nije moguće preći na manji paket narednih 6 mjeseci',
    warningThree: '* Korisnici demo plana imaju uvid u funkcionalnosti koje pruža premium paket',
    warningOneAnnualPackage: 'Potrebno Vam je više? Imate veću organizaciju? Mi imamo ENTERPRISE paket za Vas.',
    warningTwoAnnualPackage: 'i naš prodajni tim će Vas kontaktirati ubrzo.',
    packageChange: 'Promjena paketa',
    changePackage: 'Da li ste sigurni da želite da pređete na',
    package: 'paket',
    annualPricing: 'Godišnje cijene',
    monthlyPricing: 'Mjesečne cijene',
    packageType10: 'Tim 10',
    packageType25: 'Tim 25',
    packageType50: 'Tim 50',
    'Team 10': 'Tim 10',
    'Team 25': 'Tim 25',
    'Team 50': 'Tim 50',
    annualPackagesMessage: 'Godišnja pretplata po organizaciji.',
    packageTypeSoloMainMessage: 'Samo 1 korisnički naloga.',
    packageType5MainMessage: 'Do 5 korisničkih naloga.',
    packageType10MainMessage: 'Do 10 korisničkih naloga.',
    packageType25MainMessage: 'Do 25 korisničkih naloga.',
    packageType50MainMessage: 'Do 50 korisničkih naloga.',
    allFeaturesIncluded: 'Sve funkcionalnosti uključene.',
    unlimitedRides: 'Neograničen broj vožnji i vozila',
    trialPeriod: 'Uključen probni period.',
    onboardingFeeOptional: 'Naknada za instalaciju opciona.',
    noHiddenCosts: 'Bez skrivenih troškova.',
    freeSmsFlights10: 'Besplatno 15 SMS-ova i 50 provjera letova mjesečno.',
    freeSmsFlights25: 'Besplatno 35 SMS-ova i 100 provjera letova mjesečno.',
    freeSmsFlights50: 'Besplatno 50 SMS-ova i 200 provjera letova mjesečno.',
  },
  successPaymentPage: {
    message: 'Uspješno ste izvršili plaćanje. Hvala Vam.',
  },
  demoExpiring: {
    notification: 'Obavještenje',
    expiredSubscriptionMessage:
      'Obavještavmo Vas da je Vaša pretplata na ovu platformu  istekla. Kako biste nastavili koristiti naše usluge, molimo Vas da obnovite svoju pretplatu.',
  },
  login: {
    username: 'Korisničko ime',
    password: 'Lozinka',
    rememberMe: 'Zapamti me',
    login: 'Prijavi se',
    noAccount: 'Nemate nalog još uvijek? Možete se registrovati ',
    forgetPassword: 'Zaboravili ste lozinku? Možete je resetovati ',
    registerHere: 'Ovdje',
    email: 'Email',
    enterEmail: 'Molimo Vas unesite email',
    enterPassword: 'Molimo Vas unesite novu lozinku',
    signInWithGoogle: 'Prijava putem Google-a',
    passwordChangeError:
      'Došlo je do greške prilikom mijenjanja lozinke. Molimo Vas pokušajte ponovo ili kontaktirajte podršku.',
  },
  registration: {
    register: 'Registruj se',
    name: 'Ime organizacije',
    address: 'Adresa',
    phoneNumber: 'Broj telefona',
    email: 'Email',
    companyId: 'PIB',
    vat: 'PDV',
    language: 'Jezik',
    package: 'Paket',
    accept: 'Prihvatam',
    terms: 'uslove korišćenja',
    successfullyRegistered: 'Korisnik je uspješno registrovan. Dobićete email sa verifikacionim linkom',
    haveAccount: 'Već imate nalog? Možete se prijaviti',
    loginHere: 'Ovdje',
    trialPeriodInfo:
      'Uključen je besplatan probni period od 14 dana. Naplata će uslijediti nakon završetka probnog perioda.',
  },
  driving: {
    drivingType: 'Tip rezervacije',
    general: 'Opšte informacije',
    newDriving: 'Zakaži transfer',
    newDailyRent: 'Zakaži dnevni najam',
    areYouSure: 'Da li ste sigurni?',
    vehicleIsUnavailable: 'Vozilo je zauzeto.',
    vehicleIsDamaged: 'Vozilo je pokvareno.',
    sureAboutThisVehicle: 'Da li ste sigurni da želite da odaberete ovo vozilo?',
    driverUnavaliable: 'Vozač je zauzet.',
    sureAboutThisDriver: 'Da li ste sigurni da želite da odaberete ovog vozača?',
    checkVehicleSeatAvailability: 'Ovo vozilo ne može da primi ovoliko putnika.',
    cancelDriving: 'Da li ste sigurni da želite da otkažete nalog?',
    finishDriving: 'Da li ste sigurni da želite da završite nalog?',
    noShowDriving: 'Da li ste sigurni da želite da označite vožnju kao nepojavljivanje?',
    markDrivingAsStarted: 'Da li ste sigurni da želite označiti nalog za vožnju kao započet?',
    deleteDriving: 'Da li ste sigurni da želite da izbrišete nalog?',
    newTransfer: 'Novi transfer',
    driving: 'Nalog',
    comments: 'Komentari',
    id: 'Id',
    locationFrom: 'Polazište',
    locationTo: 'Odredište',
    vehicleClass: 'Klasa vozila',
    vehicle: 'Vozilo',
    driver: 'Vozač',
    pickupTime: 'Vrijeme polaska',
    clientConfirmed: 'Klijent potvrdio',
    description: 'Opis',
    extraInfo: 'Dodatne informacije',
    drivingNotAccepted: 'Nisu prihvatili vožnju:',
    currency: 'Valuta',
    paymentMethod: 'Metoda plaćanja',
    price: 'Cijena',
    paid: 'Plaćeno',
    distance: 'Razdaljina (km)',
    distanceMi: 'Udaljenost (milja)',
    expectedTime: 'Očekivano trajanje',
    waitingTime: 'Vrijeme čekanja (h)',
    EnterTheNumberOfHoursWaiting: 'Unesite broj sati čekanja',
    dropOffTime: 'Vrijeme dolaska',
    comebackTime: 'Vrijeme povratka',
    acceptUntil: 'Prihvatiti do',
    drivingRequired: 'Vožnja obavezna',
    comment: 'Komentar',
    attachments: 'Dodatni fajlovi',
    addFiles: '+ Dodajte fajlove',
    deleteFiles: 'Da li ste sigurni da želite da izbrišete ovaj fajl?',
    passengers: 'Putnici',
    addPassenger: '+ Dodajte putnika',
    deletePassenger: 'Da li ste sigurni da želite da izbrišete ovog putnika?',
    chooseClient: 'Odaberite klijenta',
    costs: 'Troškovi',
    checkpoints: 'Putne tačke',
    addCheckpoint: 'Dodaj putnu tačku',
    deleteCheckpoint: 'Da li ste sigurni da želite da izbrišete ovu putnu tačku?',
    waitingBoardText: 'Tekst za čekanje na oglasnoj ploči',
    flightNumber: 'Broj leta',
    flightInfo: 'Informacije o letu',
    createdBy: 'Nalog za vožnju kreiran <b>{time}</b> od strane <b>{user}</b>',
    client: 'Klijent',
    gnet: 'GNET',
    note: 'Napomena',
    noteDriver: 'Napomena za vozača',
    noteDispatcher: 'Napomena za dispečera',
    passenger: 'Putnik',
    fullName: 'Ime i prezime',
    phoneNumber: 'Broj telefona',
    email: 'Email',
    suitcasesNumber: 'Broj kofera',
    passengersNumber: 'Broj putnika',
    babySeatsNumber: 'Broj sjedišta za bebe',
    stopsNumber: 'Broj zaustavljanja',
    steps: {
      mainInfo: 'Osnovne informacije',
      additionalInfo: 'Dodatne informacije',
      review: 'Pregled',
    },
    commissionAmount: 'Provizija',
    sendSms: 'Pošalji SMS',
    message: 'Poruka',
    acceptDriving: 'Da li ste sigurni da želite prihvatiti vožnju?',
    rejectDriving: 'Da li ste sigurni da želite odbiti vožnju?',
    calculatePriceViaZonePricing: 'Izračunaj cijenu putem cjenovne zone',
    cannotCalculatePriceViaZonePricingForDailyRents:
      'Nije moguće izračunati cijenu putem cjenovne zone za dnevni najam',
    disableCalculatePriceViaZonePricing:
      'Nije moguće izračunati cijenu putem cjenovne zone bez lokacije, valute i klase vozila',
    numberOfPassengers: 'Broj putnika',
    numberOfSuitcases: 'Broj kofera',
    vat: 'PDV',
    vatPercentage: 'PDV procenat',
    priceType: 'Tip cijene',
    accessibleForWheelchair: 'Pristupačno za invalidska kolica',
    priceForWaiting: 'Cijena čekanja',
    numberOfHours: 'Broj sati',
    getAQuote: 'Cijena na upit',
  },
  drivingStatus: {
    pending: 'Na čekanju',
    accepted: 'Prihvaćeno',
    rejected: 'Odbijeno',
    expired: 'Isteklo',
    canceled: 'Otkazano',
    draft: 'Draft',
    done: 'Završeno',
    noShow: 'Nepojavljivanje',
    ongoing: 'Započeto',
  },
  drivingOrderType: {
    dailyRent: 'Dnevni najam',
    transfer: 'Transfer',
    hourlyDaily: 'Po satu/danu',
    oneWayTransfer: 'Jednosmjerni transfer',
    fromAirport: 'Od aerodroma',
    toAirport: 'Do aerodroma',
  },
  flightInfo: {
    airlineName: 'Naziv avio kompanije',
    flightNumber: 'Broj leta',
    departureAirport: 'Aerodrom polaska',
    departureCountry: 'Zemlja polaska',
    departureTime: 'Vrijeme polaska',
    arrivalAirport: 'Aerodrom dolaska',
    arrivalCountry: 'Zemlja dolaska',
    arrivalTime: 'Vrijeme dolaska',
    departureDelayed: 'Kašnjenje polaska',
    flightStatus: 'Status leta',
    percentageOfFlight: 'Procenat leta',
  },
  customValidations: {
    fileType: `Tip fajla .{extension} nije podržan`,
    fileSize: 'Fajlovi ne mogu biti veći od {size}',
    fieldRequired: 'Polje {field} je obavezno',
    fieldType: 'Polje {field} mora biti {type}',
    fieldLengthMin: 'Polje {field} mora sadržati najmanje {length} karaktera',
    fieldLengthMax: 'Polje {field} ne smije biti duže od {length} karaktera',
    fieldUnique: 'Polje {field} mora biti jedinstveno',
    fieldValid: 'Polje {field} mora biti validno',
    noActiveSubscription: 'Niste pretplaćeni ni na jedan paket',
    usersLimitExceeded: 'Dostignut limit za broj korinsika',
    fieldBefore: 'Polje {field1} mora biti prije polja {field2}',
    flightNotExist: 'Let {field} ne postoji',
    gnetKeyNotValid: 'Uneseni Gnet ključ nije validan.',
    gnetPassengersRequired: 'Nalog za vožnju ne može biti poslat GNET-u bez putnika.',
    priceAndCurrencyRequired: 'Link za plaćanje ne može biti generisan bez cijene i valute',
    fieldValueNotSupported: 'Vrijednost polja {field} nije podržana',
    noCountryCode: 'Niste odabrali državu',
    noStripeForYourCountry: 'Stripe nije dostupan u vašoj zemlji',
    stripeAlreadyEnabled: 'Stripe je već aktiviran',
    fieldValueMin: 'Polje {field} mora biti {min} ili više',
    fieldValueMax: 'Polje {field} mora biti {max} ili manje',
    markAsDoneDate: 'Vrijeme polaska ne može biti u budućnosti',
    phoneNumberNotValidForSms:
      'Broj telefona nije validan za slanje SMS-a. Validan broj telefona mora sadržati pozivni broj zemlje.',
    affiliateIdDoesNotExist: 'Organizacija sa datim affiliate ID-em ne postoji.',
    numericDecimal: 'Polje {field} mora biti broj (može i decimalni)',
  },
  fieldTypes: {
    string: 'string',
    integer: 'broj',
    date: 'datum',
  },
  names: {
    username: 'korisničko ime',
    password: 'lozinka',
    choose_client: 'odaberi klijenta',
    choose_month: 'odaberi mjesec',
    currency: 'valuta',
    payment_method: 'metoda plaćanja',
    first_name: 'ime',
    last_name: 'prezime',
    passport: 'pasoš',
    nationality: 'nacionalnost',
    plate_number: 'registarske oznake',
    brand: 'marka vozila',
    vehicle_class: 'klasa vozila',
    number_of_passengers: 'broj putnika',
    fuel_consumption: 'potrošnja (100km)',
    year_of_manufacture: 'godina proizvodnje',
    price_per_km: 'cijena po km',
    price_per_mi: 'cijena po mi',
    price_per_hour: 'cijena po satu',
    price_per_waiting_hour: 'cijena po satu čekanja',
    vehicle: 'vozilo',
    description: 'opis',
    time_from: 'vrijeme od',
    time_to: 'vrijeme do',
    name: 'naziv',
    cost_type: 'tip troška',
    cost_time: 'vrijeme troška',
    price: 'cijena',
    rebate_min: 'minimalni broj naloga',
    rebate_max: 'maksimalni broj naloga',
    rebate: 'rabat',
    phone_number: 'broj telefona',
    organisation: 'organizacija',
    role: 'uloga',
    image: 'slika',
    location_from: 'polazište',
    location_to: 'odredište',
    pickup_time: 'Vrijeme polaska',
    distance: 'razdaljina',
    waiting_time: 'vrijeme čekanja',
    comment: 'komentar',
    address: 'adresa',
    vat: 'PDV',
    company_id: 'pib',
    company_personal_id: 'pib/lični id',
    licence: 'licenca',
    warrant_number: 'broj rješenja',
    seal: 'pečat',
    signature: 'potpis',
    email: 'email',
    percentage: 'procenat',
    organizationName: 'ime organizacije',
    package: 'paket',
    choose_currency: 'izaberi valutu',
    team_10_price: 'cijena za tim 10',
    team_10_paddle_price_id: 'ID cijene za tim 10 Paddle',
    team_25_price: 'cijena za tim 25',
    team_25_paddle_price_id: 'ID cijene za tim 25 Paddle',
    team_50_price: 'cijena za tim 50',
    team_50_paddle_price_id: 'ID cijene za tim 50 Paddle',
    team_10_price_monthly: 'cijena za tim 10',
    team_10_paddle_price_id_monthly: 'ID cijene za tim 10 Paddle',
    team_25_price_monthly: 'cijena za tim 25',
    team_25_paddle_price_id_monthly: 'ID cijene za tim 25 Paddle',
    team_50_price_monthly: 'cijena za tim 50',
    team_50_paddle_price_id_monthly: 'ID cijene za tim 50 Paddle',
    countries: 'zemlje',
    invoice_number: 'broj fakture',
    status: 'status',
    total: 'ukupno',
    billed_at: 'datum izdavanja',
    articles_of_law: 'zakoni',
    country: 'država',
    referent: 'referent',
    language: 'jezik',
    location: 'lokacija',
    arrival_time: 'vrijeme dolaska',
    waiting_board_text: 'tekst za čekanje na oglasnoj ploči',
    flight_number: 'broj leta',
    gnet_id: 'GNET ID',
    receiver_id: 'ID primaoca',
    preferred_vehicle_type: 'preferirani tip vozila',
    reservation_type: 'tip rezervacije',
    run_type: 'tip vožnje',
    trip_duration_minutes: 'trajanje putovanja',
    client: 'klijent',
    client_type: 'tip klijenta',
    card_holder_name: 'ime vlasnika kartice',
    card_number: 'broj kartice',
    expiry_date: 'datum isteka',
    cvv: 'cvv',
    code: 'kod',
    symbol: 'simbol',
    quantity: 'količina',
    discount: 'popust',
    driving_type: 'tip vožnje',
    full_name: 'puno ime',
    suitcases_number: 'broj kofera',
    passengers_number: 'broj putnika',
    baby_seats_number: 'broj dječijih sjedišta',
    stops_number: 'broj zaustavljanja',
    commission_amount: 'provizija',
    message: 'poruka',
    affiliate_id: 'affiliate id',
    coordinates: 'koordinate',
    zone_one: 'zona jedan',
    zone_two: 'zona dva',
    price_type: 'tip cijene',
    price_for_waiting: 'cijena za čekanje',
    number_of_hours: 'broj sati',
    zones: 'Zone',
  },
  weekDays: {
    monday: 'Ponedeljak',
    tuesday: 'Utorak',
    wednesday: 'Srijeda',
    thursday: 'Četvrtak',
    friday: 'Petak',
    saturday: 'Subota',
    sunday: 'Nedelja',
  },
  select: {
    noDataAvailable: 'Nema podataka',
    selected: 'odabrano',
  },
  excelExport: {
    to_date_range: 'Izvještaj ne može biti generisan za datume koji su u budućnosti. Molimo unesite valjane datume.',
    overlap_range: 'Krajnje vrijeme ne može biti kasnije od početnog vremena.',
  },
  setPassword: {
    success: 'Email poslat',
    checkEmail: 'Provjerite email za dalja uputstva',
    already_changed: 'Lozinka je ranije promijenjena',
    send_new_reset_request: 'Lozinka je ranije promijenjena. Za ponovnu promjenu posaljite novi zahtjev.',
  },
  statisticsPage: {
    reportType: 'Tip izvještaja',
    reportTime: 'Vrijeme izvještaja',
    vehicleUtilization: 'Izvještaj o korišćenju vozila',
    costEffectiveness: 'Izvještaj isplativosti vozila',
    generalReport: 'Generalni izvještaj',
    clientsReport: 'Izvještaj po klijentima',
  },
  profile: {
    yourProfile: 'Profil',
    name: 'Ime',
    role: 'Uloga',
    username: 'Korisničko ime',
    password: 'Šifra',
    phoneNumber: 'Broj telefona',
    email: 'Email',
    percentage: 'Procenat',
  },
  gpsMaps: {
    noTransfers: 'Nema tekućih ili predstojećih transfera.',
    map: 'Karta',
    transfers: 'Nalozi',
    drivers: 'Vozači',
    ongoing: 'U toku',
    upcoming: 'Nadolazeći',
    driving: 'Vožnja',
    driver: 'Vozač',
    vehicle: 'Vozilo',
    requestDriverLocation: 'Zahtjev za lokaciju vozača',
    lastRecordedLocation: 'Posljednja zabilježena lokacija',
  },
  driverStatus: {
    available: 'Dostupan',
    offline: 'Van mreže',
    inTransfer: 'U transferu',
  },
  onboardingTutorialMain: {
    languageStep: 'Ovdje možete postaviti željeni jezik za platformu.',
    notificationsStep: 'Ovdje možete pronaći svoje obavještenja.',
    addTransferStep: 'Kliknite ovo dugme za kreiranje nove rezervacije, transfera i dnevnih najama.',
    sideMenuButtonStep: 'Kliknite ovu ikonu da prikažete ili sakrijete meni.',
    sideMenuExpStep:
      'Odavde možete stići do glavnih sekcija aplikacije: Rezervacije, Vozni park, Troškovi, Administracija, Fakture, Statistika, itd.',
    sideMenuButtonCloseStep: 'Kliknite istu ikonu da sakrijete meni.',
    supportStep: 'Ako imate bilo kakvih problema ili pitanja, slobodno kontaktirajte korisničku podršku klikom ovdje.',
  },
  onboardingTutorialVehicles: {
    tableStep: 'Vozila će biti prikazana u ovoj tabeli nakon što ih dodate.',
    searchStep: 'Možete lako pronaći vozila pretragom po marki, registarskoj oznaci, godini itd.',
    addStep:
      'Dodajte nova vozila klikom na dugme „Dodaj“. Imajte na umu da prvo morate dodati sve „Klase vozila“ prije unosa vozila.',
  },
  onboardingTutorialStatistics: {
    typeStep:
      'U ovom dijelu možete pronaći sve statističke podatke prikazane u grafikonima. Ovdje odaberite tip statistike.',
    intervalStep: 'Izaberite interval za koji želite pregled statistike.',
    downloadStep: 'Takođe možete preuzeti i odštampati ove tabele.',
  },
  onboardingTutorialGps: {
    tabOneStep:
      'U GPS modulu možete pratiti svoje vozače i transfere na jednom mjestu. Izaberite ovu karticu za prikaz aktuelnih i nadolazećih transfera (sljedećih 24h).',
    tabTwoStep: 'Izaberite vozila da ih vidite na mapi. Možete dodijeliti dostupna vozila transferima direktno odavde.',
    mapStep: 'Izaberite ili odaberite vozila klikom na njihov pin na mapi.',
  },
  onboardingTutorialInvoices: {
    tableStep: 'Sve fakture će biti prikazane u ovoj tabeli, od najnovijih do najstarijih.',
    searchStep: 'Lako pronađite fakture pretragom po broju, iznosu, imenu klijenta, datumu kreiranja, itd.',
    addStep: 'Kreirajte nove fakture klikom na dugme „Dodaj“.',
  },
  onboardingTutorialOffers: {
    tableStep: 'Ponude će biti prikazane u ovoj tabeli nakon što ih dodate, od najnovijih do najstarijih.',
    searchStep: 'Lako pronađite ponude pretragom po broju, iznosu ili opisu.',
    addStep: 'Kreirajte nove ponude klikom na dugme „Dodaj“.',
  },
  onboardingTutorialResources: {
    infoStep: 'Prikazuje se koliko vam resursa preostaje, a na zahtjev možete kupiti još SMS poruka.',
    tableStep:
      'U ovoj tabeli možete pronaći evidenciju i istoriju svih korištenih resursa (poslati SMS-ovi i provjereni letovi).',
    searchStep: 'Lako pronađite resurse pretragom po sadržaju, korisniku ili datumu kreiranja.',
  },
  onboardingTutorialPassengerReviews: {
    tableStep: 'Recenzije putnika će biti prikazane u ovoj tabeli, od najnovijih do najstarijih.',
    searchStep: 'Lako pronađite recenzije putnika pretragom po broju ili komentaru putnika.',
  },
  onboardingTutorialPricingZones: {
    tableStep: 'Cjenovne zone će biti prikazane u ovoj tabeli nakon što ih dodate.',
    searchStep: 'Lako pronađite cjenove zone pretragom.',
    addStep: 'Kreirajte nove cjenove zone klikom na dugme „Dodaj“.',
  },
  onboardingTutorialPricingZoneRelations: {
    tableStep: 'Relacije cjenovnih zona (cijene) će biti prikazane u ovoj tabeli nakon što ih dodate.',
    searchStep: 'Lako pronađite relacije cjenovnih zona pretragom.',
    addStep:
      'Kreirajte nove relacije cjenovnih zona (cijene) klikom na dugme „Dodaj“. Imajte na umu da prvo trebate dodati „Zone cijena“ prije korištenja ove funkcije.',
  },
  onboardingTutorialPricingZoneHourlyRelations: {
    tableStep:
      'Sve cjenovne zone su navedene u ovoj tabeli. Otvaranjem određene zone možete postaviti cijene za tu zonu.',
    searchStep: 'Jednostavno pronađite cjenovne zone pretraživanjem direktno.',
  },
  onboardingTutorialDashboard: {
    infoStep: 'Prikazuje sažetak statistike vaše organizacije za posljednjih 30 dana.',
    next3DaysStep: 'Nalozi za naredna 3 dana će biti prikazan u ovoj tabeli.',
    notPaidOrConfirmedStep:
      'Nalozi za narednih 7 dana koji nisu potvrđeni ili plaćeni će biti prikazani u ovoj tabeli.',
    next5DaysStep: 'Zauzeti vozači za narednih 5 dana će biti prikazani u ovoj tabeli.',
    latestReviewsStep: 'Najnovije recenzije vožnji će biti prikazane u ovoj tabeli.',
  },
  onboardingTutorialAffiliate: {
    tabStep: 'Promjenom taba možete vidjeti svoje partnere, poslane zahtjeve ili primljene zahtjeve.',
    idStep: 'Ovdje možete pronaći svoj affiliate ID koji možete podijeliti sa partnerima.',
    addStep: 'Kreirajte novi zahtjev za affiliate partnera klikom na dugme „Dodaj“.',
  },
  onboardingTutorialAdvancedSettings: {
    advancedSettings:
      'Klikom ovdje možete pristupiti naprednim podešavanjima vaše organizacije i podesiti sve stavke i integracije.',
  },
  advancedSettings: {
    advancedSettings: 'Napredna podešavanja',
    distanceUnit: 'Jedinica za udaljenost',
    distanceUnitDescription: 'Izaberite jedinicu za mjerenje udaljenosti.',
    kilometers: 'Kilometri',
    miles: 'Milje',
    dateFormat: 'Format datuma',
    dateFormatDescription: 'Odaberite format datuma koji želite koristiti.',
    ddmmyyyy: 'DD-MM-YYYY',
    mmddyyyy: 'MM-DD-YYYY',
    mandatoryDrivings: 'Obavezne vožnje',
    mandatoryDrivingsDescription: 'Odaberite ako želite da sve vožnje budu obavezne po defaultu.',
    mandatoryDrivingsDefault: 'Obavezne vožnje (Po defaultu)',
    paidDrivings: 'Plaćene vožnje',
    paidDrivingsDescription: 'Odaberite ako želite da sve vožnje budu plaćene po defaultu.',
    paidDrivingsDefault: 'Plaćene vožnje (Po defaultu)',
    notificationsEmail: 'Email za obavještenja',
    notificationsEmailDescription:
      'Promijenite defaultni email za primanje obavještenja (ako ne želite koristiti email vaše organizacije).',
    email: 'Email',
    articlesOfLaw: 'Zakoni',
    integrations: 'Integracije',
    gnetId: 'GNET ID',
    gnetDescription:
      'GNET je sveobuhvatna platforma dizajnirana za optimizaciju praćenja u realnom vremenu, upravljanja flotom i dispečerskih operacija za transportnu industriju.',
    makeRoutesAvailable: 'Učini integracione rute dostupnim',
    makeRoutesAvailableDescription:
      'Aktivirajte svoj API ključ da integrišete LimoExpress sa vašim web sajtom, ERP sistemom i još mnogo toga.',
    makePublicFormAvailable: 'Omogući javni obrazac',
    makePublicFormAvailableDescription:
      'Aktivirajte ovu opciju kako biste omogućili pristup javnoj stranici za rezervaciju, tako da vaši klijenti mogu pristupiti naprednom obrascu za rezervaciju i lako kreirati naloge. Link možete koristiti u biltenima, na sajtu, u flajerima; to je jedinstven link samo za vašu kompaniju. Alternativno, možete dobiti i dio koda za embedovanje obrasca za rezervaciju direktno na vašem sajtu.',
    emailsEnabled: 'Omogućena email obaveštenja',
    emailsEnabledDefault: 'Omogućena email obaveštenja',
    emailsEnabledDescription: 'Odaberite ako želite da primate obavještenja putem email-a.',
    passengerReviewsEnabled: 'Omogućene ocjene putnika',
    passengerReviewsEnabledDefault: 'Omogućene ocjene putnika',
    passengerReviewsEnabledDescription:
      'Aktivacijom ove funkcionalnosti vaši putnici (ukoliko je unesen email) će dobiti email potvrdu nakon završene vožnje (označene kao Završeno) i imat će mogućnost da daju ocjene (feedback) za vaše usluge.',
    vat: 'PDV',
    vatPercentage: 'Procenat PDV-a',
    vatPercentageDescription: 'Unesite procenat PDV-a.',
    invoicePaymentInstructions: 'Uputstva za plaćanje fakture',
    stripe: 'Stripe',
    stripeDescription:
      'Aktivirajte Stripe kako bi mogli da naplaćujete usluge od svojih klijenata. LimoExpress ne uzima proviziju.',
    dataExport: 'Exportovanje podataka',
    dataExportDescription: 'Možete exportovati sve svoje podatke, i oni će vam biti poslati na email.',
    sureAboutDataExport:
      'Da li ste sigurni da želite da exportujete sve svoje podatke? Ako jeste, svi podaci će vam stići na mail u naredna 2 sata.',
    defaultPriceType: 'Podrazumijevani tip cijene',
    defaultPriceTypeDescription: 'Podesite podrazumjevani tip cijene kako bi se odredio prikaz cijena.',
    NET: 'NETO',
    GROSS: 'BRUTO',
    link: 'Link',
    code: 'Kod',
    language: 'Jezik',
    chooseSize: 'Izaberite veličinu',
    small: 'Mala',
    medium: 'Srednja',
    large: 'Velika',
  },
  drivingExcelCheckboxes: {
    drivingNumber: 'Broj naloga',
    drivingType: 'Tip rezervacije',
    drivingStatus: 'Status',
    locationFrom: 'Polazište',
    locationTo: 'Odredište',
    driver: 'Vozač',
    client: 'Klijent',
    plateNumber: 'Registarska oznaka',
    note: 'Opis',
    fuelConsumption: 'Potrošnja goriva',
    pickupTime: 'Vrijeme polaska',
    numberOfPassengers: 'Broj putnika',
    passengers: 'Putnici',
    price: 'Cijena',
    paid: 'Plaćeno',
    paymentMethod: 'Metoda plaćanja',
    waitingTime: 'Vrijeme čekanja (h)',
    distance: 'Razdaljina (km)',
    distanceMi: 'Udaljenost (milja)',
    selectAll: 'Izaberi sve',
  },
  gnet: {
    gnetInfo: 'GNET informacije',
    requesterId: 'ID podnosioca zahtjeva',
    receiverId: 'ID primaoca',
    preferredVehicleType: 'Preporučeni tip vozila',
    reservationType: 'Tip rezervacije',
    runType: 'Tip vožnje',
    price: 'Cijena',
    currency: 'Valuta',
    noteDriver: 'Napomena za vozača',
    noteDispatcher: 'Napomena za dispečera',
    tripDurationMinutes: 'Trajanje putovanja (minuti)',
  },
  invoicesHeaders: {
    invoiceNumber: 'Broj fakture',
    client: 'Klijent',
    totalPrice: 'Ukupna cijena',
    drivings: 'Vožnje',
    paid: 'Plaćeno',
    currency: 'Valuta',
    createdAt: 'Kreirano',
    actions: 'Akcije',
  },
  invoicesPage: {
    newItem: 'Novi unos fakture',
    chooseClient: 'Odaberite klijenta',
    chooseDrivings: 'Odaberite naloge',
    allDrivings: 'Svi nalozi',
    deleteMessage: 'Da li ste sigurni da želite da izbrišete ovu fakturu?',
    markInvoiceAsPaid: 'Da li ste sigurni da želite označiti ovu fakturu kao plaćenu?',
  },
  drivingOffersHeaders: {
    number: 'Broj ponude za vožnju',
    totalPrice: 'Ukupna cijena',
    description: 'Opis',
    paid: 'Plaćeno',
    currency: 'Valuta',
    createdAt: 'Kreirano',
    actions: 'Akcije',
  },
  drivingOffersPage: {
    newItem: 'Novi unos ponude za vožnju',
    item: 'Stavka',
    description: 'Opis',
    title: 'Naslov',
    vehicleClass: 'Klasa vozila',
    quantity: 'Količina',
    price: 'Cijena',
    discount: 'Popust',
    vat: 'PDV',
    deleteMessage: 'Da li ste sigurni da želite obrisati ovu ponudu za vožnju?',
    markDrivingOfferAsPaid: 'Da li ste sigurni da želite označiti ovu ponudu za vožnju kao plaćenu?',
  },
  modal: {
    reservationSuccessTitle: 'Uspjeh',
    reservationSuccessMessage: 'Zahtjev za rezervaciju je uspješno poslat. Neko će vas uskoro kontaktirati.',
    reservationErrorTitle: 'Forma onemogućena',
    reservationErrorMessage:
      'Forma je trenutno onemogućena od strane organizacije. Molimo kontaktirajte podršku ili pokušajte ponovo kasnije.',
  },
  dashboardPage: {
    drivingCountToday: 'Broj naloga (danas)',
    drivingCountThisMonth: 'Broj naloga (poslednjih 30 dana)',
    dailyRentsAndTransfers: 'Broj naloga (dnevni najam/transferi)',
    drivingTotalAmount: 'Ukupna suma',
    driversCount: 'Aktivni vozači (±7 dana)',
    passengerCount: 'Broj putnika (poslednjih 30 dana)',
  },
  reviewsHeaders: {
    rating: 'Ocjena',
    comment: 'Komentar',
    createdAt: 'Kreirano',
  },
  resourceOverviewHeaders: {
    actionType: 'Tip akcije',
    user: 'Korisnik',
    action: 'Akcija',
    createdAt: 'Kreirano',
  },
  resourceOverviewPage: {
    smsMessagesLeft: 'Broj preostalih SMS poruka',
    flightsCheckLeft: 'Broj preostalih provjera letova',
    sms: 'SMS',
    flightCheck: 'Provjera leta',
  },
  affiliateHeaders: {
    organisation: 'Organizacija',
    createdAt: 'Kreirano',
    actions: 'Akcije',
  },
  affiliatePage: {
    partners: 'Partneri',
    sentRequests: 'Poslati zahtjevi',
    receivedRequests: 'Primljeni zahtjevi',
    acceptRequest: 'Da li ste sigurni da želite prihvatiti ovaj zahtjev?',
    rejectRequest: 'Da li ste sigurni da želite odbiti ovaj zahtjev?',
    deletePartner: 'Da li ste sigurni da želite obrisati ovog partnera?',
    deleteRequest: 'Da li ste sigurni da želite obrisati zahtjev?',
    newItem: 'Novi zahtjev',
    affiliateId: 'Affiliate ID',
    registerNewPartner: 'Registrujte novog partnera',
    registrationSuccessfull: 'Registracija uspješna',
    registrationSuccessfullDescription:
      'Affiliate partner je uspješno registrovan! Obavijestite partnera da će dobiti email za verifikaciju naloga i unos lozinke. Nakon toga, mogu početi koristiti platformu.',
  },
  affiliate: {
    affiliateInfo: 'Affiliate informacije',
    affiliate: 'Affiliate',
    partner: 'Partner',
    preferredVehicleType: 'Preferirani tip vozila',
    driverInfo: 'Informacije o vozaču',
    vehicleInfo: 'Informacije o vozilu',
    price: 'Cijena',
    currency: 'Valuta',
  },
  pricingZonesHeaders: {
    name: 'Naziv zone',
    code: 'Šifra',
    postalCode: 'Poštanski broj',
    actions: 'Akcije',
  },
  pricingZonesPage: {
    newItem: 'Novi unos cjenovne zone',
    editItem: 'Izmjena cjenovne zone',
    id: 'ID',
    name: 'Naziv zone',
    code: 'Šifra',
    deleteMessage: 'Da li ste sigurni da želite da obrišete ovu cjenovnu zonu?',
    postalCode: 'Poštanski broj',
    postalCodeDescription: 'Definišite zonu na mapi pomoću poštanskog broja',
    postalCodeDisabled: 'Molimo unesite poštanski broj',
  },
  pricingZoneRelationsHeaders: {
    zoneOne: 'Zona jedan',
    zoneTwo: 'Zona dva',
    client: 'Klijent',
    actions: 'Akcije',
  },
  pricingZoneRelationsPage: {
    newItem: 'Unos nove veze cjenovnih zona',
    editItem: 'Izmjena veze cjenovnih zona',
    zone: 'Zona',
    zoneOne: 'Zona jedan',
    zoneTwo: 'Zona dva',
    chooseClient: 'Izaberite klijenta',
    chooseClientFrom: 'Kloniraj od klijenta',
    chooseClientTo: 'Kloniraj za klijenta',
    cloneItem: 'Kloniranje veze cjenovnih zona',
    zoneRelations: 'Veze cjenovnih zona',
    cloneItemHourly: 'Kloniranje cijena po satu',
    zones: 'Zone',
    fromTo: 'Od - Do',
    percentage: 'Procenat',
    deleteMessage: 'Da li ste sigurni da želite obrisati ovu vezu cjenovnih zona?',
  },
  referralPrize: {
    referToAFriend: 'Preporučite prijatelju',
    referToAFriendDescription:
      'Pozovite prijatelje da se pridruže platformi unosom njihove email adrese ispod. Oni će dobiti personalizovanu pozivnicu za registraciju i početak korišćenja platforme. Alternativno, možete podijeliti vaš jedinstveni link za registraciju direktno s drugima! Kada se neko registruje putem vašeg linka ili pozivnice, dobićete nagradu za preporuku kao znak zahvalnosti. Počnite da pozivate i dijelite danas!',
  },
};
