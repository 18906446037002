import { getHttpClient } from './client';

export const getAllPricingZones = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/zone-pricing';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const savePricingZone = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/zone-pricing', 'POST', data).then(
    (response) => response.data
  );
};

export const updatePricingZone = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/zone-pricing', 'POST', data).then(
    (response) => response.data
  );
};

export const deletePricingZone = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/zone-pricing/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const getAllPricingZoneRelations = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/zone-pricing-relation';

  let queryString = '?';
  if (data) {
    if (data.client_id) {
      queryString += `client_id=${data.client_id}`;
    }
    if (data.global_only) {
      queryString += `global_only=${data.global_only}`;
    }
  }

  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const savePricingZoneRelation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/zone-pricing-relation', 'POST', data).then(
    (response) => response.data
  );
};

export const clonePricingZoneRelation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/clone-zone-pricing-relations', 'POST', data).then(
    (response) => response.data
  );
};

export const deletePricingZoneRelation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/zone-pricing-relation/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const getAllPricingHourRelations = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/zone-pricing-hourly';

  let queryString = '?';
  if (data) {
    if (data.client_id) {
      queryString += `client_id=${data.client_id}`;
    }
    if (data.global_only) {
      queryString += `global_only=${data.global_only}`;
    }
  }

  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const savePricingHourRelation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/zone-pricing-hourly', 'POST', data).then(
    (response) => response.data
  );
};

export const clonePricingHourRelation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/clone-zone-pricing-hourly', 'POST', data).then(
    (response) => response.data
  );
};

export const deletePricingHourRelation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/zone-pricing-hourly/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const getZonePricingData = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/zone-pricing-dropdown-data';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const calculatePriceViaZonePricing = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/get-prices-by-coordinates';

  let queryString = '?';
  if (data) {
    if (data.from_lat) {
      queryString += `from_lat=${data.from_lat}`;
    }
    if (data.from_lng) {
      queryString += `&from_lng=${data.from_lng}`;
    }
    if (data.to_lat) {
      queryString += `&to_lat=${data.to_lat}`;
    }
    if (data.to_lng) {
      queryString += `&to_lng=${data.to_lng}`;
    }
    if (data.currency_id) {
      queryString += `&currency_id=${data.currency_id}`;
    }
    if (data.vehicle_class_id) {
      queryString += `&vehicle_class_id=${data.vehicle_class_id}`;
    }
  }

  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const calculatePriceViaZonePricingCustomer = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/customer/get-prices-by-coordinates';

  let queryString = '?';
  if (data) {
    if (data.from_lat) {
      queryString += `from_lat=${data.from_lat}`;
    }
    if (data.from_lng) {
      queryString += `&from_lng=${data.from_lng}`;
    }
    if (data.to_lat) {
      queryString += `&to_lat=${data.to_lat}`;
    }
    if (data.to_lng) {
      queryString += `&to_lng=${data.to_lng}`;
    }
    if (data.currency_id) {
      queryString += `&currency_id=${data.currency_id}`;
    }
    if (data.vehicle_class_id) {
      queryString += `&vehicle_class_id=${data.vehicle_class_id}`;
    }
  }

  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getDataByPostalCode = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/data-by-postal-code';

  let queryString = '?';
  if (data) {
    if (data.postal_code) {
      queryString += `postal_code=${data.postal_code}`;
    }
    if (data.country_code) {
      queryString += `&country_code=${data.country_code}`;
    }
  }

  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};
