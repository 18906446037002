export default {
  sidebar: {
    fleet: 'Flotte',
    vehicles: 'Fahrzeuge',
    vehiclesClasses: 'Fahrzeugklassen',
    administration: 'Verwaltung',
    currencies: 'Währungen',
    currenciesAdministration: 'Währungsverwaltung',
    paymentTypes: 'Zahlungsmethoden',
    paymentTypesAdministration: 'Verwaltung der Zahlungsmethoden',
    users: 'Benutzer',
    usersAdministration: 'Benutzerverwaltung',
    clients: 'Kunden',
    clientsAdministration: 'Kundenverwaltung',
    organizations: 'Organisationen',
    organizationsAdministration: 'Organisationsverwaltung',
    saasAdmin: 'Saas-Admin',
    administrators: 'Administratoren',
    saasAdminAdministration: 'Saas-Admin-Verwaltung',
    statistics: 'Statistiken',
    generalOverview: 'Allgemeiner Bericht',
    payment: 'Zahlung',
    rebates: 'Rabatte',
    pricingRegions: 'Preisregionen',
    pricingRegionsAdministration: 'Preisregionen Verwaltung',
    help: 'Hilfe',
    drivings: 'Fahrtaufträge',
    calendar: 'Kalender',
    tabularView: 'Tabellarische Ansicht',
    expenses: 'Kosten',
    vehicleExpensesAdministration: 'Verwaltung der Fahrzeugkosten',
    vehicleMaintenance: 'Fahrzeugstörungen',
    vehicleMaintenanceAdministration: 'Verwaltung der Fahrzeugstörungen',
    organization: 'Organisation',
    organizationAdminstration: 'Organisationsverwaltung',
    packages: 'Pakete',
    packagesChange: 'Paketwechsel',
    logout: 'Abmelden',
    logoutText: 'Sind Sie sicher, dass Sie sich abmelden möchten?',
    unaccessibleRoutes: 'Sie können auf diese Seite nicht zugreifen. Erweitern Sie das Paket für vollen Zugang.',
    transactions: 'Transaktionen',
    gpsMap: 'GPS-Karte',
    team: 'Team',
    teamAdministration: 'Teamverwaltung',
    invoices: 'Rechnungen',
    invoicesAdministration: 'Rechnungsverwaltung',
    drivingOffers: 'Angebote',
    drivingOffersAdministration: 'Verwaltung der Angebote',
    dashboard: 'Dashboard',
    dashboardAdministration: 'Dashboard-Verwaltung',
    resourceOverview: 'Ressourcenübersicht',
    resourceOverviewAdministration: 'Ressourcenverwaltungsübersicht',
    rides: 'Fahrten',
    passengerReviews: 'Passagierbewertungen',
    passengerReviewsAdministration: 'Passagierbewertungsverwaltung',
    pricingAndRates: 'Preise und Sätze',
    pricingZones: 'Zonen und Bereiche',
    pricingZonesAdministration: 'Verwaltung der Zonen und Bereiche',
    pricingPerZones: 'Preise nach Zonen',
    pricingPerZonesAdministration: 'Verwaltung der Preise nach Zonen',
    pricingPerHour: 'Stundensätze',
    pricingPerHourAdministration: 'Verwaltung der Stundensätze',
    availability: 'Verfügbarkeit',
    availabilityAdministration: 'Verfügbarkeit von Fahrern und Fahrzeugen',
  },
  header: {
    organization: 'Organisation',
    newDriving: 'Fahrauftrag',
    dailyRent: 'Tagesmiete',
    transfer: 'Transfer',
    notifications: 'Benachrichtigungen',
    changeLanguage: 'Sprache ändern',
    help: 'Hilfe',
  },
  footer: {
    poweredBy: 'Bereitgestellt von',
    subscriptionTo: 'Ihr Abonnement zu',
    trialEndsAt: 'Din prøveperiode for',
    packageExpiresIn: 'Paket läuft ab in',
    clickToChangePackage: 'Klicken Sie, um das Paket zu wechseln',
    clickToActivatePackage: 'Klicken Sie, um das Paket zu aktivieren',
    clickToPay: 'Klicken Sie für die Zahlung',
    packageHasExpired: 'Paket ist abgelaufen',
    affiliateUser:
      'Wenn Sie alle Funktionen der Plattform freischalten möchten, müssen Sie ein Abonnement abschließen ',
    here: 'HIER',
  },
  buttons: {
    ok: 'Ok',
    yes: 'Ja',
    no: 'Nein',
    save: 'Speichern',
    send: 'Senden',
    sendMessage: 'Nachricht senden',
    edit: 'Bearbeiten',
    add: 'Hinzufügen',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    cancelDriving: 'Fahrt abbrechen',
    markAsDone: 'Als erledigt markieren',
    markAsNoShow: 'Als Nichterscheinen markieren',
    deleteDriving: 'Fahrt löschen',
    newOrganisation: 'Neue Organisation',
    changePackage: 'Paketwechsel',
    downloadStats: 'Statistik herunterladen',
    downloadPDF: 'PDF herunterladen',
    today: 'Heute',
    choose: 'Auswählen',
    login: 'Einloggen',
    generateFile: 'Vertrag erstellen',
    generateDrivingOrder: 'Fahrauftrag drucken',
    downloadFile: 'Datei herunterladen',
    activate: 'Aktivieren',
    deactivate: 'Deaktivieren',
    showImage: 'Bild anzeigen',
    showDetails: 'Details anzeigen',
    actions: 'Aktionen',
    delete: 'Löschen',
    open: 'Öffnen',
    show: 'Anzeigen',
    options: 'Optionen',
    prev: 'Zurück',
    next: 'Weiter',
    skip: 'Überspringen',
    finish: 'Beenden',
    settings: 'Einstellungen',
    cancelSubscription: 'Abonnement kündigen',
    backToEditOrganization: 'Zurück zur Bearbeitung der Organisation',
    payNow: 'Jetzt bezahlen',
    back: 'Zurück',
    integrationRoutes: 'Integrationsrouten',
    transactions: 'Transaktionen',
    articlesOfLaw: 'Gesetzesartikel',
    export: 'Exportieren',
    duplicate: 'Eine Kopie erstellen',
    makeReturnTrip: 'Eine Rückreise machen',
    selectOrganisation: 'Organisation auswählen',
    advancedSettings: 'Erweiterte Einstellungen',
    accept: 'Akzeptieren',
    reject: 'Ablehnen',
    sendViaGnet: 'Über GNET senden',
    printInvoice: 'Rechnung drucken',
    enable: 'Aktivieren',
    disable: 'Deaktivieren',
    generatePaymentLink: 'Zahlungslink generieren',
    addItem: '+ Artikel hinzufügen',
    visitWebsite: 'Besuchen Sie unsere Webseite',
    buyMoreSms: 'Kaufen Sie mehr SMS',
    forward: 'Weiterleiten',
    sendViaAffiliate: 'Über Affiliate senden',
    markInvoiceAsPaid: 'Rechnung als bezahlt markieren',
    markDrivingOfferAsPaid: 'Fahrtangebot als bezahlt markieren',
    selectAll: 'Alles auswählen',
    viaAffiliateId: 'Hinzufügen über Affiliate-ID',
    registerPartner: 'Partner registrieren',
    createNew: 'Neu erstellen',
    clone: 'Klonen',
    cloneExisting: 'Vorhandenes klonen',
    copyLink: 'Link kopieren',
    markAsStarted: 'Als gestartet markieren',
  },
  tooltips: {
    save: 'Speichern',
    edit: 'Bearbeiten',
    delete: 'Löschen',
    showImage: 'Bild anzeigen',
    open: 'Öffnen',
    show: 'Anzeigen',
    showDetails: 'Details anzeigen ',
    downloadFile: 'Datei herunterladen',
    generateFile: 'Vertrag erstellen',
    generateDrivingOrder: 'Fahrauftrag drucken',
    activate: 'Aktivieren',
    deactivate: 'Deaktivieren',
    cancel: 'Abbrechen',
    cancelDriving: 'Fahrt abbrechen',
    cannotMarkAsCanceled: 'Die Bestellung kann nicht als storniert markiert werden, da sie bereits angenommen wurde',
    markAsDone: 'Als erledigt markieren',
    cannotMarkAsDone: 'Der Auftrag kann nicht als abgeschlossen markiert werden, da er noch nicht stattgefunden hat',
    markAsNoShow: 'Als Nichterscheinen markieren',
    cannotMarkAsNoShow: 'Die Bestellung kann nicht als Nichterscheinen markiert werden, da sie noch nicht erfolgt ist',
    deleteDriving: 'Fahrt löschen',
    cannotDelete:
      'Wir entschuldigen uns, aber aus Sicherheitsgründen können Benutzer nicht gelöscht werden, solange sie angemeldet sind.',
    duplicate: 'Eine Kopie erstellen',
    makeReturnTrip: 'Eine Rückreise machen',
    refresh: 'Aktualisieren',
    selectOrganisation: 'Organisation auswählen',
    sendViaGnet: 'Über GNET senden',
    receivedViaGnet: 'Über GNET empfangen',
    sentViaGnet: 'Über GNET gesendet',
    printInvoice: 'Rechnung drucken',
    optionsDisabled: 'Sie müssen die Bestellung ablehnen oder akzeptieren, um das Optionsmenü zu öffnen',
    cannotMakeReturnTrip: 'Sie können keine Rückfahrt für die Tagesmiete machen',
    cannotSendGnetOrderViaGnet: 'Sie können die Gnet-Bestellung nicht über Gnet senden',
    cannotSendViaGnetWithoutGnetId: 'Sie können die Bestellung nicht ohne Gnet-ID über Gnet senden',
    cannotPrintInvoiceWithoutClient: 'Sie können keine Rechnung drucken, ohne einen Kunden auszuwählen',
    cannotMarkAsDoneStatusRejected:
      'Die Bestellung kann nicht als abgeschlossen markiert werden, da sie bereits als abgelehnt markiert wurde',
    cannotMarkAsDoneStatusCanceled:
      'Die Bestellung kann nicht als abgeschlossen markiert werden, da sie bereits als storniert markiert wurde',
    cannotMarkAsDoneStatusDone:
      'Die Bestellung kann nicht als abgeschlossen markiert werden, da sie bereits als abgeschlossen markiert wurde',
    cannotMarkAsDoneStatusNoShow:
      'Die Bestellung kann nicht als abgeschlossen markiert werden, da sie bereits als nicht erschienen markiert wurde',
    cannotMarkAsNoShowStatusRejected:
      'Die Bestellung kann nicht als nicht erschienen markiert werden, da sie bereits als abgelehnt markiert wurde',
    cannotMarkAsNoShowStatusCanceled:
      'Die Bestellung kann nicht als nicht erschienen markiert werden, da sie bereits als storniert markiert wurde',
    cannotMarkAsNoShowStatusDone:
      'Die Bestellung kann nicht als nicht erschienen markiert werden, da sie bereits als abgeschlossen markiert wurde',
    cannotMarkAsNoShowStatusNoShow:
      'Die Bestellung kann nicht als nicht erschienen markiert werden, da sie bereits als nicht erschienen markiert wurde',
    cannotMarkAsCanceledGnet:
      'Die Bestellung kann nicht als storniert markiert werden, da sie über Gnet empfangen wurde',
    cannotMarkAsCanceledStatusRejected:
      'Die Bestellung kann nicht als storniert markiert werden, da sie bereits als abgelehnt markiert wurde',
    cannotMarkAsCanceledStatusExpired:
      'Die Bestellung kann nicht als storniert markiert werden, da sie bereits als abgelaufen markiert wurde',
    cannotMarkAsCanceledStatusCanceled:
      'Die Bestellung kann nicht als storniert markiert werden, da sie bereits als storniert markiert wurde',
    cannotMarkAsCanceledStatusDraft:
      'Die Bestellung kann nicht als storniert markiert werden, da sie bereits als Entwurf markiert wurde',
    cannotMarkAsCanceledStatusCompleted:
      'Die Bestellung kann nicht als storniert markiert werden, da sie bereits als abgeschlossen markiert wurde',
    cannotMarkAsCanceledStatusNoShow:
      'Die Bestellung kann nicht als storniert markiert werden, da sie bereits als nicht erschienen markiert wurde',
    cannotDeleteDrivingSentViaGnet: 'Die Bestellung kann nicht gelöscht werden, da sie über Gnet gesendet wurde',
    cannotDeleteDrivingReceivedViaGnet:
      'Die Bestellung kann nicht gelöscht werden, da sie über Gnet empfangen und akzeptiert wurde',
    generatePaymentLink: 'Zahlungslink generieren',
    cannotGeneratePaymentLInk: 'Zahlungslink kann nicht generiert werden, da Sie Stripe nicht aktiviert haben',
    disabledExport: 'Sie können Daten einmal in 24 Stunden exportieren',
    sendSms: 'SMS senden',
    accept: 'Akzeptieren',
    reject: 'Ablehnen',
    sendViaAffiliate: 'Über Affiliate senden',
    receivedViaAffiliate: 'Erhalten über Affiliate',
    sentViaAffiliate: 'Gesendet über Affiliate',
    cannotSendViaAffiliate: 'Sie können den Fahrtauftrag nicht über den Affiliate senden.',
    cannotForwardDrivingOrder: 'Sie können einen vergangenen Fahrtauftrag nicht weiterleiten.',
    cannotMarkAsCanceledAffiliate:
      'Der Fahrtauftrag kann nicht als storniert markiert werden, da er über den Affiliate empfangen wurde.',
    cannotMarkAsCanceled12Hours:
      'Der Auftrag kann nicht als storniert markiert werden, da 12 Stunden seit der Abholzeit vergangen sind.',
    cannotDeleteActiveDriving: 'Der aktive Fahrtauftrag kann nicht gelöscht werden.',
    noImageToShow: 'Kein Bild zum Anzeigen',
    disabledForAffiliateUser:
      "Ihr Konto ist vom Typ 'Affiliate'. Diese Aktion ist für abonnierte [Reguläre] Konten verfügbar. Gehen Sie zum Link im Footer, um ein Abonnement abzuschließen.",
    markInvoiceAsPaid: 'Rechnung als bezahlt markieren',
    markDrivingOfferAsPaid: 'Fahrtangebot als bezahlt markieren',
    invoiceIsPaid: 'Rechnung ist bereits als bezahlt markiert',
    drivingOfferIsPaid: 'Fahrtangebot ist bereits als bezahlt markiert',
    selectAreaToDelete: 'Wählen Sie den Bereich, den Sie löschen möchten',
    deleteSelectedArea: 'Ausgewählten Bereich löschen',
    markAsStarted: 'Als gestartet markieren',
    cannotMarkAsStarted: 'Die Bestellung kann nicht als gestartet markiert werden, da sie noch nicht stattgefunden hat',
    cannotMarkAsStartedStatusStarted:
      'Die Bestellung kann nicht als gestartet markiert werden, da sie bereits als gestartet markiert wurde',
    cannotMarkAsStartedStatusRejected:
      'Die Bestellung kann nicht als gestartet markiert werden, da sie bereits als abgelehnt markiert wurde',
    cannotMarkAsStartedStatusCanceled:
      'Die Bestellung kann nicht als gestartet markiert werden, da sie bereits als storniert markiert wurde',
    cannotMarkAsStartedStatusDone:
      'Die Bestellung kann nicht als gestartet markiert werden, da sie bereits als abgeschlossen markiert wurde',
    cannotMarkAsStartedStatusNoShow:
      'Die Bestellung kann nicht als gestartet markiert werden, da sie bereits als "nicht erschienen" markiert wurde',
  },
  snackbar: {
    close: 'Schließen',
    savedSuccessfully: 'Erfolgreich gespeichert',
    excelDownloadedSuccessfully: 'Erfolgreich heruntergeladen',
    deletedSuccessfully: 'Erfolgreich gelöscht',
    sentSuccessfully: 'Erfolgreich gesendet',
    statusChanged: 'Status erfolgreich geändert',
    activeStatusChanged: 'Aktiver Status erfolgreich geändert',
    defaultStatusChanged: 'Standardstatus erfolgreich geändert',
    primaryStatusChanged: 'Primärstatus erfolgreich geändert',
    anErrorOccured: 'Ein Fehler ist aufgetreten, bitte melden Sie sich beim Support',
    loadingCommentsFailed: 'Laden von Kommentaren fehlgeschlagen',
    drivingCanceled: 'Fahrauftrag erfolgreich abgebrochen',
    drivingFinished: 'Fahrauftrag erfolgreich abgeschlossen',
    drivingStarted: 'Fahrauftrag wurde erfolgreich als gestartet markiert',
    drivingNoShow: 'Fahrtbestellung wurde erfolgreich als Nichterscheinen markiert.',
    drivingDeleted: 'die fahrerlaubnis wurde erfolgreich gelöscht',
    drivingAccepted: 'Fahrauftrag wurde erfolgreich angenommen',
    drivingRejected: 'Fahrauftrag wurde erfolgreich abgelehnt',
    planChanged: 'Plan erfolgreich geändert',
    planChangeFailed: 'Planänderung nicht möglich',
    saveChangesFirst: 'Zuerst die zuvor gemachten Änderungen speichern',
    makeRoutesPublic: 'Routen sind öffentlich zugänglich',
    makeRoutesNotPublic: 'Routen sind nicht mehr öffentlich zugänglich',
    makePublicLinkActive: 'Öffentlicher Link ist jetzt verfügbar',
    makePublicLinkInactive: 'Öffentlicher Link ist nicht mehr verfügbar',
    integrationKeyCopied: 'Integrationsschlüssel kopiert',
    copyToken: 'Token manuell kopieren',
    publicLinkCopied: 'Öffentlicher Link kopiert',
    copyPublicLink: 'Kopieren Sie den öffentlichen Link manuell',
    organisationChanged: 'Unternehmen erfolgreich geändert',
    changeFailed: 'Änderung fehlgeschlagen, bitte erneut versuchen',
    loginFailed: 'Benutzername oder Passwort ist falsch',
    organisationSuspended: 'Ihre Organisation ist gesperrt',
    userEmailDoesNotExists: 'Benutzer mit dieser E-Mail existiert nicht',
    canceledSubscrSuccessfully: 'Abonnement erfolgreich gekündigt',
    toManyRequests: 'Zu viele Anfragen. Versuchen Sie es in einer Minute erneut.',
    visibleStatusChanged: 'Sichtbarer Status erfolgreich geändert',
    noInternetConnection: 'Kein Internet. Bitte überprüfen Sie Ihre Internetverbindung.',
    backOnline: 'Sie sind wieder online.',
    driverNotifiedToTurnGpsOn: 'Fahrer wurde benachrichtigt, das GPS einzuschalten',
    savedSuccessfullyWithGnetError: 'GNET-Synchronisierung fehlgeschlagen, Fahrtbestellung erfolgreich gespeichert.',
    logoutFromAnotherDevice: 'Sie wurden von einem anderen Gerät abgemeldet.',
    stripeEnabled: 'Stripe erfolgreich aktiviert',
    stripeDisabled: 'Stripe erfolgreich deaktiviert',
    finishStripeConnect: 'Bitte schließen Sie Ihre Stripe-Einrichtung ab',
    requestSentSuccessfully: 'Anfrage erfolgreich gesendet',
    pricingZoneNoMatch: 'Wir können keine entsprechenden Preiszonen finden, um den Preis zu berechnen.',
    areaAlreadyExists: 'Dieser Bereich existiert bereits',
    noDataForThisPostalCode: 'Keine Daten für diese Postleitzahl verfügbar',
    affiliateRequestSentSuccessfully: 'Organisation existiert bereits, Affiliate-Anfrage wurde gesendet',
    organizationRegisteredSuccessfully: 'Organisation erfolgreich registriert',
    organizationIsAlreadyAPartner: 'Organisation ist bereits ein Partner',
    recordAlreadyExists: 'Dieser Eintrag existiert bereits.',
  },
  filters: {
    filter: 'Filter',
    currentYear: 'Aktuelles Jahr',
    lastYear: 'Letztes Jahr',
    lastQuarter: 'Letztes Quartal',
    lastThreeYears: 'Letzte drei Jahre',
    filterByDriver: 'Nach Fahrer filtern',
    filterByVehicle: 'Nach Fahrzeug filtern',
    filterByClient: 'Nach Kunden filtern',
    day: 'Tag',
    week: 'Woche',
    month: 'Monat',
    vehicle: 'Fahrzeug',
    driving: 'Fahren',
    user: 'Benutzer',
    fromDate: 'Vom Datum',
    toDate: 'Bis Datum',
    threeMonths: 'Drei Monate',
    halfYear: 'Halbes Jahr',
    year: 'Jahr',
  },
  months: {
    jan: 'Januar',
    feb: 'Februar',
    mar: 'März',
    apr: 'April',
    may: 'Mai',
    jun: 'Juni',
    jul: 'Juli',
    aug: 'August',
    sep: 'September',
    oct: 'Oktober',
    nov: 'November',
    dec: 'Dezember',
    January: 'Januar',
    February: 'Februar',
    March: 'März',
    April: 'April',
    May: 'Mai',
    June: 'Juni',
    July: 'Juli',
    August: 'August',
    September: 'September',
    October: 'Oktober',
    November: 'November',
    December: 'Dezember',
  },
  usersPageHeaders: {
    firstName: 'Vorname',
    lastName: 'Nachname',
    username: 'Benutzername',
    email: 'E-Mail',
    phoneNumber: 'Telefonnummer',
    role: 'Rolle',
    active: 'Aktiv',
    actions: 'Aktionen',
  },
  usersRoles: {
    masterAdmin: 'Hauptadministrator',
    manager: 'Manager',
    driver: 'Fahrer',
    operator: 'Operator',
    externalAssociate: 'Externer Mitarbeiter',
    sales: 'Vertrieb',
    salesLead: 'Vertriebsleiter',
    gnet: 'GNET',
    customer: 'Portalbenutzer',
    publicUser: 'Öffentlicher Benutzer',
  },
  masterUsersPageHeaders: {
    firstName: 'Vorname',
    lastName: 'Nachname',
    username: 'Benutzername',
    email: 'E-Mail',
    phoneNumber: 'Telefonnummer',
    role: 'Rolle',
    organisation: 'Organisation',
    active: 'Aktiv',
    actions: 'Aktionen',
  },
  usersPage: {
    newItem: 'Neuer Benutzereintrag',
    editItem: 'Benutzer bearbeiten',
    id: 'Id',
    firstName: 'Vorname',
    lastName: 'Nachname',
    username: 'Benutzername',
    password: 'Passwort',
    phoneNumber: 'Telefonnummer',
    role: 'Rolle',
    client: 'Klient',
    email: 'E-Mail',
    organisation: 'Organisation',
    percentage: 'Prozentsatz',
    externalAssociate: 'Externer Mitarbeiter',
    image: 'Bild',
    deleteUser: 'Benutzer löschen',
    deleteMessage: 'Sind Sie sicher, dass Sie den Benutzer löschen möchten ',
    emailChanged: 'E-Mail geändert',
    verificationEmailSent:
      'Die Anfrage zur Änderung der E-Mail-Adresse wurde gesendet. Um den Prozess abzuschließen und die Sicherheit des Benutzerkontos zu gewährleisten, muss die neue E-Mail-Adresse durch Klicken auf den Verifizierungslink in der neuen E-Mail verifiziert werden. Die E-Mail-Adresse wird nicht geändert, bis sie erfolgreich verifiziert wurde.',
    passengersInfoAvailableToDriver: 'Passagierinformationen für den Fahrer verfügbar',
    colorPicker:
      'Sie können eine einzigartige Farbe für jeden Benutzer auswählen. Diese wird auf der Verfügbarkeitsseite zur einfacheren Identifizierung verwendet.',
  },
  clientsPageHeaders: {
    name: 'Name',
    phoneNumber: 'Telefonnummer',
    address: 'Adresse',
    email: 'E-Mail',
    companyId: 'Firmen-ID/Personalausweis',
    clientType: 'Kundentyp',
    percentage: 'Prozentsatz',
    active: 'Aktiv',
    actions: 'Aktionen',
  },
  clientsPage: {
    newItem: 'Neuer Kundeneintrag',
    editItem: 'Kunden bearbeiten',
    id: 'Id',
    name: 'Name',
    address: 'Adresse',
    phoneNumber: 'Telefonnummer',
    email: 'E-Mail',
    companyId: 'Firmen-ID/Personalausweis',
    clientType: 'Kundentyp',
    individual: 'Einzelperson',
    business: 'Geschäftseinheit',
    percentage: 'Prozentsatz',
    deleteClient: 'Kunden löschen',
    deleteMessage: 'Sind Sie sicher, dass Sie den Kunden löschen möchten ',
    tabs: {
      general: 'Allgemein',
      transfers: 'Überweisungen',
      users: 'Benutzer',
      invoices: 'Rechnungen',
      cards: 'Karten',
    },
  },
  drivingsHeaders: {
    drivingNumber: 'Fahrtauftragsnummer',
    drivingType: 'Buchungstyp',
    drivingStatus: 'Status',
    locationFrom: 'Startpunkt',
    locationTo: 'Ziel',
    vehicleClass: 'Fahrzeugklasse',
    vehicle: 'Fahrzeug',
    driversName: 'Fahrer',
    startTime: 'Abholzeit',
    actions: 'Aktionen',
    exportExcel: 'Excel exportieren',
    price: 'Preis',
    totalPrice: 'Gesamtpreis',
    confirmed: 'Bestätigt',
    paid: 'Bezahlt',
    numberOfDrivings: 'Anzahl der Fahrtenbestellungen',
  },
  vehiclesHeaders: {
    plateNumber: 'Kennzeichen',
    brand: 'Fahrzeugmarke',
    vehicleClass: 'Fahrzeugklasse',
    passengersNumber: 'Anzahl der Passagiere',
    fuelConsumption: 'Kraftstoffverbrauch',
    yearOfManufacture: 'Baujahr',
    pricePerKm: 'Preis pro km',
    pricePerMi: 'Preis pro Meile',
    pricePerHour: 'Preis pro Stunde',
    pricePerWaitingHour: 'Preis pro Wartezeit',
    active: 'Aktiv',
    actions: 'Aktionen',
  },
  vehiclesPage: {
    id: 'Id',
    newItem: 'Neuer Fahrzeugeintrag',
    editItem: 'Fahrzeug ändern',
    vehiclePlates: 'Kennzeichen',
    vehicleBrand: 'Fahrzeugmarke',
    vehicleClass: 'Fahrzeugklasse',
    passengersNumber: 'Anzahl der Passagiere',
    fuelConsumption: 'Kraftstoffverbrauch (100km)',
    fuelConsumptionMi: 'Kraftstoffverbrauch des Autos (100 Meilen)',
    manufactureYear: 'Baujahr',
    price: 'Preis pro km',
    priceMi: 'Preis pro Meile',
    hourPrice: 'Preis pro Stunde',
    image: 'Bild',
    hourWaitingPrice: 'Preis pro Wartezeit',
    deleteMessage: 'Sind Sie sicher, dass Sie dieses Fahrzeug löschen möchten?',
    tabs: {
      general: 'Allgemein',
      transfers: 'Transfers',
      costs: 'Kosten',
      damages: 'Schäden',
    },
    licence: 'Lizenznummer',
    color: 'Farbe',
    colorPicker:
      'Sie können eine einzigartige Farbe für jeden Fahrzeugeintrag auswählen. Diese wird auf der Verfügbarkeitsseite zur einfacheren Identifizierung verwendet.',
  },
  vehicleClassesHeaders: {
    vehicleClass: 'Fahrzeugklasse',
    numberOfPassengers: 'Anzahl der Passagiere',
    numberOfSuitcases: 'Anzahl der Koffer',
    pricePerKm: 'Preis pro km',
    pricePerMi: 'Preis pro Meile',
    pricePerHour: 'Preis pro Stunde',
    pricePerWaitingHour: 'Preis pro Wartezeit',
    availableForPublicBookingForm: 'Verfügbar für öffentliches Buchungsformular',
    active: 'Aktiv',
    actions: 'Aktionen',
  },
  vehicleClassesPage: {
    newItem: 'Neuer Eintrag für Fahrzeugklasse',
    editItem: 'Fahrzeugklasse ändern',
    id: 'Id',
    vehicleClass: 'Fahrzeugklasse',
    numberOfPassengers: 'Anzahl der Passagiere',
    numberOfSuitcases: 'Anzahl der Koffer',
    availableForPublicBookingForm: 'Verfügbar für öffentliches Buchungsformular',
    price: 'Preis pro km',
    priceMi: 'Preis pro Meile',
    hourPrice: 'Preis pro Stunde',
    image: 'Bild',
    hourWaitingPrice: 'Preis pro Wartezeit',
    deleteMessage: 'Sind Sie sicher, dass Sie diese Fahrzeugklasse löschen möchten?',
  },
  expensesHeaders: {
    name: 'Name',
    vehicle: 'Fahrzeug',
    type: 'Typ',
    description: 'Beschreibung',
    price: 'Preis',
    expenseDate: 'Datum',
    actions: 'Aktion',
    driving: 'Fahren',
    exportExcel: 'Excel exportieren',
  },
  vehicleExpensesTypes: {
    minorService: 'Kleiner Service',
    majorService: 'Großer Service',
    fuel: 'Kraftstoff',
    tires: 'Reifen',
    parking: 'Parken',
    other: 'Andere',
  },
  expensesPage: {
    newItem: 'Neuer Kosteneintrag',
    editItem: 'Kostenänderung',
    id: 'Id',
    name: 'Name',
    vehicle: 'Fahrzeug',
    costType: 'Kostentyp',
    price: 'Preis',
    currency: 'Währung',
    costTime: 'Kostenzeitpunkt',
    description: 'Beschreibung',
    deleteMessage: 'Sind Sie sicher, dass Sie diese Kosten löschen möchten?',
    costTypes: {
      'Mali servis': 'Kleiner Service',
      'Veliki servis': 'Großer Service',
      Gorivo: 'Kraftstoff',
      Gume: 'Reifen',
      Parking: 'Parken',
      Ostalo: 'Andere',
    },
  },
  vehicleMaintenanceHeaders: {
    name: 'Fahrzeug',
    details: 'Details',
    dateFrom: 'Datum von',
    dateTo: 'Datum bis',
    actions: 'Aktionen',
  },
  vehicleMaintenancePage: {
    newItem: 'Neuer Eintrag für Fahrzeugausfall',
    editItem: 'Fahrzeugausfall ändern',
    id: 'Id',
    vehicle: 'Fahrzeug',
    description: 'Beschreibung',
    dateFrom: 'Von',
    dateTo: 'Bis',
    deleteMessage: 'Sind Sie sicher, dass Sie diesen Fahrzeugausfall löschen möchten?',
  },
  rebatesHeaders: {
    min: 'Mindestanzahl der Fahraufträge',
    max: 'Höchstanzahl der Fahraufträge',
    percentage: 'Rabatt (%)',
    actions: 'Aktionen',
  },
  rebatesPage: {
    newItem: 'Neuer Eintrag für Rabatt',
    editItem: 'Rabatt ändern',
    rebateMin: 'Mindestanzahl der Fahraufträge',
    rebateMax: 'Höchstanzahl der Fahraufträge',
    rebate: 'Rabatt (%)',
    deleteRebate: 'Rabatt löschen',
    deleteMessage: 'Sind Sie sicher, dass Sie diesen Rabatt löschen möchten?',
  },
  customerCardsHeaders: {
    cardholderName: 'Name des Karteninhabers',
    cardNumber: 'Kartennummer',
    expiryDate: 'Ablaufdatum',
    cvv: 'CVV',
    actions: 'Aktionen',
  },
  customerCardsPage: {
    newItem: 'Neuer Karteneintrag',
    editItem: 'Kartenänderung',
    cardholderName: 'Name des Karteninhabers',
    cardNumber: 'Kartennummer',
    expiryDate: 'Ablaufdatum',
    cvv: 'CVV',
    deleteCard: 'Karte löschen',
    deleteMessage: 'Sind Sie sicher, dass Sie diese Karte löschen möchten?',
  },
  pricingRegionsHeaders: {
    name: 'Name',
    actions: 'Aktionen',
  },
  pricingRegionsPage: {
    newItem: 'Neuer Eintrag für Preisregion',
    editItem: 'Änderung der Preisregion',
    name: 'Name',
    chooseCurrency: 'Währung auswählen',
    soloPrice: 'Solo Preis - Jahr',
    soloPaddlePriceId: 'Solo Paddle-Preis-ID - Jahr',
    team5Price: 'Team 5 Preis - Jahr',
    team5PaddlePriceId: 'Team 5 Paddle-Preis-ID - Jahr',
    team10Price: 'Team 10 Preis - Jahr',
    team10PaddlePriceId: 'Team 10 Paddle-Preis-ID - Jahr',
    team25Price: 'Team 25 Preis - Jahr',
    team25PaddlePriceId: 'Team 25 Paddle-Preis-ID - Jahr',
    team50Price: 'Team 50 Preis - Jahr',
    team50PaddlePriceId: 'Team 50 Paddle-Preis-ID - Jahr',
    soloPriceMonthly: 'Solo Preis - Monat',
    soloPaddlePriceIdMonthly: 'Solo Paddle-Preis-ID - Monat',
    team5PriceMonthly: 'Team 5 Preis - Monat',
    team5PaddlePriceIdMonthly: 'Team 5 Paddle-Preis-ID - Monat',
    team10PriceMonthly: 'Team 10 Preis - Monat',
    team10PaddlePriceIdMonthly: 'Team 10 Paddle-Preis-ID - Monat',
    team25PriceMonthly: 'Team 25 Preis - Monat',
    team25PaddlePriceIdMonthly: 'Team 25 Paddle-Preis-ID - Monat',
    team50PriceMonthly: 'Team 50 Preis - Monat',
    team50PaddlePriceIdMonthly: 'Team 50 Paddle-Preis-ID - Monat',
    chooseCountries: 'Länder auswählen',
    description: 'Beschreibung',
    deletePricingRegion: 'Preisregion löschen',
    deleteMessage: 'Möchten Sie diese Preisregion wirklich löschen?',
  },
  notificationsHeaders: {
    time: 'Zeit',
    notification: 'Benachrichtigung',
  },
  organisationsHeaders: {
    name: 'Organisation',
    phoneNumber: 'Telefonnummer',
    email: 'E-Mail',
    address: 'Adresse',
    companyId: 'Firmen-ID',
    vat: 'Umsatzsteuer-ID',
    active: 'Aktiv',
    createdAt: 'Registriert',
    actions: 'Aktionen',
    billingDate: 'Abrechnungsdatum',
  },
  organisationsPage: {
    newItem: 'Neuer Benutzereintrag',
    editItem: 'Benutzer bearbeiten',
    id: 'Id',
    name: 'Organisation',
    address: 'Adresse',
    phoneNumber: 'Telefonnummer',
    email: 'E-Mail',
    vat: 'Umsatzsteuer-ID',
    companyId: 'Firmen-ID',
    warrantNumber: 'Entscheidungsnummer',
    image: 'Bild',
    signature: 'Unterschrift',
    seal: 'Stempel',
    deactivateMessage: 'Sind Sie sicher, dass Sie ',
    deactivate: 'DEAKTIVIEREN',
    activate: 'AKTIVIEREN',
    organisation: 'Organisation',
    deleteOrganisation: 'Organisation löschen',
    deleteMessage: 'Sind Sie sicher, dass Sie diese Organisation löschen möchten?',
    cancelSubscrMessage:
      'Sind Sie sicher, dass Sie Ihr Abonnement kündigen möchten? Bitte beachten Sie, dass durch Bestätigung dieser Aktion: 1. Ihr Organisationskonto und alle zugehörigen Daten dauerhaft gelöscht werden. Diese Aktion kann nicht rückgängig gemacht werden. 2. Sie werden unmittelbar nach der Kündigung automatisch aus dem System abgemeldet. 3. Sie werden in Zukunft keinen Zugang mehr haben, um sich anzumelden oder irgendwelche Daten, die mit Ihrer Organisation zusammenhängen, abzurufen. Wir empfehlen dringend, alle notwendigen Informationen zu überprüfen oder wichtige Daten zu exportieren, bevor Sie mit dieser unwiderruflichen Aktion fortfahren. Wenn Sie Fragen haben oder Hilfe benötigen, zögern Sie bitte nicht, unser Support-Team zu kontaktieren. Möchten Sie mit der Kündigung fortfahren?',
    allDrivings: 'Gesamtanzahl der Fahraufträge',
    allDrivingsAccepted: 'Gesamtanzahl akzeptierter Fahraufträge',
    allDrivingsCanceled: 'Gesamtanzahl abgelehnter Fahraufträge',
    allVehicles: 'Gesamtanzahl der Fahrzeuge',
    allUsers: 'Gesamtanzahl der Benutzer',
    areYouSure: 'Sind Sie sicher?',
    makeRoutesAvailable: 'Integrationsrouten verfügbar machen',
    percentage: 'Prozentsatz',
    deleteImageMessage: 'Möchten Sie dieses Bild wirklich löschen?',
    deleteImageTitle: 'Bild löschen',
    cancelSubscriptionTitle: 'Abonnement kündigen',
    subscriptionType: 'Abonnementtyp',
    billingDate: 'Abrechnungsdatum',
    articlesOfLaw: 'Gesetzesartikel',
    country: 'Land',
    referent: 'Referent',
    language: 'Sprache',
    gnetId: 'GNET-ID',
    affiliateId: 'Affiliate ID',
    trialEndsAt: 'Testzeitraum endet am',
    languageTooltip:
      'Standard-Ersatzsprache für neue Benutzer und die Sprache, in der alle Unternehmens-E-Mails (nicht die E-Mails einzelner Benutzer) gesendet werden.',
    organisationType: 'Organisationstyp',
    regular: 'Regulär',
    affiliateSender: 'Affiliate-Absender',
    affiliateReceiver: 'Affiliate-Empfänger',
    dispatcher: 'Disponent',
  },
  transactionsHeaders: {
    invoiceNumber: 'Rechnungsnummer',
    status: 'Status',
    total: 'Gesamt',
    currency: 'Währung',
    billedAt: 'Abgerechnet am',
    actions: 'Aktionen',
  },
  transactionsPage: {
    newItem: 'Neuer Transaktionseintrag',
    editItem: 'Transaktion bearbeiten',
    id: 'ID',
    invoiceNumber: 'Rechnungsnummer',
    status: 'Status',
    sent: 'Gesendet',
    completed: 'Abgeschlossen',
    total: 'Gesamt',
    currency: 'Währung',
    billedAt: 'Abgerechnet am',
    actions: 'Aktionen',
    deleteTransaction: 'Transaktion löschen',
    deleteMessage: 'Sind Sie sicher, dass Sie diese Transaktion löschen möchten?',
    currentSubscription: 'Aktuelles Paket',
    nextBillingDate: 'Nächstes Abrechnungsdatum',
    numberOfSeatsTaken: 'Anzahl besetzter Teammitglieder',
    numberOfSeatsAvailable: 'Anzahl verfügbarer Teammitglieder',
  },
  currenciesHeaders: {
    name: 'Name',
    code: 'Code',
    symbol: 'Symbol',
    active: 'Aktiv',
    default: 'Primär',
    actions: 'Aktionen',
  },
  currenciesPage: {
    newItem: 'Neuer Währungseintrag',
    editItem: 'Währungsänderung',
    id: 'Id',
    currency: 'Währung',
    code: 'Code',
    symbol: 'Symbol',
    deleteMessage: 'Sind Sie sicher, dass Sie diese Währung löschen möchten?',
  },
  paymentMethodsHeaders: {
    name: 'Name',
    active: 'Aktiv',
    default: 'Primär',
    priceVisible: 'Preis sichtbar',
    actions: 'Aktionen',
  },
  paymentMethodsPage: {
    newItem: 'Neuer Zahlungsmethodeeintrag',
    editItem: 'Änderung der Zahlungsmethode',
    id: 'Id',
    paymentMethod: 'Zahlungsmethode',
    deleteMessage: 'Sind Sie sicher, dass Sie diese Zahlungsmethode löschen möchten?',
  },
  feedbacksHeaders: {
    subject: 'Name',
    organisation: 'Organisation',
    user: 'Benutzer',
    actions: 'Aktionen',
  },
  passengersHeaders: {
    firstName: 'Vorname',
    lastName: 'Nachname',
    phoneNumber: 'Telefonnummer',
    email: 'E-Mail',
    passport: 'Reisepass',
    nationality: 'Nationalität',
    actions: 'Aktionen',
  },
  checkpointsHeaders: {
    checkpointLocation: 'Kontrollpunkt Standort',
    arrivalTime: 'Ankunftszeit',
    actions: 'Aktionen',
  },
  tables: {
    noDataText: 'Keine Daten',
    search: 'Suchen',
    drivings: 'Fahraufträge',
    drivingsByPage: 'Fahraufträge pro Seite',
    vehicles: 'Fahrzeuge',
    vehiclesByPage: 'Fahrzeuge pro Seite',
    vehiclesClasses: 'Fahrzeugklassen',
    vehiclesClassesByPage: 'Fahrzeugklassen pro Seite',
    expenses: 'Kosten',
    expensesByPage: 'Kosten pro Seite',
    vehiclesMaintenance: 'Fahrzeugausfälle',
    vehiclesMaintenanceByPage: 'Fahrzeugausfälle pro Seite',
    reportsByPage: 'Berichte pro Seite',
    currencies: 'Währungen',
    currencieseByPage: 'Währungen pro Seite',
    payments: 'Zahlungsmethoden',
    paymentsByPage: 'Zahlungsmethoden pro Seite',
    saasAdministrators: 'Alle Administratoren',
    users: 'Alle Benutzer',
    usersByPage: 'Benutzer pro Seite',
    clients: 'Alle Kunden',
    clientsByPage: 'Kunden pro Seite',
    organisations: 'Alle Organisationen',
    organisationsByPage: 'Organisationen pro Seite',
    rebates: 'Rabatte',
    rebatesByPage: 'Rabatte pro Seite',
    cards: 'Karten',
    cardsByPage: 'Karten pro Seite',
    transactions: 'Transaktionen',
    transactionsByPage: 'Transaktionen pro Seite',
    pricingRegions: 'Preisregionen',
    pricingRegionsByPage: 'Preisregionen pro Seite',
    feedback: 'Feedbacks',
    feedbacksByPage: 'Feedbacks pro Seite',
    notifications: 'Benachrichtigungen',
    notificationsByPage: 'Benachrichtigungen pro Seite',
    all: 'Alle',
    passengers: 'Passagiere',
    passengersByPage: 'Passagiere pro Seite',
    of: 'von',
    checkpoints: 'Wegpunkte',
    checkpointsByPage: 'Wegpunkte pro Seite',
    invoices: 'Rechnungen',
    invoicesByPage: 'Rechnungen pro Seite',
    drivingOffers: 'Fahrangebote',
    drivingOffersByPage: 'Fahrangebote pro Seite',
    smsMessages: 'SMS-Nachrichten',
    smsMessagesByPage: 'SMS-Nachrichten pro Seite',
    resourcesUsed: 'Verwendete Ressourcen',
    resourcesUsedByPage: 'Ressourcen pro Seite',
    inNext3Days: 'In den nächsten 3 Tagen',
    notConfirmedOrPaid: 'Nicht bestätigt oder bezahlt',
    inNext5Days: 'In den nächsten 5 Tagen',
    latestReviews: 'Neueste Bewertungen',
    passengerReviews: 'Passagierbewertungen',
    driversByPage: 'Fahrer pro Seite',
    reviewsByPage: 'Bewertungen pro Seite',
    partners: 'Partner',
    partnersByPage: 'Partner pro Seite',
    sentRequests: 'Gesendete Anfragen',
    receivedRequests: 'Erhaltene Anfragen',
    requestsByPage: 'Anfragen pro Seite',
    pricingZones: 'Zonen und Bereiche',
    pricingZonesByPage: 'Zonen und Bereiche pro Seite',
    pricingPerZones: 'Preise nach Zonen',
    pricingPerZonesByPage: 'Preise pro Seite',
    pricingPerHour: 'Preise pro Stunde',
    pricingPerHourByPage: 'Preise pro Seite',
  },
  statistics: {
    numberOfAcceptedDrivings: 'Anzahl der angenommenen Fahraufträge',
    numberOfRejectedDrivings: 'Anzahl der abgelehnten Fahraufträge pro Fahrer',
    vehicleDrivingsNumber: 'Anzahl der Fahraufträge pro Fahrzeug',
    vehicleProfit: 'Einnahmen pro Fahrzeug',
    monthDrivingsNumber: 'Anzahl der Fahraufträge pro Monat',
    driverProfit: 'Einnahmen pro Fahrer',
  },
  reportsHeaders: {
    vehicle: 'Fahrzeug',
    expenses: 'Ausgaben',
    profit: 'Profit',
    earnings: 'Einnahmen',
    daysWorking: 'Arbeitstage',
    usageIndex: 'Nutzungsindex',
    client: 'Klient',
    numberOfDrivings: 'Anzahl der Fahrten',
    percentForClient: 'Preis für den Kunden',
    sumOfPrice: 'Menge',
  },
  packages: {
    active: 'Aktiv',
    starterTitle: 'Starter',
    monthlyPackagesMessage: 'Monatliches Abonnement pro Organisation.',
    starterText: 'Ideal für Einzelpersonen oder kleine Teams, die nur grundlegende Funktionen benötigen.',
    starterListItemOne: 'Mobile Anwendungen',
    starterListItemTwo: 'Push-Benachrichtigungen',
    starterListItemThree: 'Zugriff auf die Verwaltung',
    starterListItemFour: 'Website-Integration',
    standardTitle: 'Standard',
    standardText: 'Perfekt für Organisationen mit mehr als 5 Fahrern.',
    standardListItemOne: 'STARTER-Paket enthalten',
    standardListItemTwo: 'Keine Begrenzung der Anzahl der Fahrer',
    standardListItemThree: '99,9% Betriebszeit',
    standardListItemFour: 'Berichte & Fahrzeugverwaltung',
    premiumTitle: 'Premium',
    premiumText: 'Perfekt für Limousinenservice-Organisationen, die auf genaue Daten angewiesen sind.',
    premiumListItemOne: 'STANDARD-Paket enthalten',
    premiumListItemTwo: 'BI & Kostenprognose',
    premiumListItemThree: 'Datenexport',
    premiumListItemFour: 'Wöchentliche und monatliche Berichte',
    warningOne: '* Die angezeigten Preise sind monatliches Abonnement pro Benutzer',
    warningTwo:
      '* Nach dem Wechsel zu einem neuen Paket ist es nicht möglich, für die folgenden 6 Monate zu einem kleineren Paket zu wechseln',
    warningThree: '* Benutzer des Demoplans haben Einblick in die Funktionalitäten, die das Premium-Paket bietet',
    warningOneAnnualPackage:
      'Brauchen Sie mehr? Haben Sie eine größere Organisation? Wir haben ein ENTERPRISE-Paket für Sie.',
    warningTwoAnnualPackage: 'und unser Verkaufsteam wird Sie in Kürze kontaktieren.',
    packageChange: 'Paketwechsel',
    changePackage: 'Sind Sie sicher, dass Sie zum',
    package: 'Paket',
    annualPricing: 'Jahrespreise',
    monthlyPricing: 'Monatliche Preise',
    packageType10: 'Team 10',
    packageType25: 'Team 25',
    packageType50: 'Team 50',
    'Team 10': 'Team 10',
    'Team 25': 'Team 25',
    'Team 50': 'Team 50',
    annualPackagesMessage: 'Jährliches Abonnement pro Organisation.',
    packageTypeSoloMainMessage: 'Nur 1 Benutzerkonto.',
    packageType10MainMessage: 'Bis zu 10 Benutzerkonten.',
    packageType25MainMessage: 'Bis zu 25 Benutzerkonten.',
    packageType50MainMessage: 'Bis zu 50 Benutzerkonten.',
    allFeaturesIncluded: 'Alle Funktionen enthalten.',
    unlimitedRides: 'Unbegrenzte Anzahl von Fahrten und Fahrzeugen.',
    trialPeriod: 'Testzeitraum inklusive.',
    onboardingFeeOptional: 'Einrichtungsgebühr optional.',
    noHiddenCosts: 'Keine versteckten Kosten.',
    freeSmsFlights10: '15 SMS und 50 Flugprüfungen monatlich kostenlos.',
    freeSmsFlights25: '35 SMS und 100 Flugprüfungen monatlich kostenlos.',
    freeSmsFlights50: '50 SMS und 200 Flugprüfungen monatlich kostenlos.',
  },
  successPaymentPage: {
    message: 'Zahlung erfolgreich. Vielen Dank.',
  },
  demoExpiring: {
    notification: 'Benachrichtigung',
    expiredSubscriptionMessage:
      'Wir informieren Sie darüber, dass Ihr Abonnement für diese Plattform abgelaufen ist. Um unsere Dienste weiterhin nutzen zu können, erneuern Sie bitte Ihr Abonnement.',
  },
  login: {
    username: 'Benutzername',
    password: 'Passwort',
    rememberMe: 'Erinnere mich',
    login: 'Anmelden',
    noAccount: 'Noch kein Konto? Sie können sich registrieren',
    forgetPassword: 'Passwort vergessen? Sie können es zurücksetzen',
    registerHere: 'Hier',
    email: 'E-Mail',
    enterEmail: 'Bitte geben Sie Ihre E-Mail ein',
    enterPassword: 'Bitte geben Sie Ihr neues Passwort ein',
    signInWithGoogle: 'Mit Google anmelden',
  },
  registration: {
    register: 'Registrieren',
    name: 'Organisationsname',
    address: 'Adresse',
    phoneNumber: 'Telefonnummer',
    email: 'E-Mail',
    companyId: 'Unternehmens-ID',
    vat: 'MwSt.',
    language: 'Sprache',
    package: 'Paket',
    accept: 'Ich akzeptiere',
    terms: 'die Nutzungsbedingungen',
    successfullyRegistered:
      'Der Benutzer wurde erfolgreich registriert. Sie erhalten eine E-Mail mit einem Bestätigungslink.',
    haveAccount: 'Haben Sie bereits ein Konto? Sie können sich anmelden',
    loginHere: 'Hier',
    trialPeriodInfo:
      'Inklusive 14 Tage kostenloser Probierzeitraum. Die Abrechnung erfolgt nach Ende des Probierzeitraums.',
  },
  driving: {
    drivingType: 'Buchungstyp',
    general: 'Allgemeine Informationen',
    newDriving: 'Transfer planen',
    newDailyRent: 'Tägliche Miete planen',
    areYouSure: 'Sind Sie sicher?',
    vehicleIsUnavailable: 'Das Fahrzeug ist besetzt.',
    vehicleIsDamaged: 'Das Fahrzeug ist beschädigt.',
    sureAboutThisVehicle: 'Sind Sie sicher, dass Sie dieses Fahrzeug wählen möchten?',
    driverUnavaliable: 'Der Fahrer ist beschäftigt.',
    sureAboutThisDriver: 'Sind Sie sicher, dass Sie diesen Fahrer auswählen möchten?',
    checkVehicleSeatAvailability: 'Dieses Fahrzeug kann nicht so viele Passagiere aufnehmen.',
    cancelDriving: 'Sind Sie sicher, dass Sie den Fahrauftrag stornieren möchten?',
    finishDriving: 'Sind Sie sicher, dass Sie den Fahrauftrag beenden möchten?',
    noShowDriving: 'Sind Sie sicher, dass Sie die Fahrt als Nichterscheinen markieren möchten?',
    markDrivingAsStarted: 'Sind Sie sicher, dass Sie den Fahrauftrag als gestartet markieren möchten?',
    deleteDriving: 'Sind Sie sicher, dass Sie den Fahrauftrag löschen möchten?',
    newTransfer: 'Neuer Transfer',
    driving: 'Fahrauftrag',
    comments: 'Kommentare',
    id: 'Id',
    locationFrom: 'Startpunkt',
    locationTo: 'Ziel',
    vehicleClass: 'Fahrzeugklasse',
    vehicle: 'Fahrzeug',
    driver: 'Fahrer',
    pickupTime: 'Abholzeit',
    clientConfirmed: 'Kunde bestätigt',
    description: 'Beschreibung',
    extraInfo: 'Zusätzliche Informationen',
    drivingNotAccepted: 'Der Fahrauftrag wurde nicht angenommen:',
    currency: 'Währung',
    paymentMethod: 'Zahlungsmethoden',
    price: 'Preis',
    paid: 'Bezahlt',
    distance: 'Entfernung (km)',
    distanceMi: 'Entfernung (Meilen)',
    expectedTime: 'Erwartete Dauer',
    waitingTime: 'Wartezeit (h)',
    EnterTheNumberOfHoursWaiting: 'Geben Sie die Anzahl der Wartestunden ein',
    dropOffTime: 'Ankunftszeit',
    comebackTime: 'Rückkehrzeit',
    acceptUntil: 'Akzeptieren bis',
    drivingRequired: 'Ist Fahrauftrag obligatorisch',
    comment: 'Kommentar',
    attachments: 'Anhänge',
    addFiles: '+ Dateien hinzufügen',
    deleteFiles: 'Sind Sie sicher, dass Sie diese Datei löschen möchten?',
    passengers: 'Passagiere',
    addPassenger: '+ Passagier hinzufügen',
    deletePassenger: 'Sind Sie sicher, dass Sie diesen Passagier löschen möchten?',
    chooseClient: 'Kunden auswählen',
    costs: 'Kosten',
    checkpoints: 'Wegpunkte',
    addCheckpoint: 'Wegpunkt hinzufügen',
    deleteCheckpoint: 'Sind Sie sicher, dass Sie diesen Wegpunkte löschen möchten?',
    waitingBoardText: 'Wartetext an der Tafel',
    flightNumber: 'Flugnummer',
    flightInfo: 'Fluginformation',
    createdBy: 'Fahrauftrag erstellt um <b>{time}</b> von <b>{user}</b>',
    client: 'Klient',
    gnet: 'GNET',
    note: 'Hinweis',
    noteDriver: 'Hinweis für den Fahrer',
    noteDispatcher: 'Hinweis für den Disponenten',
    passenger: 'Passagier',
    fullName: 'Vollständiger Name',
    phoneNumber: 'Telefonnummer',
    email: 'E-Mail',
    suitcasesNumber: 'Anzahl der Koffer',
    passengersNumber: 'Anzahl der Passagiere',
    babySeatsNumber: 'Anzahl der Kindersitze',
    stopsNumber: 'Anzahl der Stopps',
    steps: {
      mainInfo: 'Hauptinformationen',
      additionalInfo: 'Zusätzliche Informationen',
      review: 'Überprüfung',
    },
    commissionAmount: 'Provision',
    sendSms: 'SMS senden',
    message: 'Message',
    acceptDriving: 'Sind Sie sicher, dass Sie den Fahrauftrag annehmen möchten?',
    rejectDriving: 'Sind Sie sicher, dass Sie den Fahrauftrag ablehnen möchten?',
    calculatePriceViaZonePricing: 'Preis über Zonenpreisberechnung berechnen',
    cannotCalculatePriceViaZonePricingForDailyRents: 'Preisberechnung über Zonenpreise für Tagesmieten nicht möglich',
    disableCalculatePriceViaZonePricing:
      'Preis über Zonenpreisberechnung ohne Standort, Währung und Fahrzeugklasse nicht möglich',
    numberOfPassengers: 'Anzahl der Passagiere',
    numberOfSuitcases: 'Anzahl der Koffer',
    vat: 'MwSt',
    vatPercentage: 'Mehrwertsteuerprozentsatz',
    priceType: 'Preistyp',
    accessibleForWheelchair: 'Barrierefrei für Rollstuhlfahrer',
    priceForWaiting: 'Preis für das Warten',
    numberOfHours: 'Anzahl der Stunden',
    getAQuote: 'Kontaktieren Sie uns für ein Angebot',
  },
  drivingStatus: {
    pending: 'Ausstehend',
    accepted: 'Akzeptiert',
    rejected: 'Abgelehnt',
    expired: 'Abgelaufen',
    canceled: 'Storniert',
    draft: 'Entwurf',
    done: 'Erledigt',
    noShow: 'Nichterscheinen',
    ongoing: 'Gestartet',
  },
  drivingOrderType: {
    dailyRent: 'Tagesmiete',
    transfer: 'Transfer',
    hourlyDaily: 'Stündlich/Täglich',
    oneWayTransfer: 'Einfache Fahrt',
    fromAirport: 'Vom Flughafen',
    toAirport: 'Zum Flughafen',
  },
  flightInfo: {
    airlineName: 'Fluggesellschaft',
    flightNumber: 'Flugnummer',
    departureAirport: 'Abflughafen',
    departureCountry: 'Abflugland',
    departureTime: 'Abflugzeit',
    arrivalAirport: 'Ankunftshafen',
    arrivalCountry: 'Ankunftsland',
    arrivalTime: 'Ankunftszeit',
    departureDelayed: 'Verspäteter Abflug',
    flightStatus: 'Flugstatus',
    percentageOfFlight: 'Flugfortschritt in Prozent',
  },
  customValidations: {
    fileType: `Der Dateityp .{extension} wird nicht unterstützt`,
    fileSize: 'Dateien dürfen nicht größer als {size} sein',
    fieldRequired: 'Feld {field} ist erforderlich',
    fieldType: 'Feld {field} muss vom Typ {type} sein',
    fieldLengthMin: 'Das Feld {field} muss {length} oder mehr sein',
    fieldLengthMax: 'Das Feld {field} muss {length} oder weniger sein',
    fieldUnique: 'Feld {field} muss eindeutig sein',
    fieldValid: 'Feld {field} muss gültig sein',
    noActiveSubscription: 'Kein aktives Abonnement gefunden',
    usersLimitExceeded: 'Benutzerlimit erreicht',
    fieldBefore: '{field1} muss vor {field2} liegen',
    flightNotExist: 'Der Flug {field} existiert nicht',
    gnetKeyNotValid: 'Der angegebene Gnet-Schlüssel ist ungültig.',
    gnetPassengersRequired: 'Fahrbefehl kann nicht an GNET gesendet werden, ohne Passagiere.',
    priceAndCurrencyRequired: 'Zahlungslink kann nicht ohne Preis und Währung generiert werden',
    fieldValueNotSupported: 'Der Wert des Feldes {field} wird nicht unterstützt',
    noCountryCode: 'Sie haben kein Land ausgewählt',
    noStripeForYourCountry: 'Stripe ist in Ihrem Land nicht verfügbar',
    stripeAlreadyEnabled: 'Stripe ist bereits aktiviert',
    fieldValueMin: 'Das Feld {field} muss {min} oder mehr sein',
    fieldValueMax: 'Das Feld {field} muss {max} oder weniger sein',
    markAsDoneDate: 'Die Abholzeit darf nicht in der Zukunft liegen',
    phoneNumberNotValidForSms:
      'Die Telefonnummer ist für das Senden von SMS ungültig. Eine gültige Telefonnummer muss die Ländervorwahl enthalten.',
    affiliateIdDoesNotExist: 'Die Organisation mit der angegebenen Affiliate-ID existiert nicht.',
    numericDecimal: 'Das Feld {field} darf nur numerische Zeichen enthalten (Dezimalstellen sind erlaubt)',
  },
  fieldTypes: {
    string: 'Zeichenkette',
    integer: 'Zahl',
    date: 'Datum',
  },
  names: {
    username: 'Benutzername',
    password: 'Passwort',
    choose_client: 'Kunde wählen',
    choose_month: 'Monat wählen',
    currency: 'Währung',
    payment_method: 'Zahlungsmethode',
    vehicle_class: 'Fahrzeugklasse',
    first_name: 'Vorname',
    last_name: 'Nachname',
    passport: 'Reisepass',
    nationality: 'Nationalität',
    plate_number: 'Kennzeichen',
    brand: 'Fahrzeugmarke',
    number_of_passengers: 'Anzahl der Passagiere',
    fuel_consumption: 'Kraftstoffverbrauch',
    year_of_manufacture: 'Baujahr',
    price_per_km: 'Preis pro km',
    price_per_mi: 'Preis pro Meile',
    price_per_hour: 'Preis pro Stunde',
    price_per_waiting_hour: 'Preis pro Wartestunde',
    vehicle: 'Fahrzeug',
    description: 'Beschreibung',
    time_from: 'Zeit von',
    time_to: 'Zeit bis',
    name: 'Name',
    cost_type: 'Kostenart',
    cost_time: 'Kostenzeitpunkt',
    price: 'Preis',
    rebate_min: 'Mindestanzahl der Fahraufträge',
    rebate_max: 'Höchstanzahl der Fahraufträge',
    rebate: 'Rabatt',
    phone_number: 'Telefonnummer',
    organisation: 'Organisation',
    role: 'Rolle',
    image: 'Bild',
    location_from: 'Standort von',
    location_to: 'Standort bis',
    pickup_time: 'Abholzeit',
    distance: 'Entfernung',
    waiting_time: 'Wartezeit',
    comment: 'Kommentar',
    address: 'Adresse',
    vat: 'MwSt',
    company_id: 'Firmen-ID',
    company_personal_id: 'firmen-id/personalausweis',
    licence: 'Lizenz',
    warrant_number: 'Warrant-Nummer',
    seal: 'Siegel',
    signature: 'Unterschrift',
    email: 'E-Mail',
    percentage: 'Prozentsatz',
    organizationName: 'Name der Organisation',
    package: 'paket',
    choose_currency: 'Währung auswählen',
    team_10_price: 'Team 10 Preis',
    team_10_paddle_price_id: 'Team 10 Paddle Preis-ID',
    team_25_price: 'Team 25 Preis',
    team_25_paddle_price_id: 'Team 25 Paddle Preis-ID',
    team_50_price: 'Team 50 Preis',
    team_50_paddle_price_id: 'Team 50 Paddle Preis-ID',
    team_10_price_monthly: 'Team 10 Preis',
    team_10_paddle_price_id_monthly: 'Team 10 Paddle Preis-ID',
    team_25_price_monthly: 'Team 25 Preis',
    team_25_paddle_price_id_monthly: 'Team 25 Paddle Preis-ID',
    team_50_price_monthly: 'Team 50 Preis',
    team_50_paddle_price_id_monthly: 'Team 50 Paddle Preis-ID',
    countries: 'Länder',
    invoice_number: 'Rechnungsnummer',
    status: 'Status',
    total: 'Gesamt',
    billed_at: 'Abgerechnet am',
    articles_of_law: 'Gesetzesartikel',
    country: 'land',
    referent: 'referent',
    language: 'sprache',
    location: 'Standort',
    arrival_time: 'Ankunftszeit',
    waiting_board_text: 'wartetext an der Tafel',
    flight_number: 'Flugnummer',
    gnet_id: 'GNET-ID',
    receiver_id: 'Empfänger-ID',
    preferred_vehicle_type: 'bevorzugter Fahrzeugtyp',
    reservation_type: 'Reservierungsart',
    run_type: 'Fahrtart',
    trip_duration_minutes: 'Reisedauer',
    client: 'klient',
    client_type: 'kundentyp',
    card_holder_name: 'name des karteninhabers',
    card_number: 'kartennummer',
    expiry_date: 'ablaufdatum',
    cvv: 'cvv',
    code: 'code',
    symbol: 'symbol',
    quantity: 'menge',
    discount: 'rabatt',
    driving_type: 'Fahrtentyp',
    full_name: 'vollständiger Name',
    suitcases_number: 'Anzahl der Koffer',
    passengers_number: 'Anzahl der Passagiere',
    baby_seats_number: 'Anzahl der Kindersitze',
    stops_number: 'Anzahl der Stopps',
    commission_amount: 'provision',
    message: 'message',
    affiliate_id: 'affiliate id',
    coordinates: 'Koordinaten',
    zone_one: 'Zone eins',
    zone_two: 'Zone zwei',
    price_type: 'Preistyp',
    price_for_waiting: 'preis für das warten',
    number_of_hours: 'anzahl der stunden',
    zones: 'Zonen',
  },
  weekDays: {
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
    sunday: 'Sonntag',
  },
  select: {
    noDataAvailable: 'Keine Daten verfügbar',
    selected: 'ausgewählt',
  },
  excelExport: {
    to_date_range: 'Der Bericht kann nicht für zukünftige Termine generiert werden. Bitte geben Sie gültige Daten ein.',
    overlap_range: 'Die Endzeit darf nicht größer sein als die Startzeit.',
  },
  setPassword: {
    success: 'E-Mail gesendet',
    checkEmail: 'Überprüfen Sie Ihre E-Mail für weitere Anweisungen.',
    already_changed: 'Bereits geändert',
    send_new_reset_request:
      'Sie haben bereits das Passwort über diesen Link geändert. Senden Sie eine neue Anforderung zum Zurücksetzen des Passworts.',
  },
  statisticsPage: {
    reportType: 'Berichtstyp',
    reportTime: 'Berichtszeit',
    vehicleUtilization: 'Fahrzeugnutzung',
    costEffectiveness: 'Kosteneffizienz',
    generalReport: 'Allgemeiner Bericht',
    clientsReport: 'Bericht nach Kunden',
  },
  profile: {
    yourProfile: 'Ihr Profil',
    name: 'Name',
    role: 'Rolle',
    username: 'Benutzername',
    password: 'Passwort',
    phoneNumber: 'Telefonnummer',
    email: 'E-Mail',
    percentage: 'Prozentsatz',
  },
  gpsMaps: {
    noTransfers: 'Es gibt keine laufenden oder bevorstehenden Überweisungen.',
    map: 'Karte',
    transfers: 'Transfers',
    drivers: 'Fahrer',
    ongoing: 'Laufend',
    upcoming: 'Bevorstehend',
    driving: 'Fahrt',
    driver: 'Fahrer',
    vehicle: 'Fahrzeug',
    requestDriverLocation: 'Anforderung des Fahrerstandorts',
    lastRecordedLocation: 'Letzter aufgezeichneter Standort',
  },
  driverStatus: {
    available: 'Verfügbar',
    offline: 'Offline',
    inTransfer: 'Im Transfer',
  },
  onboardingTutorialMain: {
    languageStep: 'Hier können Sie Ihre bevorzugte Sprache für die Plattform einstellen.',
    notificationsStep: 'Hier finden Sie Ihre Benachrichtigungen.',
    addTransferStep:
      'Klicken Sie auf diese Schaltfläche, um eine neue Reservierung, Einwegtransfers und Tagesmieten zu erstellen.',
    sideMenuButtonStep: 'Klicken Sie auf dieses Symbol, um die Menüleiste ein- oder auszublenden.',
    sideMenuExpStep:
      'Von hier aus können Sie zu den Hauptbereichen der Plattform navigieren: Reservierungen, Fuhrpark, Kosten, Verwaltung, Rechnungen, Statistik usw.',
    sideMenuButtonCloseStep: 'Klicken Sie auf dasselbe Symbol, um die Menüleiste auszublenden.',
    supportStep: 'Falls Sie Fragen oder Probleme haben, wenden Sie sich hier an den Kundenservice.',
  },
  onboardingTutorialVehicles: {
    tableStep: 'Fahrzeuge werden nach dem Hinzufügen in dieser Tabelle aufgeführt.',
    searchStep: 'Sie können Fahrzeuge einfach direkt nach Marke, Kennzeichen, Jahr usw. suchen.',
    addStep:
      'Fügen Sie neue Fahrzeuge hinzu, indem Sie auf die Schaltfläche „Hinzufügen“ klicken. Bitte beachten Sie, dass Sie zuerst alle „Fahrzeugklassen“ hinzufügen müssen, bevor Sie Fahrzeuge eingeben.',
  },
  onboardingTutorialStatistics: {
    typeStep:
      'In diesem Bereich finden Sie alle Ihre statistischen Daten in Diagrammen. Wählen Sie hier die Art der Statistik aus.',
    intervalStep: 'Wählen Sie ein Intervall für die Statistiken.',
    downloadStep: 'Sie können diese Tabellen auch herunterladen und drucken.',
  },
  onboardingTutorialGps: {
    tabOneStep:
      'Im GPS-Modul können Sie Ihre Fahrer und Transfers an einem Ort verfolgen. Wählen Sie diese Registerkarte, um aktuelle und kommende (nächste 24 Stunden) Transfers anzuzeigen.',
    tabTwoStep:
      'Wählen Sie Fahrzeuge aus, um sie auf der Karte zu sehen. Sie können verfügbare Fahrzeuge direkt von hier zuweisen.',
    mapStep: 'Wählen oder deaktivieren Sie Fahrzeuge, indem Sie auf ihren Pin auf der Karte klicken.',
  },
  onboardingTutorialInvoices: {
    tableStep: 'Alle Rechnungen werden in dieser Tabelle von neu nach alt aufgelistet.',
    searchStep:
      'Finden Sie Rechnungen ganz einfach, indem Sie direkt nach Nummer, Betrag, Kundenname, Erstellungsdatum usw. suchen.',
    addStep: 'Erstellen Sie neue Rechnungen, indem Sie auf die Schaltfläche „Hinzufügen“ klicken.',
  },
  onboardingTutorialOffers: {
    tableStep: 'Angebote werden in dieser Tabelle nach dem Hinzufügen von neu nach alt aufgelistet.',
    searchStep: 'Finden Sie Angebote ganz einfach, indem Sie direkt nach Nummer, Betrag oder Beschreibung suchen.',
    addStep: 'Erstellen Sie neue Angebote, indem Sie auf die Schaltfläche „Hinzufügen“ klicken.',
  },
  onboardingTutorialResources: {
    infoStep:
      'Zeigt die verbleibenden Ressourcen an, und es besteht die Möglichkeit, bei Bedarf zusätzliche SMS zu kaufen.',
    tableStep:
      'In dieser Tabelle finden Sie ein Protokoll und den Verlauf aller verwendeten Ressourcen (gesendete SMS und geprüfte Flüge).',
    searchStep:
      'Finden Sie Ressourcen ganz einfach, indem Sie direkt nach Inhalt, Benutzer oder Erstellungsdatum suchen.',
  },
  onboardingTutorialPassengerReviews: {
    tableStep: 'Passagierbewertungen werden in dieser Tabelle von neu nach alt aufgelistet.',
    searchStep: 'Finden Sie Passagierbewertungen ganz einfach, indem Sie direkt nach Nummer oder Kommentar suchen.',
  },
  onboardingTutorialPricingZones: {
    tableStep: 'Preiszonen werden nach dem Hinzufügen in dieser Tabelle angezeigt.',
    searchStep: 'Finden Sie Preiszonen ganz einfach durch direkte Suche.',
    addStep: 'Erstellen Sie neue Preiszonen, indem Sie auf die Schaltfläche „Hinzufügen“ klicken.',
  },
  onboardingTutorialPricingZoneRelations: {
    tableStep: 'Preiszonenbeziehungen (Preise) werden nach dem Hinzufügen in dieser Tabelle angezeigt.',
    searchStep: 'Finden Sie Preiszonenbeziehungen ganz einfach durch direkte Suche.',
    addStep:
      'Erstellen Sie neue Preiszonenbeziehungen (Preise), indem Sie auf die Schaltfläche „Hinzufügen“ klicken. Bitte beachten Sie, dass zuerst Preiszonen hinzugefügt werden müssen.',
  },
  onboardingTutorialPricingZoneHourlyRelations: {
    tableStep:
      'Alle Preiszonen sind in dieser Tabelle aufgeführt. Durch Öffnen einer bestimmten Zone können Sie Preise für diese Zone festlegen.',
    searchStep: 'Finden Sie Preiszonen einfach durch direkte Suche.',
  },
  onboardingTutorialDashboard: {
    infoStep: 'Zeigt eine Zusammenfassung der Statistiken Ihrer Organisation für die letzten 30 Tage an.',
    next3DaysStep: 'Reservierungen für die nächsten 3 Tage werden in dieser Tabelle angezeigt.',
    notPaidOrConfirmedStep:
      'Reservierungen für die nächsten 7 Tage, die nicht bestätigt oder bezahlt sind, werden in dieser Tabelle angezeigt.',
    next5DaysStep: 'Beschäftigte Fahrer für die nächsten 5 Tage werden in dieser Tabelle angezeigt.',
    latestReviewsStep: 'Die neuesten Bewertungen Ihrer Fahrten werden in dieser Tabelle angezeigt.',
  },
  onboardingTutorialAffiliate: {
    tabStep:
      'Durch Wechseln der Registerkarten können Sie Ihre Partner, gesendete Anfragen oder empfangene Anfragen anzeigen.',
    idStep: 'Hier finden Sie Ihre Affiliate-ID, die Sie mit Ihren Partnern teilen können.',
    addStep: 'Erstellen Sie eine neue Affiliate-Partneranfrage, indem Sie auf die Schaltfläche „Hinzufügen“ klicken.',
  },
  onboardingTutorialAdvancedSettings: {
    advancedSettings:
      'Klicken Sie hier, um auf die erweiterten Einstellungen Ihrer Organisation zuzugreifen und alle Eigenschaften und Integrationen zu konfigurieren.',
  },
  advancedSettings: {
    advancedSettings: 'Erweiterte Einstellungen',
    distanceUnit: 'Entfernungseinheit',
    distanceUnitDescription: 'Wählen Sie die Einheit für Entfernungsangaben.',
    kilometers: 'Kilometer',
    miles: 'Meilen',
    dateFormat: 'Datumsformat',
    dateFormatDescription: 'Wählen Sie das bevorzugte Datumsformat aus.',
    ddmmyyyy: 'DD-MM-YYYY',
    mmddyyyy: 'MM-DD-YYYY',
    mandatoryDrivings: 'Verpflichtende Fahrten',
    mandatoryDrivingsDescription: 'Wählen Sie, ob alle Fahrten standardmäßig verpflichtend sein sollen.',
    mandatoryDrivingsDefault: 'Verpflichtende Fahrten (Standard)',
    paidDrivings: 'Bezahlte Fahrten',
    paidDrivingsDescription: 'Markieren Sie dies, um alle Fahrten standardmäßig bezahlt zu machen.',
    paidDrivingsDefault: 'Bezahlte Fahrten (Standard)',
    notificationsEmail: 'E-Mail für Benachrichtigungen',
    notificationsEmailDescription:
      'Ändern Sie die Standard-E-Mail für Benachrichtigungen (wenn Sie die E-Mail-Adresse Ihrer Organisation nicht verwenden möchten).',
    email: 'E-Mail',
    articlesOfLaw: 'Gesetzesartikel',
    integrations: 'Integrationen',
    gnetId: 'GNET ID',
    gnetDescription:
      'GNET ist eine umfassende Plattform zur Optimierung der Echtzeitverfolgung, Flottenverwaltung und Einsatzsteuerung in der Transportindustrie.',
    makeRoutesAvailable: 'Integrationsrouten verfügbar machen',
    makeRoutesAvailableDescription:
      'Aktivieren Sie Ihren API-Schlüssel, um LimoExpress in Ihre Website, Ihr ERP-System und mehr zu integrieren.',
    emailsEnabled: 'E-Mails aktiviert',
    makePublicFormAvailable: 'Öffentliches Formular verfügbar machen',
    makePublicFormAvailableDescription:
      'Aktivieren Sie diese Option, um Zugriff auf die öffentliche Buchungsseite zu ermöglichen, damit Ihre Kunden auf das erweiterte Buchungsformular zugreifen und einfach Konten erstellen können. Sie können den Link in Newslettern, auf der Website und in Flyern verwenden; es ist ein einzigartiger Link nur für Ihr Unternehmen. Alternativ können Sie auch einen Code-Snippet erhalten, um das Buchungsformular direkt auf Ihrer eigenen Website einzubetten.',
    emailsEnabledDefault: 'E-Mails aktiviert',
    emailsEnabledDescription: 'Aktivieren, um E-Mail-Benachrichtigungen zu erhalten.',
    passengerReviewsEnabled: 'Passagierbewertungen aktiviert',
    passengerReviewsEnabledDefault: 'Passagierbewertungen aktiviert',
    passengerReviewsEnabledDescription:
      'Durch Aktivierung dieser Funktion erhalten Ihre Passagiere (sofern eine E-Mail eingegeben wurde) eine Bestätigungs-E-Mail nach Abschluss der Fahrt (als Abgeschlossen markiert) und haben die Möglichkeit, Bewertungen (Feedback) zu Ihren Dienstleistungen abzugeben.',
    vat: 'MwSt',
    vatPercentage: 'MwSt-Prozentsatz',
    vatPercentageDescription: 'Geben Sie den MwSt-Prozentsatz ein.',
    invoicePaymentInstructions: 'Rechnungszahlungsanweisungen',
    stripe: 'Stripe',
    stripeDescription:
      'Aktivieren Sie Stripe, um Ihren Kunden Ihre Dienstleistungen in Rechnung stellen zu können. LimoExpress erhebt keine Provision.',
    dataExport: 'Datenexport',
    dataExportDescription: 'Sie können alle Ihre Daten exportieren, und sie werden Ihnen per E-Mail zugeschickt.',
    sureAboutDataExport:
      'Sind Sie sicher, dass Sie alle Ihre Daten exportieren möchten? Wenn ja, werden alle Daten innerhalb der nächsten 2 Stunden per E-Mail gesendet.',
    defaultPriceType: 'Standardpreistyp',
    defaultPriceTypeDescription:
      'Legen Sie den Standardpreistyp fest, um zu bestimmen, wie die Preise angezeigt werden.',
    NET: 'NETTO',
    GROSS: 'BRUTTO',
    link: 'Link',
    code: 'Code',
    language: 'Sprache',
    chooseSize: 'Größe wählen',
    small: 'Klein',
    medium: 'Mittel',
    large: 'Groß',
  },
  drivingExcelCheckboxes: {
    drivingNumber: 'Fahrtnummer',
    drivingType: 'Buchungstyp',
    drivingStatus: 'Status',
    locationFrom: 'Startpunkt',
    locationTo: 'Ziel',
    driver: 'Fahrer',
    client: 'Kunde',
    plateNumber: 'Kennzeichen',
    note: 'Beschreibung',
    fuelConsumption: 'Kraftstoffverbrauch',
    pickupTime: 'Abholzeit',
    numberOfPassengers: 'Passagierzahl',
    passengers: 'Passagiere',
    price: 'Preis',
    paid: 'Bezahlt',
    paymentMethod: 'Zahlungsmethode',
    waitingTime: 'Wartezeit (h)',
    distance: 'Entfernung (km)',
    distanceMi: 'Entfernung (Meilen)',
    selectAll: 'Alles auswählen',
  },
  gnet: {
    gnetInfo: 'GNET Info',
    requesterId: 'Anforderer-ID',
    receiverId: 'Empfänger-ID',
    preferredVehicleType: 'Bevorzugter Fahrzeugtyp',
    reservationType: 'Reservierungsart',
    runType: 'Laufart',
    price: 'Preis',
    currency: 'Währung',
    noteDriver: 'Hinweis für den Fahrer',
    noteDispatcher: 'Hinweis für den Disponenten',
    tripDurationMinutes: 'Reisedauer (Minuten)',
  },
  invoicesHeaders: {
    invoiceNumber: 'Rechnungsnummer',
    client: 'Kunde',
    totalPrice: 'Gesamtpreis',
    drivings: 'Fahrten',
    paid: 'Bezahlt',
    currency: 'Währung',
    createdAt: 'Erstellt am',
    actions: 'Aktionen',
  },
  invoicesPage: {
    newItem: 'Neuer Rechnungseintrag',
    chooseClient: 'Kunden auswählen',
    chooseDrivings: 'Fahrten auswählen',
    allDrivings: 'Alle Fahrten',
    deleteMessage: 'Sind Sie sicher, dass Sie diese Rechnung löschen möchten?',
    markInvoiceAsPaid: 'Sind Sie sicher, dass Sie diese Rechnung als bezahlt markieren möchten?',
  },
  drivingOffersHeaders: {
    number: 'Angebotsnummer für Fahrten',
    totalPrice: 'Gesamtpreis',
    description: 'Beschreibung',
    paid: 'Bezahlt',
    currency: 'Währung',
    createdAt: 'Erstellt am',
    actions: 'Aktionen',
  },
  drivingOffersPage: {
    newItem: 'Neuer Eintrag für Fahrangebote',
    item: 'Artikel',
    description: 'Beschreibung',
    title: 'Titel',
    vehicleClass: 'Fahrzeugklasse',
    quantity: 'Menge',
    price: 'Preis',
    discount: 'Rabatt',
    vat: 'MwSt',
    deleteMessage: 'Sind Sie sicher, dass Sie dieses Fahrangebot löschen möchten?',
    markDrivingOfferAsPaid: 'Sind Sie sicher, dass Sie dieses Fahrtangebot als bezahlt markieren möchten?',
  },
  modal: {
    reservationSuccessTitle: 'Erfolg',
    reservationSuccessMessage:
      'Reservierungsanfrage erfolgreich gesendet. Jemand wird sich in Kürze mit Ihnen in Verbindung setzen.',
    reservationErrorTitle: 'Formular deaktiviert',
    reservationErrorMessage:
      'Das Formular wurde von der Organisation deaktiviert. Bitte kontaktieren Sie den Support oder versuchen Sie es später erneut.',
  },
  dashboardPage: {
    drivingCountToday: 'Anzahl der Fahrten (heute)',
    drivingCountThisMonth: 'Anzahl der Fahrten (letzte 30 Tage)',
    dailyRentsAndTransfers: 'Anzahl der Fahrten (Tagesmieten/Transfers)',
    drivingTotalAmount: 'Gesamtbetrag',
    driversCount: 'Aktive Fahrer (±7 Tage)',
    passengerCount: 'Anzahl der Passagiere (letzte 30 Tage)',
  },
  reviewsHeaders: {
    rating: 'Bewertung',
    comment: 'Kommentar',
    createdAt: 'Erstellt am',
  },
  resourceOverviewHeaders: {
    actionType: 'Aktionstyp',
    user: 'Benutzer',
    action: 'Aktion',
    createdAt: 'Erstellt am',
  },
  resourceOverviewPage: {
    smsMessagesLeft: 'Verbleibende Anzahl an SMS-Nachrichten',
    flightsCheckLeft: 'Verbleibende Anzahl an Flugüberprüfungen',
    sms: 'SMS',
    flightCheck: 'Flugüberprüfung',
  },
  affiliateHeaders: {
    organisation: 'Organisation',
    createdAt: 'Erstellt am',
    actions: 'Aktionen',
  },
  affiliatePage: {
    partners: 'Partner',
    sentRequests: 'Gesendete Anfragen',
    receivedRequests: 'Erhaltene Anfragen',
    acceptRequest: 'Sind Sie sicher, dass Sie diese Anfrage akzeptieren möchten?',
    rejectRequest: 'Sind Sie sicher, dass Sie diese Anfrage ablehnen möchten?',
    deletePartner: 'Sind Sie sicher, dass Sie diesen Partner löschen möchten?',
    deleteRequest: 'Sind Sie sicher, dass Sie die Anfrage löschen möchten?',
    newItem: 'Neue Anfrage',
    affiliateId: 'Affiliate ID',
    registerNewPartner: 'Einen neuen Partner registrieren',
    registrationSuccessfull: 'Registrierung erfolgreich',
    registrationSuccessfullDescription:
      'Affiliate-Partner erfolgreich registriert! Bitte informieren Sie den Partner, dass er eine E-Mail zur Verifizierung seines Kontos und zum Festlegen eines Passworts erhält. Danach kann er die Plattform nutzen.',
  },
  affiliate: {
    affiliateInfo: 'Affiliate Informationen',
    affiliate: 'Affiliate',
    partner: 'Partner',
    preferredVehicleType: 'Bevorzugter Fahrzeugtyp',
    driverInfo: 'Fahrerinformationen',
    vehicleInfo: 'Fahrzeuginformationen',
    price: 'Preis',
    currency: 'Währung',
  },
  pricingZonesHeaders: {
    name: 'Zonenname',
    code: 'Code',
    postalCode: 'PLZ/Postleitzahl',
    actions: 'Aktionen',
  },
  pricingZonesPage: {
    newItem: 'Neuer Eintrag für Preiszone',
    editItem: 'Änderung der Preiszone',
    id: 'ID',
    name: 'Zonenname',
    code: 'Code',
    deleteMessage: 'Möchten Sie diese Preiszone wirklich löschen?',
    postalCode: 'PLZ/Postleitzahl',
    postalCodeDescription: 'Definieren Sie eine Zone auf der Karte mit einer PLZ/Postleitzahl',
    postalCodeDisabled: 'Bitte geben Sie zuerst eine PLZ/Postleitzahl ein',
  },
  pricingZoneRelationsHeaders: {
    zoneOne: 'Zone eins',
    zoneTwo: 'Zone zwei',
    client: 'Kunde',
    actions: 'Aktionen',
  },
  pricingZoneRelationsPage: {
    newItem: 'Neuer Eintrag für Preiszonenbeziehung',
    editItem: 'Änderung der Preiszonenbeziehung',
    zone: 'Zone',
    zoneOne: 'Zone eins',
    zoneTwo: 'Zone zwei',
    chooseClient: 'Kunden auswählen',
    chooseClientFrom: 'Von Kunde klonen',
    chooseClientTo: 'Zu Kunde klonen',
    cloneItem: 'Preiszonenbeziehung klonen',
    zoneRelations: 'Zonenbeziehungen',
    cloneItemHourly: 'Stundenpreise klonen',
    zones: 'Zonen',
    fromTo: 'Von - Bis',
    percentage: 'Prozentsatz',
    deleteMessage: 'Sind Sie sicher, dass Sie diese Preiszonenbeziehung löschen möchten?',
  },
  referralPrize: {
    referToAFriend: 'Einem Freund empfehlen',
    referToAFriendDescription:
      'Laden Sie Ihre Freunde ein, der Plattform beizutreten, indem Sie ihre E-Mail-Adresse unten eingeben. Sie erhalten eine personalisierte Einladung, um sich zu registrieren und die Plattform zu nutzen. Alternativ können Sie Ihren einzigartigen Registrierungslink direkt mit anderen teilen! Wenn sich jemand über Ihren Link oder Ihre Einladung registriert, erhalten Sie eine Empfehlungsprämie als Dankeschön. Beginnen Sie noch heute mit dem Einladen und Teilen!',
  },
};
